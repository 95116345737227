// import services from '@/helpers/services.js'
// import { Message } from 'element-ui'
function getAccessToken () {
  const idToken = window.localStorage.getItem('idToken')
  if (idToken) {
    return JSON.parse(idToken)
  } else {
    return null
  }
}

export {
  getAccessToken
}
