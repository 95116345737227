<template>
  <el-row class="login">
    <el-col class="logo" :span="12">
      <!-- <div class="grid-content bg-purple"> -->
        <div class="login_text_div">
          <div class="title1">TENOX</div>
          <div class="tseg">.</div>
          <h3 class="title2">Шатахуун түгээх станцын цогц систем</h3>
        </div>
      <!-- </div> -->
    </el-col>
       <el-col class="bg-purple-light" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <div >
        <div class="login_input">
          <el-row>
            <el-col>
              <h1 class="title3">НУУЦ ҮГ МАРТСАН</h1><br>
              <h3 class="title4">Та и-мэйл хаягаараа шинэчлэсэн нууц үгээ аваарай</h3>
            </el-col>
          </el-row>
        <el-form class="m-t-40">
          <el-form-item prop="email" :rules="[
            { required: true, message: 'Таны и-мэйл хаяг дутуу байна', trigger: 'blur' },
            { type: 'email', message: 'Та и-мэйл оруулна уу', trigger: ['blur', 'change'] }]">
            <el-input v-model="rules.email"  placeholder="Та и-мэйл оруулна уу">
              <i slot="prefix" class="el-input__icon el-icon-user"></i>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button class="button1" type="primary" @click="submitForm('dynamicValidateForm')">Нууц үг сэргээх</el-button>
          </el-form-item>
            <el-form-item>
            <el-button class="butsahbutton" type="info" @click="butsah('dynamicValidateForm')">Буцах</el-button>
          </el-form-item>
        </el-form>
        </div>
      </div>
    </el-col>
  </el-row>

</template>
<script>
export default {
  data () {
    return {
      rules: {
        email: '',
        password: '',
        rpassword: ''
      }
    }
  },
  methods: {
    butsah () {
      this.$router.push({
        name: 'LoginPage'
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}

</script>
