<template>
    <div class="home">
        <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
            <el-col :span="8">
                <h3 class="m-l-25">Гэрээний жагсаалт</h3>
            </el-col>
            <el-col :span="3" class="user_head_add_button m-r-25">
                <el-button size="mini" type="success">+ Гэрээ үүсгэх</el-button>
            </el-col>
        </el-row>
        <el-row class="m-l-30">
            <el-col :span="23" class="home_body p-20">
            <el-table v-loading="loading" :data="contractList" stripe style="width: 100%">
                <el-table-column type="index" width="50">
                </el-table-column>
                <el-table-column prop="id" label="Гэрээний дугаар">
                </el-table-column>
                <el-table-column prop="contract_detail.contract_status.status_name" label="Гэрээний төлөв">
                </el-table-column>
                <el-table-column prop="parent.last_name" label="Сурагчийн овог">
                </el-table-column>
                <el-table-column prop="student.last_name" label="Сурагчийн нэр">
                </el-table-column>
                <el-table-column prop="created_at" format="year" label="Үүсгэсэн огноо">
                </el-table-column>
                <el-table-column prop="contract_detail.reason" label="Цуцалсан шалтгаан">
                </el-table-column>
                <el-table-column label="Үйлдэл">
                    <template>
                        <el-button size="mini" type="primary" icon="el-icon-edit" circle></el-button>
                        <el-button size="mini" type="danger" icon="el-icon-delete" circle></el-button>
                    </template>
                </el-table-column>
            </el-table>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import services from '@/helpers/services.js'
export default {
  data () {
    return {
      contractList: [],
      loading: true
    }
  },
  created () {
    this.getContract()
  },
  methods: {
    getContract () {
      services.getContract()
        .then((response) => {
          this.contractList = response.data
          this.loading = false
        })
    }
  }
}

</script>

<style>

</style>
