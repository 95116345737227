<template>
  <div class="user_list">
    <el-row class="user_head_layout m-t-20">
      <el-col :span="6">
        <h3 class="m-l-25">Сурагчийн хуваарийн жагсаалт</h3>
      </el-col>
      <el-col :span="3" class="user_head_add_button m-r-5">
        <el-button size="mini" type="success" @click="goToAddUser()">+ Сурагч үүсгэх</el-button>
      </el-col>
    </el-row>
    <el-row class="m-t-20">
      <el-col :span="23" class="user_body m-l-20 p-20">
        <el-table size="mini" :data="tabledata" style="width: 100%">
          <el-table-column type="index" width="50">
          </el-table-column>
          <el-table-column prop="name" label="Сурагчийн нэр">
            <template slot-scope="scope">
              <span type="success" v-if="scope.row.student !== null"> {{ scope.row.student.name }}</span>
              <el-tag type="warning" v-if="scope.row.student === null"> Холбогдоогүй</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="180px" label="Үйлдэл">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleEdit(scope.row)"></el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" circle
                @click="handleDelete(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
export default {

  data() {
    return {
      tabledata: []
    }
  },
  created() {
    this.getData()
    // this.getData1()
    // this.getData2()
  },
  methods: {
    getData() {
      services.getstudentschedule()
        .then((response) => {
          this.tabledata = response.result
        })
    }
  }
}
</script>
