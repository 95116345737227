<template>
<div>
    <el-row type="flex" justify="space-between" class="page_head_zone">
      <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8">
        <h3 class="m-l-25">Асран хамгаалагчийн мэдээлэл</h3>
      </el-col><br />
      <el-row>
      <el-col>
        <el-input v-model="search" size="mini" placeholder="Сурагчийн нэрээр хайх" />
      </el-col>
    </el-row>
    </el-row>
    <el-row>
        {{ contractList[0] }}
        <el-table :data="contractList.filter(data => !search || data.student.name.toLowerCase().includes(search.toLowerCase()))" stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="student.created_at" label="Бүртгүүлсэн огноо">
            </el-table-column>
            <el-table-column prop="student.name" label="Сурагчийн овог">
            </el-table-column>
            <el-table-column prop="student.last_name" label="Сурагчийн нэр">
            </el-table-column>
            <el-table-column prop="student.grade" label="Сурагчийн анги">
            </el-table-column>
            <el-table-column prop="student.school" label="Сурагчийн сурууль">
            </el-table-column>
            <el-table-column prop="student.regno" label="Сурагчийн РД">
            </el-table-column>
            <el-table-column prop="student.phone" label="Сурагчийн утас">
            </el-table-column>
            <el-table-column prop="parent.last_name" label="Асран хамгаалагчийн овог">
            </el-table-column>
            <el-table-column prop="parent.name" label="Асран хамгаалагчийн нэр">
            </el-table-column>
            <el-table-column prop="parent.regno" label="Асран хамгаалагчийн РД">
            </el-table-column>
            <el-table-column prop="parent.phone" label="Асран хамгаалагчийн утас">
            </el-table-column>
            <el-table-column prop="parent.email" label="Асран хамгаалагчийн и-мэйл">
            </el-table-column>
            <el-table-column prop="parent.duureg" label="Дүүрэг">
            </el-table-column>
            <el-table-column prop="parent.horoo" label="Хороо">
            </el-table-column>
            <el-table-column prop="parent.address" label="Дэлгэрэнгүй">
            </el-table-column>
        </el-table>
    </el-row>
</div>
</template>

<script>
import services from '@/helpers/services.js'
export default {
  data () {
    return {
      contractList: [],
      loading: true,
      search: ''
    }
  },
  created () {
    this.getContract()
  },
  methods: {
    getContract () {
      services.getContract()
        .then((response) => {
          this.contractList = response.data
          this.loading = false
        })
    },
    filterHandler (value, row, column) {
            const property = column.property
            return row[property] === value
      },
  }
}
</script>

<style>
</style>