<template>
  <div class="home">
	<el-row type="flex" justify="space-between" class="page_head_zone">
      <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8">
        <h3 class="m-l-25">Англи хэлний сурагчдийн жагсаалт</h3>
      </el-col>
    </el-row>
	<el-row>
		<el-col>
			<ActiveStudentComponent :passUserListData="studentList" :lesson_name="'eng'"/>
		</el-col>
  	</el-row>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import ActiveStudentComponent from '@/components/student/component/activeStudentComponent.vue'


export default {
components:{
	ActiveStudentComponent
},

  data () {
		return {
			attendanceList:[],
			studentList: [],
			search: '',
			bagshiin_ner: ''
		}
	},
  created(){
		this.getStudentList()
	},
	methods:{
		getStudentList(){
			var payload = {
				lesson_name : 'eng'
			}
			services.getActiveStudentView(payload)
				.then((response) => {
				if (response.status === 'success') {
					response.result.forEach(element => {
						element.attendance_student.forEach(e => {
							if(e.status_id == 100){
								element.irsen = e.student_id_count*2
							}else if (e.status_id == 101){
								element.tasalsan = e.student_id_count*2
							}else if (e.status_id == 103){
								element.uvchtei = e.student_id_count*2
							}else if(e.status_id == 102){
								element.chuluutei = e.student_id_count*2
							}
						});
					});
				this.studentList = response.result
				}
			})
		},
		filterHandler (value, row, column) {
			const property = column.property
			return row[property] === value
		}
	}
}
</script>

<style>

</style>