<template>
  <div class="user_list">
    <el-row class="user_head_layout m-t-20">
      <el-col :span="6">
        <h3 class="m-l-25">Сурагчийн хуваарийн жагсаалтd</h3>
      </el-col>
      <el-col :span="3" class="user_head_add_button m-r-5">
        <el-button size="mini" type="success" @click="addstudentTopicDrawer()">+ Сурагчийн сэдэв үүсгэх</el-button>
      </el-col>
    </el-row>
    <el-row class="m-t-20">
      <el-col :span="23" class="user_body m-l-20 p-20">
        <el-table size="mini" :data="tabledata" style="width: 100%">
          <el-table-column type="index" width="50">
          </el-table-column>
          <el-table-column prop="name" label="Сурагчийн нэр">
            <template slot-scope="scope">
              <span type="success" v-if="scope.row.student !== null"> {{scope.row.student.name}}</span>
              <el-tag type="warning" v-if="scope.row.student === null"> Холбогдоогүй</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="topic" label="Сэдэв">
            <template slot-scope="scope">
              <span type="success" v-if="scope.row.topic !== null"> {{scope.row.topic.name}}</span>
              <el-tag type="warning" v-if="scope.row.topic === null"> Холбогдоогүй</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="teacher" label="Багш">
            <template slot-scope="scope">
              <span type="success" v-if="scope.row.teacher !== null"> {{scope.row.teacher.name}}</span>
              <el-tag type="warning" v-if="scope.row.teacher === null"> Холбогдоогүй</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="rate_description" label="Тайлбар">
          </el-table-column>
          <el-table-column prop="status" label="Төлөв" width="100px">
            <template slot-scope="scope">
              <el-switch :active-value="1" :inactive-value="0" active-color="#13ce66" v-model="scope.row.status"
                disabled>
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="180px" label="Үйлдэл">
            <template slot-scope="scope">
              <!-- <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleEdit(scope.row)">
              </el-button> -->
              <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="deleteprogrammandstudent(scope.row)">
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-drawer title="Сурагчийн сэдэв үүсгэх" :visible.sync="this.$root.createStudentTopicDrawer" custom-class="demo-drawer"
    ref="drawer" :before-close="handleClose">
    <div class="p-20">
      <el-form :model="createStudentTopicDrawer" :rules="rules" ref="ruleForm" label-position="top" class="demo-ruleForm">
        {{createStudentTopicDrawer}}
        <el-form-item label="Сэдвийн нэр" prop="name" :label-width="formLabelWidth">
          <el-input v-model="createStudentTopicDrawer.name" placeholder="Сэдвийн нэр"></el-input>
        </el-form-item>
        <el-form-item label="Тайлбар" prop="description" :label-width="formLabelWidth">
          <el-input v-model="createStudentTopicDrawer.description" type="textarea" placeholder="Тайлбар"></el-input>
        </el-form-item>
      </el-form>
      <div class="demo-drawer__footer m-r-20">
        <el-button type="success" class="button_right" @click="createTopic('ruleForm')">Үүсгэх</el-button>
      </div>
    </div>
  </el-drawer>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import {
  notification
} from '@/helpers/custom'
export default {

  data () {
    return {
      formLabelWidth: '80px',
      rules: {
        name: [{
          required: true,
          message: 'Сэдвийн нэр оруулна уу',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: 'Тайлбар оруулна уу',
          trigger: 'blur'
        }]
      },
      createStudentTopicDrawer: {
        status: '',
        is_active: '',
        rate: '',
        rate_teacher_id: '',
        topic_id: '',
        student_id: '',
        parent_programm_id: '',
        rate_description: '',
        rate_create_date: ''
      },
      tabledata: []
    }
  },
  created () {
    this.getData()
  },
  methods: {
    deleteprogrammandstudent (data) {
      this.$confirm(data.name + ' усгах гэж байна итгэлтэй байна уу.', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const payload = {
          id: data.id
        }
        services.deleteprogrammandstudent(payload)
          .then((response) => {
            if (response.status === 'success') {
              notification('success_deleted')
              this.$root.fullscreenLoading = false
              this.handleClose()
              this.getData()
            }
            // console.log(response)
          })
      }).catch(() => {})
    },
    addstudentTopicDrawer () {
      // this.createStudentTopicDrawer.name = ''
      // this.createStudentTopicDrawer.description = ''
      this.$root.createStudentTopicDrawer = true
    },
    handleClose () {
      this.$root.createStudentTopicDrawer = false
      // this.createTopicDrawer.name = ''
      // this.createTopicDrawer.description = ''
      // this.createTopicDrawer.status = 'active'
      // if (this.createTopicDrawer.id) {
      //   delete this.createTopicDrawer.id
      // }
    },
    getData () {
      services.getprogrammandstudent()
        .then((response) => {
          this.tabledata = response.result
        })
    }
  }
}

</script>
