<template>
  <div class="home">
    <el-dialog :title="studentData.ner + ' cурагчийн '+ selectedType + ' төлвийн ирцын дэлгэрэнгүй'"
      :visible.sync="this.$root.attendaceDetailDialog" width="80%" :before-close="handleCloseAttendanceDialog">
      <el-table :data="data" style="width: 100%" :row-class-name="tableRowClassName">
        <el-table-column prop="created_at" label="Ирц бүргэсэн огноо" width="180">
          <template slot-scope="scope">
            {{ scope.row.created_at.split('T')[0] }}  
            {{ scope.row.created_at.split('T')[1].substring(0, 8)}} 
          </template>
        </el-table-column>
        <el-table-column prop="status_id" label="Төлөв" width="180">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.status_id === 100" type="success">
              Ирсэн
            </el-tag>
            <el-tag v-else-if="scope.row.status_id === 101" type="danger">
              Тасалсан
            </el-tag>
            <el-tag size="mini" v-else-if="scope.row.status_id === 103" type="warning">
              Өвчтэй
            </el-tag>
            <el-tag size="mini" v-else-if="scope.row.status_id === 102" type="primary">
              Чөлөөтэй
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="created_user_id" label="Бүртгэсэн хичээл" width="180">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.lesson_name == 'mat'">
              Математик
            </el-tag>
            <el-tag type="info" size="mini" v-if="scope.row.lesson_name == 'eng'">Англи хэл</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="created_user_id" label="Бүртгэсэн багш" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.teacher">
              {{ scope.row.teacher.name }}
            </template>
            <template v-else>Хоосон</template>
          </template>
        </el-table-column>
        <el-table-column prop="percent" label="Даалгавар хийсэн байдал">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      data: Array,
      studentData: Object,
      selectedType: String
    },
    data() {
      return {
        attendanceList: [],
        search: '',
        bagshiin_ner: ''
      }
    },
    created() {},
    methods: {
      handleCloseAttendanceDialog() {
        this.$root.attendaceDetailDialog = false
      },
      tableRowClassName({
        row,
        rowIndex
      }) {
        if (row.status_id === 101) {
          return 'danger-row';
        } else if (row.status_id === 103) {
          return 'warning-row';
        }else if (row.status_id === 102) {
          return 'primary-row';
        }else if (row.status_id === 100) {
          return 'success-row';
        }
        return '';
      }
    }
  }

</script>

<style>
  .el-table .danger-row {
    background: rgb(239, 221, 221);
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .el-table .warning-row {
    background: #fdefe3;
  }
  .el-table .primary-row {
    background: #c2deea;
  }

</style>
