<template>
  <div>
    <div class="home">
      <el-row type="flex" justify="space-between" class="page_head_zone">
        <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8">
          <h3 class="m-l-5 m-t-4">Үйл ажиллагаа</h3>
        </el-col>
        <el-col :xs="8" :sm="8" :md="3" :lg="3" :xl="3" class="user_head_add_button m-r-50">
          <el-button size="mini" type="success" @click="showDialog()">+ Үйл ажиллагаа нэмэх</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="home_body p-5">
          <FullCalendar :options="calendarOptions">
          </FullCalendar>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="Үйл ажиллагаа бүртгэх" :visible.sync="dialogVisible" width="380px">
      <span>
        <el-form label-position='top'>
          <el-form-item label="Төрөл">
            <el-radio-group size="mini" v-model="event.event_type">
            <el-radio-button label="level">Төвшин</el-radio-button>
            <el-radio-button label="meeting">Уулзалт</el-radio-button>
          </el-radio-group>
          </el-form-item>
          <el-form-item label="Гарчиг">
            <el-input v-model="event.title" placeholder="Гарчиг оруулна уу"></el-input>
          </el-form-item>
          <el-form-item label="Утас">
            <el-input v-model="event.phone" placeholder="Утасны дугаар оруулна уу"></el-input>
          </el-form-item>
          <el-form-item label="Тайлбар">
            <el-input type="textarea" v-model="event.description" placeholder="Үйл ажилгаатай холбоотой тайлбар"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="24">
              <el-form-item label="Эхлэх огноо">
                <el-date-picker v-model="event.start" placeholder="эхлэх цаг" value-format="yyyy/MM/dd HH:mm" format="yyyy/MM/dd HH:mm" type="datetime" style="width: 100%;">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-form-item label="Орж ирсэн суваг">
              <el-radio-group size="mini" v-model="event.chanel">
                <el-radio-button label="level">Дуудлага</el-radio-button>
                <el-radio-button label="meeting">Чат</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <!-- <el-col :span="24">
              <el-form-item label="Дуусах огноо">
                <el-date-picker v-model="event.end" placeholder="дуусах цаг" value-format="yyyy/MM/dd HH:mm" format="yyyy/MM/dd HH:mm"
                  :picker-options="datePickerOptions" type="datetime" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col> -->
          </el-row>
            <el-form-item v-if="event.id" label="Төлөв">
              <el-radio-group size="mini" v-model="event.status">
                <el-radio-button label="create">Үүсгэсэн</el-radio-button>
                <el-radio-button label="success">Амжилттай</el-radio-button>
                <el-radio-button label="insuccess">Ирээгүй</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="event.event_type == 'level'" label="Төлбөр">
              <el-radio-group size="mini" v-model="event.payment">
                <el-radio-button label="1">Төлсөн</el-radio-button>
                <el-radio-button label="0">Төлөөгүй</el-radio-button>
              </el-radio-group>
            </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Буцах</el-button>
        <el-button type="primary" @click="createEvent()">Хадгалах</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import services from '@/helpers/services'
  import {
    notification
  } from '@/helpers/custom'

  export default {
    components: {
      FullCalendar
    },
    name: 'EventPage',
    data() {
      return {
        // datePickerOptions: {
        //   disabledDate(date) {
        //     return date < new Date()
        //   },
        // },
        dialogVisible: false,
        event: {
          title: '',
          description: '',
          start: null,
          event_type:'level',
          status:'create',
          phone:'',
          chanel: '',
          payment: 0
          // end: ''
        },
        events: [],
        calendarOptions: {
          headerToolbar: {
            start: 'dayGridMonth,dayGridWeek,dayGridDay',
            center: 'title',
            end: 'prev,next'
          },
          titleFormat: {
            hour12: false
          },
          buttonText: {
            dayGridDay: 'Өдөр',
            dayGridWeek: '7 хоног',
            dayGridMonth: 'Сар'
          },
          eventTimeFormat: { // like '14:30:00'
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short'
          },
          selectable: true,
          plugins: [interactionPlugin, dayGridPlugin],
          initialView: 'dayGridMonth',
          dateClick: (arg) => {
            this.onHandleDateClick(arg)
          },
          eventClick: (data) => {
            this.dialogVisible = true
            console.log(data.event, 'sd')
            this.event.title = data.event.title
            this.event.id = data.event.id
            this.event.start = data.event.startStr
            this.event.event_type = data.event.extendedProps.event_type
            this.event.status = data.event.extendedProps.status
            // this.event.end = data.event.endStr
            this.event.description = data.event.extendedProps.description
            this.event.phone = data.event.extendedProps.phone
            this.event.chanel = data.event.extendedProps.chanel
            this.event.payment = data.event.extendedProps.payment
          },
          events: [],
        }
      }
    },
    mounted() {
      services.getEvents()
        .then((response) => {
          response.result.forEach(element => {
            if(element.status === 'success'){
              element.color = '#00FF00'
            }
            else if(element.status === 'insuccess'){
              element.color = '#FF0000'
            }
            else if(element.payment === 1){
              element.color = '#FFD700'
            }
            else if(element.event_type === 'meeting'){
              element.color = '#581845'
            }
            else if(element.event_type === 'level'){
              element.color = '#F18A85'
            }
            //   if(element.event_type === 'level'){FFFF00
            //   element.color = '#F18A85'
            // }else if(element.event_type === 'meeting'){
            //   element.color = '#581845'
            // }
          this.calendarOptions.events = response.result
          });
        })
    },
    methods: {
      onHandleDateClick(arg){

      },
      showDialog() {
        this.dialogVisible = true
        this.event = {
          title: '',
          description: '',
          start: '',
          event_type:'level',
          status:'create',
          phone:'',
          chanel: '',
          payment: 0
          // end: ''
        }
      },
      createEvent() {
        services.createEvent(this.event)
          .then((response) => {
            if (response.status === 'success') {
              this.dialogVisible = false
              notification('success_save')
              location.reload()
            }
          })
      }
    }
  }

</script>
<style>
  .fc-event-time {
    font-weight: 400 !important;
  }

</style>
