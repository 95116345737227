<template>
    <div class="home">
      <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
        <el-col :span="8">
          <h3 class="m-l-25">Санал хүсэлтийн жагсаалт</h3>
        </el-col>
      </el-row>
      <el-row class="m-l-30">
        <el-col :span="23" class="home_body p-20">
          <el-table v-loading="loading" :data="userRequestList" stripe style="width: 100%">
            <el-table-column type="index" width="50">
            </el-table-column>
                <el-table-column prop="user_name" label="Нэр">
                </el-table-column>
                <el-table-column prop="phone" label="Утас">
                </el-table-column>
                <el-table-column prop="description" label="Дэлгэрэнгүй">
                </el-table-column>
                <el-table-column prop="status" label="Төлөв" :filters="[{text: 'Шийдвэрлэсэн', value: 'Шийдвэрлэсэн'}, {text: 'Шийдвэрлээгүй', value: 'Шийдвэрлээгүй'}]"
                :filter-method="filterHandler">
                <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.status === 'Шийдвэрлэсэн' ? 'success' : 'danger'"
                    disable-transitions>{{scope.row.status}}
                  </el-tag>
                </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Ирсэн огноо" >
                </el-table-column>
                <el-table-column label="Үйлдэл">
                  <template slot-scope="scope">
                    <el-button
                      size="mini" type="primary"
                      @click="addBlogDialog(scope.row)">Харах</el-button>
                  </template>
                </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-dialog title="Санал хүсэлт" :visible.sync="this.$root.categoryCreatDialog" width="30%" :before-close="handleClose">
        <div class="reqDetailInfo">
          <table></table>
          <el-row prop="user_name">
            <el-col style="color:grey" :span="6">
              Нэр :
            </el-col>
            <el-col :span="18">
              <span class="m-l-20" style="color:black">{{selectedRow.user_name}}</span>
            </el-col>
          </el-row>
          <el-row prop="phone">
            <el-col style="color:grey" :span="6">
              Утас :
            </el-col>
            <el-col :span="18">
              <span class="m-l-20" style="color:black">{{selectedRow.phone}}</span>
            </el-col>
          </el-row>
          <el-row prop="description">
            <el-col style="color:grey" :span="6">
              Дэлгэрэнгүй :
            </el-col>
            <el-col :span="18">
              <span class="m-l-20" style="color:black">{{selectedRow.description}}</span>
            </el-col>
          </el-row>
          <el-row prop="status">
            <el-col style="color:grey" :span="6">
              Төлөв :
            </el-col>
            <el-col :span="18">
              <span class="m-l-20" style="color:black">{{selectedRow.status}}</span>
            </el-col>
          </el-row>
          <el-row prop="created_at">
            <el-col style="color:grey" :span="6">
              Ирсэн огноо :
            </el-col>
            <el-col :span="18">
              <span class="m-l-20" style="color:black">{{selectedRow.created_at}}</span>
            </el-col>
          </el-row>
          <el-row prop="employee_description" v-if="selectedRow.employee_description != null">
            <el-col style="color:grey" :span="6">
              Хариу :
            </el-col>
            <el-col :span="18">
              <span class="m-l-20" style="color:black">{{selectedRow.employee_description}}</span>
            </el-col>
          </el-row>
          <el-row prop="name" v-if="selectedRow.employee_description != null">
            <el-col style="color:grey" :span="6">
              Ажилтан :
            </el-col>
            <el-col :span="18">
              <span class="m-l-20" v-if="selectedRow.employee" style="color:black">{{selectedRow.employee.name}}</span>
            </el-col>
          </el-row>
        </div>
        <div v-if="selectedRow.employee_description == null"  class="reqDetailLine m-t-20">
        <el-input type="textarea" v-model="sendBody.employee_description" class="m-t-20"></el-input>
        <div class="m-t-20">
        <el-button size="mini" type="success" @click="updateReq()">Хадгалах</el-button>
      </div>
      </div>
      </el-dialog>
    </div>
  </template>

<script>
import services from '@/helpers/services.js'
export default {
  name: 'userRequestList',
  data () {
    return {
      userRequestList: [],
      selectedUserRequestList: {
        user_name: '',
        phone: '',
        description: '',
        status: '',
        created_at: '',
        employee_description: '',
        name: '',
        dialogVisible: true
      },
      selectedRow: {},
      sendBody: {
        employee_description: '',
        id: null
      }
    }
  },
  created () {
    this.getUserRequestList()
  },
  methods: {
    getUserRequestList () {
      services.getUserRequest()
        .then((response) => {
          this.userRequestList = response.data
          this.loading = false
        })
    },
    resetDateFilter () {
      this.$refs.filterTable.clearFilter('status')
    },
    clearFilter () {
      this.$refs.filterTable.clearFilter()
    },
    formatter (row, column) {
      return row.description
    },
    filterTag (value, row) {
      return row.status === value
    },
    filterHandler (value, row, column) {
      const property = column.property
      return row[property] === value
    },
    addBlogDialog (data) {
      this.selectedRow = data
      this.$root.categoryCreatDialog = true
    },
    handleClose () {
      this.sendBody.employee_description = ''
      this.sendBody.id = null
      this.$root.categoryCreatDialog = false
    },
    updateReq () {
      this.$root.fullscreenLoading = true
      this.sendBody.id = this.selectedRow.id
      services.updateUserReq(this.sendBody)
        .then((response) => {
          if (response.status === 'success') {
            this.handleClose()
            this.getUserRequestList()
            this.$root.fullscreenLoading = false
          }
          // console.log(response)
        })
    }
  }
}

</script>

  <style>

  </style>
