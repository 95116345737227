<template>
  <div class="home">
    <el-row type="flex" justify="space-between" class="page_head_zone">
      <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8">
        <h3 class="m-l-25">Өвчтэй чөлөөтэй сурагчдийн жагсаалт</h3>
      </el-col>
	  <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8">
        Чөлөө өгөх
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-input v-model="search" size="mini" placeholder="Сурагчийн нэрээр хайх" />
        <el-table :data="studentList" class="p-20" size="mini">
          <el-table-column type="index" width="80">
          </el-table-column>
          <el-table-column label="Овог" prop="student_vacation.name">
            <template slot-scope="scope">
              <div v-if="scope.row.student">
              {{ scope.row.student.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Нэр" prop="student_vacation.name">
            <template slot-scope="scope">
              <div v-if="scope.row.student">
              {{ scope.row.student.last_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Төлөв" prop="attendance_status">
            <template slot-scope="scope">
              <el-tag size="mini" v-if="scope.row.status_code == 102" type="warning" effect="dark">
                Чөлөө
              </el-tag>
              <el-tag size="mini" v-else-if="scope.row.status_code == 103" type="primary" effect="dark">
                Өвчтэй
              </el-tag>
              <el-tag size="mini" v-else-if="scope.row.status_code == 101" type="danger" effect="dark">
                Тас
              </el-tag>
              <el-tag size="mini" v-else type="info" effect="dark">
                Буруу
              </el-tag>
            </template>
          </el-table-column>
		  <el-table-column label="Чөлөөтэй/ Өвчтэй өдрүүд" prop="date">
			<template slot-scope="scope">
  				<el-tag size="mini" class="m-l-20" v-for="item, index in scope.row.student_vacation_detail_get" :key="index"> {{ item.vacation_date }}</el-tag>
			</template>
		</el-table-column>
          <el-table-column label="Тайлбар" prop="description">
          </el-table-column>
          <el-table-column label="Тайлбар" >
            <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="editVacation(scope.row)">
              Засах
           </el-button>
           <el-button size="mini" type="danger" @click="deleteVacation(scope.row)">
              Устгах
           </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <StudentVacationComponent v-if="this.$root.studentVacation" :student="student" />
  </div>
</template>

<script>
  import services from '@/helpers/services.js'
  import StudentVacationComponent from '@/components/student/component/studentVacation.vue'
  export default {
    data() {
      return {
        studentList: [],
        search: '',
        bagshiin_ner: '',
        student:{
        }
      }
    },
    components:{
      StudentVacationComponent
    },
    created() {
      this.student_vacation()
    },
    methods: {
      deleteVacation(data){
        this.$confirm('Та чөлөөг цуцлахдаа итгэлтэй байна уу', 'Анхаар', {
          confirmButtonText: 'Мхм',
          cancelButtonText: 'Гүүгүүү',
          type: 'warning'
        }).then(() => {
          var payload = {
            id : data.id
          }
          services.deleteVacation(payload)
          .then((response) => {
            if(response.status === 'success'){
              this.$message({
            type: 'success',
            message: 'Delete completed'
          });
          this.student_vacation()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          });          
        });
      },
      editVacation(data){
        this.$root.studentVacation = true;
        this.student = data
      },
      student_vacation() {
        this.$root.fullscreenLoading = true
        services.getStudentVacation()
          .then((response) => {
            this.studentList = response.result
            this.$root.fullscreenLoading = false
          })
      },
      filterHandler(value, row, column) {
        const property = column.property
        return row[property] === value
      }
    }
  }

</script>

<style>

</style>
