<template>
  <div class="home">
    <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
      <el-col :span="8">
        <h3 class="m-l-25">Эцэг эхий асуулт хариулт жагсаалт</h3>
      </el-col>
      <el-col :span="3" class="user_head_add_button m-r-25">
        <el-button size="mini" type="success" @click="addCommentDialog()">+ Сэтгэгдэл оруулах</el-button>
      </el-col>
    </el-row>
    <el-row class="m-l-30">
      <el-col :span="23" class="home_body p-20">
        <el-table v-loading="loading" :data="tabledata" stripe style="width: 100%" height="250">
          <el-table-column type="index" width="50">
          </el-table-column>
          <el-table-column prop="parent_name" label="Нэр">
          </el-table-column>
          <el-table-column prop="comment" label="Сэтгэгдэл">
          </el-table-column>
          <el-table-column prop="social_channel" label="Цахим сүлжээ">
          </el-table-column>
          <el-table-column prop="user_info.name" label="Оруулсан ажилтан">
          </el-table-column>
          <el-table-column prop="is_active" label="Төлөв">
            <template slot-scope="scope">
              <el-switch @change="changeSwitchComment(scope.row.id, scope.row.is_active, scope.row.comment)"
                v-model="scope.row.is_active" active-color="#13ce66" inactive-color="gray" :active-value="1"
                :inactive-value="0">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="Үйлдэл">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="editComment(scope.row)" circle>
              </el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteComment(scope.row)" circle>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="Блог үүсгэх" :visible.sync="this.$root.createCommentDialog" width="50%"
      :before-close="handleClose">
      <el-form :model="createCommentDialog" :rules="rules" ref="ruleForm" label-width="120px" label-position="top"
        class="demo-ruleForm">
        <el-form-item label="Сэтгэгдлийг үүсгэсэн нэр" prop="parent_name">
          <el-input v-model="createCommentDialog.parent_name" placeholder="Сэтгэгдлийг үүсгэсэн нэр"></el-input>
        </el-form-item>
        <el-form-item label="Сэтгэгдэл" prop="comment">
          <el-input v-model="
              createCommentDialog.comment" type="textarea" placeholder="Сэтгэгдэл"></el-input>
        </el-form-item>
        <el-form-item label="Social суваг" prop="social_channel">
          <el-select v-model="createCommentDialog.social_channel" placeholder="Social суваг" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="createComment('ruleForm')">Үүсгэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import {
  notification
} from '@/helpers/custom'
export default {
  data () {
    return {
      options: [{
        value: 'Facebook',
        label: 'Facebook'
      }, {
        value: 'Instagram',
        label: 'Instagram'
      }, {
        value: 'Youtube',
        label: 'Youtube'
      }, {
        value: 'Twitter',
        label: 'Twitter'
      }],
      value: '',
      rules: {
        parent_name: [{
          required: true,
          message: 'Нэр оруулна уу',
          trigger: 'blur'
        }],
        comment: [{
          required: true,
          message: 'Сэтгэгдэл оруулна уу',
          trigger: 'blur'
        }],
        social_channel: [{
          required: true,
          message: 'Social суваг оруулна уу',
          trigger: 'blur'
        }]
      },
      createCommentDialog: {
        parent_name: '',
        comment: '',
        is_active: 1,
        social_channel: '',
        image_url: 'url5'
      },
      tabledata: [],
      loading: true
    }
  },
  created () {
    this.getList()
  },
  methods: {
    deleteComment (data) {
      this.$confirm(data.parent_name + ' усгах гэж байна итгэлтэй байна уу.', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const payload = {
          id: data.id
        }
        services.deleteParentsComment(payload)
          .then((response) => {
            if (response.status === 'success') {
              this.$root.createCommentDialog = false
              notification('success_deleted')
              this.$root.fullscreenLoading = false
              this.getList()
            }
            // console.log(response)
          })
      }).catch(() => {})
    },
    createComment (formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          services.createParentsComment(this.createCommentDialog)
            .then((response) => {
              if (response.status === 'success') {
                this.$root.createCommentDialog = false
                notification('success_save')
                this.$root.fullscreenLoading = false
                location.reload()
              } else if (response.status === 'unSuccess') {
                this.$root.fullscreenLoading = false
              }
              // console.log(response)
            })
        }
      })
    },
    changeSwitchComment (id, newStatus, name) {
      this.$confirm(name + ' асуултын өөрчлөхдөө итгэлтэй бнуу?', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Үгүй',
        type: 'warning'
      }).then(() => {
        const payload = {
          id: id,
          status: newStatus
        }
        services.statusChangeComment(payload)
          .then((response) => {
            if (response.status === 'success') {
              notification('success_position')
              this.$root.fullscreenLoading = false
              this.getList()
            }
            // console.log(response)
          })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },
    handleClose () {
      this.$root.createCommentDialog = false
    },
    editComment (data) {
      this.createCommentDialog = data
      this.$root.createCommentDialog = true
    },
    addCommentDialog () {
      this.createCommentDialog.parent_name = ''
      this.createCommentDialog.comment = ''
      this.createCommentDialog.social_channel = ''
      this.$root.createCommentDialog = true
    },
    getList () {
      services.getParentsComment()
        .then((response) => {
          this.tabledata = response.result
          this.loading = false
        })
    }
  }
}

</script>
