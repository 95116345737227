<template>
  <div class="home">
    <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
      <el-col :span="8">
        <h3 class="m-l-25">Албан тушаалын жагсаалт</h3>
      </el-col>
      <!-- <el-col :span="3" class="user_head_add_button m-r-25">
        <el-button size="mini" type="success" @click="addPositionDialog()">+ Албан тушаал үүсгэх</el-button>
      </el-col> -->
    </el-row>
    <el-row class="m-l-30">
      <el-col :span="23" class="home_body p-20">
        <el-table v-loading="loading" :data="positionList" stripe style="width: 100%">
          <el-table-column type="index" width="50">
          </el-table-column>
          <el-table-column prop="position_name" label="Албан тушаалын нэр">
          </el-table-column>
          <el-table-column prop="position_description" label="Албан тушаалын тайлбар">
          </el-table-column>
          <!-- <el-table-column label="Үйлдэл">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" icon="el-icon-edit" circle  @click="editPosition(scope.row)"></el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" circle  @click="deletePosition(scope.row)" ></el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="Албан тушаал үүсгэх" :visible.sync="this.$root.positionCreateDialog" width="50%"
      :before-close="handleClose">
      <el-form :model="selectedPosition" :rules="rules" ref="ruleForm" label-width="120px" label-position="top"
        class="demo-ruleForm">
        <el-form-item label="Албан тушаалын гарчиг" prop="position_name">
          <el-input v-model="selectedPosition.position_name" placeholder="Гарчиг"></el-input>
        </el-form-item>
        <el-form-item label="Албан тушаалын тайлбар" prop="position_description">
          <el-input v-model="selectedPosition.position_description" placeholder="Тайлбар"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="createPosition('ruleForm')">Үүсгэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import { notification } from '@/helpers/custom'
export default {
  data () {
    return {
      rules: {
        position_name: [
          { required: true, message: 'Албан тушаалын гарчиг оруулна уу', trigger: 'blur' }
        ],
        position_description: [
          { required: true, message: 'Албан тушаалын тайлбар оруулна уу', trigger: 'blur' }
        ]
      },
      selectedPosition: {
        position_name: '',
        position_description: ''
      },
      positionList: [],
      loading: true
    }
  },
  created () {
    this.getPositionList()
  },
  methods: {
    deletePosition (data) {
      this.$confirm(data.position_name + ' нэртэй албан тушаалыг усгах гэж байна итгэлтэй байна уу.', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const payload = {
          id: data.id
        }
        services.deletePosition(payload)
          .then((response) => {
            if (response.status === 'success') {
              this.$root.positionCreateDialog = false
              notification('success_deleted')
              this.$root.fullscreenLoading = false
              location.reload()
            }
            // console.log(response)
          })
      }).catch(() => {
      })
    },
    createPosition (formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          services.createPosition(this.selectedPosition)
            .then((response) => {
              if (response.status === 'success') {
                this.$root.positionCreateDialog = false
                notification('success_save')
                this.$root.fullscreenLoading = false
                location.reload()
              } else if (response.status === 'unSuccess') {
                this.$root.fullscreenLoading = false
              }
              // console.log(response)
            })
        }
      })
    },
    handleClose () {
      this.$root.positionCreateDialog = false
    },
    editPosition (data) {
      this.selectedPosition = data
      this.$root.positionCreateDialog = true
    },
    addPositionDialog () {
      this.selectedPosition.position_name = ''
      this.selectedPosition.position_description = ''
      this.$root.positionCreateDialog = true
    },
    getPositionList () {
      services.getPosition()
        .then((response) => {
          this.positionList = response.data
          this.loading = false
        })
    }
  }
}

</script>

<style>

</style>
