import Vue from 'vue'
import Vuex from 'vuex'
import services from '@/helpers/services.js'
// import { notification } from '@/helpers/custom.js'

Vue.use(Vuex)
const idToken = window.localStorage.getItem('idToken')
const userId = window.localStorage.getItem('userId')
const role = window.localStorage.getItem('role')
const user = window.localStorage.getItem('user')
const activeTab = window.localStorage.getItem('activeTab')

export default new Vuex.Store({
  state: {
    idToken: idToken ? JSON.parse(idToken) : null,
    userId: userId ? JSON.parse(userId) : null,
    role: role ? JSON.parse(role) : [],
    userInfo: user ? JSON.parse(user) : null,
    activeTab: activeTab
  },
  getters: {
    idToken (state) {
      return state.idToken
    },
    getterActiveTab (state) {
      return state.activeTab
    }
  },
  mutations: {
    changeTab (state, tabValue) {
      state.activeTab = tabValue
      window.localStorage.setItem('activeTab', parseInt(state.activeTab))
    },
    authUser (state, userData) {
      state.idToken = userData.token
      state.user = userData.user
      window.localStorage.setItem('idToken', JSON.stringify(state.idToken))
      window.localStorage.setItem('user', JSON.stringify(state.user))
    },
    addRole (state, roleData) {
      state.role = roleData.role
      window.localStorage.setItem('role', JSON.stringify(state.role))
    },
    addUserInfo (state, userData) {
      state.userInfo = userData
      window.localStorage.setItem('user', JSON.stringify(state.userInfo))
    }
  },
  actions: {
    changeActiveTab ({ commit }, response) {
      commit('changeTab', response)
    },
    authUserLoginPage ({ commit }, response) {
      commit('authUser', response)
    },
    addRoleLoginPage ({ commit }, response) {
      commit('addRole', response)
    },
    userAdd ({ commit }, response) {
      commit('addUserInfo', response)
    },
    signin ({ commit }, authData) {
      return new Promise((resolve, reject) => {
        services.login(authData)
          .then((response) => {
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    }
  },
  modules: {
  }
})
