<template>
  <div class="p-l-30">
    <el-row>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm"
        label-position="top">
        <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
          <el-form-item v-if="this.$router.currentRoute.path != '/publicStudentAdd2'" label="Өмнө нь бүртгэлтэй байсан эсэх">
            <el-radio-group v-model="isRegister" size="small">
              <el-radio-button :label="true">Тийм</el-radio-button>
              <el-radio-button :label="false">Үгүй</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <div v-if="!isRegister">
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item label="Асран хамгаалагчийн овог" prop="name">
              <el-input v-model="ruleForm.name" clearable placeholder="Асран хамгаалагчийн овог оруулна уу"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item label="Асран хамгаалагчийн нэр" prop="last_name">
              <el-input v-model="ruleForm.last_name" clearable placeholder="Асран хамгаалагчийн нэр оруулна уу">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item label="Асран хамгаалагчийн РД " clearable prop="regno">
              <el-input v-model="ruleForm.regno" clearable
                placeholder=" Асран хамгаалагчийн регистрийн дугаар оруулна уу" minlength="10" maxlength="10"
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item label="Асран хамгаалагчийн утас" prop="phone">
              <el-input v-model="ruleForm.phone" placeholder="Асран хамгаалагчийн утас оруулна уу" type="text"
                minlength="8" maxlength="8" clearable show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item label="Сурагчийн хэн болох" prop="is_who_name">
              <el-input v-model="ruleForm.is_who_name" placeholder="Сурагчийн хэн болох" type="text" clearable
                show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
            <el-form-item label="И-мэйл" prop="email">
              <el-input v-model="ruleForm.email" clearable placeholder="Та и-мэйл оруулна уу">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item label="Хүйс" prop="gender">
              <el-radio-group class="addproduct_select_type_group" v-model="ruleForm.gender">
                <el-radio-button v-for="(selectItem, index) in this.selectTypeList" :key="index" :span="4"
                  :label="selectItem.value" :class="'product_type_cart_'+'active'"
                  :style="'margin-left:'+ selectItem.leftSpace+'px'">
                  <el-row>
                    <el-col :span="24">
                      <span :class="'addproduct_image_'+selectItem.value"></span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="addproduct_select_type_text_value">
                      {{selectItem.name}}
                    </el-col>
                  </el-row>
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item label="Дүүрэг" prop="duureg">
                <el-select v-model="ruleForm.duureg" placeholder="Дүүрэг" clearable>
                  <el-option v-for="item in optionsDuureg" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>            
            </el-form-item>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item label="Хороо" prop="horoo">
                <el-select v-model="ruleForm.horoo" placeholder="Хороо" clearable>
                  <el-option v-for="item in optionsHoroo" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>            
            </el-form-item>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item label="Нэмэлт тайлбар" prop="description">
              <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" v-model="ruleForm.description"
                placeholder="Нэмэлт тайлбар"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
            <el-form-item label="Дэлгэрэгүй хаяг" prop="address">
              <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" v-model="ruleForm.address"
                placeholder="Дэлгэрэгүй хаяг"></el-input>
            </el-form-item>
          </el-col>
        </div>
      </el-form>
    </el-row>
    <el-row v-if="isRegister" class="m-b-20">
          <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
            <el-input
          v-model="search"
          size="mini"
          placeholder="Нэрээр хайх"/>
            <el-table
            :data="parentListData.filter(data => !search || data.last_name.toLowerCase().includes(search.toLowerCase()))"
            v-loading = "loading"
             border size style="width: 100%">
              <el-table-column fixed prop="last_name" label="Нэр">
              </el-table-column>
              <el-table-column prop="name" label="Овог">
              </el-table-column>
              <el-table-column prop="phone" label="Утас">
              </el-table-column>
              <el-table-column prop="regno" label="РД">
              </el-table-column>
              <el-table-column fixed="right" label="Operations" width="120">
                <template slot-scope="scope">
                  <el-button @click.native.prevent="checkedParent(scope.row)" type="text" size="small">
                    Сонгох
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>

    </el-row>
    <el-row>
      <el-col :span="22">
        <el-button type="primary" class="button" @click="submitForm('ruleForm')">Хадгалах</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import services from '@/helpers/services.js'
import {
  notification,
  returnValidationErrorMessage
} from '@/helpers/custom.js'
  // import Upload from '@/components/uploadImage/dynamicUploadImage.vue'
export default {
  props: {
    user: Object
  },
  created () {
    // this.getPositionList()
    this.calculateMyData()
  },
  components: {
    // Upload
  },
  data () {
    return {
      loading: true,
      search: '',
      isRegister: false,
      selectTypeList: [{
        id: 1,
        name: 'Эрэгтэй',
        value: 1,
        leftSpace: 0
      }, {
        id: 2,
        name: 'Эмэгтэй',
        value: 2,
        leftSpace: 40
      }],
      activeStep: 2,
      positionList: [],
      ruleForm: {
        student_id: this.$route.params.id,
        name: '',
        last_name: '',
        gender: 1,
        regno: '',
        phone: '',
        position_id: 14,
        email: '',
        img_url: '',
        password: '',
        is_show_web: 0,
        is_who_name: '',
        description: '',
        address: '',
        duureg: '',
        horoo: ''
      },
      optionsDuureg: [{
        value: 'Баянгол дүүрэг',
        label: 'Баянгол дүүрэг'
      }, {
        value: 'Баянзүрэх дүүрэг',
        label: 'Баянзүрэх дүүрэг'
      }, {
        value: 'Хануул дүүрэг',
        label: 'Хануул дүүрэг'
      }, {
        value: 'Сонгинохайрхан дүүрэг',
        label: 'Сонгинохайрхан дүүрэг'
      }, {
        value: 'Чингэлтэй дүүрэг',
        label: 'Чингэлтэй дүүрэг'
      }, {
        value: 'Сүхбаатар дүүрэг',
        label: 'Сүхбаатар дүүрэг'
      }, {
        value: 'Налайх дүүрэг',
        label: 'Налайх дүүрэг'
      }, {
        value: 'Багахангай дүүрэг',
        label: 'Багахангай дүүрэг'
      }, {
        value: 'Багануур дүүрэг',
        label: 'Багануур дүүрэг'
      }],
      optionsHoroo: [{
        value: '1-р хороо',
        label: '1-р хороо'
      }, {
        value: '2-р хороо',
        label: '2-р хороо'
      }, {
        value: '3-р хороо',
        label: '3-р хороо'
      }, {
        value: '4-р хороо',
        label: '4-р хороо'
      }, {
        value: '5-р хороо',
        label: '5-р хороо'
      }, {
        value: '6-р хороо',
        label: '6-р хороо'
      }, {
        value: '7-р хороо',
        label: '7-р хороо'
      }, {
        value: '8-р хороо',
        label: '8-р хороо'
      }, {
        value: '9-р хороо',
        label: '9-р хороо'
      }, {
        value: '10-р хороо',
        label: '10-р хороо'
      }, {
        value: '11-р хороо',
        label: '11-р хороо'
      }, {
        value: '12-р хороо',
        label: '12-р хороо'
      }, {
        value: '13-р хороо',
        label: '13-р хороо'
      }, {
        value: '14-р хороо',
        label: '14-р хороо'
      }, {
        value: '15-р хороо',
        label: '15-р хороо'
      }, {
        value: '16-р хороо',
        label: '16-р хороо'
      }, {
        value: '17-р хороо',
        label: '17-р хороо'
      }, {
        value: '18-р хороо',
        label: '18-р хороо'
      }, {
        value: '19-р хороо',
        label: '19-р хороо'
      }, {
        value: '20-р хороо',
        label: '20-р хороо'
      }, {
        value: '21-р хороо',
        label: '21-р хороо'
      }, {
        value: '22-р хороо',
        label: '22-р хороо'
      }, {
        value: '23-р хороо',
        label: '23-р хороо'
      }, {
        value: '24-р хороо',
        label: '24-р хороо'
      }, {
        value: '25-р хороо',
        label: '25-р хороо'
      }, {
        value: '26-р хороо',
        label: '26-р хороо'
      }, {
        value: '27-р хороо',
        label: '27-р хороо'
      }, {
        value: '28-р хороо',
        label: '28-р хороо'
      }, {
        value: '29-р хороо',
        label: '29-р хороо'
      }, {
        value: '30-р хороо',
        label: '30-р хороо'
      }, {
        value: '31-р хороо',
        label: '31-р хороо'
      }, {
        value: '32-р хороо',
        label: '32-р хороо'
      }, {
        value: '33-р хороо',
        label: '33-р хороо'
      }, {
        value: '34-р хороо',
        label: '34-р хороо'
      }, {
        value: '35-р хороо',
        label: '35-р хороо'
      }, {
        value: '36-р хороо',
        label: '36-р хороо'
      }, {
        value: '37-р хороо',
        label: '37-р хороо'
      }, {
        value: '38-р хороо',
        label: '38-р хороо'
      }, {
        value: '39-р хороо',
        label: '39-р хороо'
      }, {
        value: '40-р хороо',
        label: '40-р хороо'
      }, {
        value: '41-р хороо',
        label: '41-р хороо'
      } , {
        value: '42-р хороо',
        label: '42-р хороо'
      } , {
        value: '43-р хороо',
        label: '43-р хороо'
      } , {
        value: '44-р хороо',
        label: '44-р хороо'
      }, {
        value: '45-р хороо',
        label: '45-р хороо'
      }, {
        value: '46-р хороо',
        label: '46-р хороо'
      }, {
        value: '47-р хороо',
        label: '47-р хороо'
      }, {
        value: '48-р хороо',
        label: '48-р хороо'
      }, {
        value: '49-р хороо',
        label: '49-р хороо'
      }],
      is_upload_image: true,
      rules: {
        profile_img_url: [{
          required: true,
          message: 'Та  зурагаа уу',
          trigger: 'change '
        }],
        name: [{
          required: true,
          message: 'Та овог оруулна уу',
          trigger: 'change '
        }],
        last_name: [{
          required: true,
          message: 'Та нэрээ оруулна уу',
          trigger: 'change'
        }],
        regno: [{
          required: true,
          message: 'Та бөглөнө үү',
          trigger: 'change'
        },
        {
          min: 10,
          max: 10,
          message: 'Буруу РД дугаар байна',
          trigger: 'blur'
        }
        ],
        // is_who_name: [{
        //   required: true,
        //   message: 'Та сурагчийн хэн болохоо бөглөнө үү',
        //   trigger: 'change'
        // }
        // ],
        // position_id: [{
        //   required: true,
        //   message: 'Та хэрэглэгчийн эрх сонгоно уу',
        //   trigger: 'change'
        // }],
        phone: [{
          required: true,
          message: 'Та утасны дугаараа оруулна уу',
          trigger: 'change'
        }],
        gender: [{
          required: true,
          message: 'Та бөглөнө үү',
          trigger: 'change'
        }],
        // email: [{
        //   required: true,
        //   message: 'Та и-мэйл бөглөнө үү',
        //   trigger: 'change'
        // },
        // {
        //   type: 'email',
        //   message: 'Та и-мэйл бөглөнө үү',
        //   trigger: ['blur', 'change']
        // }
        // ],
        password: [{
          required: true,
          message: 'Та нууц үгээ оруулна уу',
          trigger: 'change'
        }]
      },
      tableData: [],
      is_password_show: true,
      parentListData: []
    }
  },
  watch: {
    isRegister () {
      this.getCustomPositionsList()
    }
  },
  methods: {
    checkedParent (data) {
      this.ruleForm.id = data.id
      this.ruleForm.name = data.name
      this.ruleForm.last_name = data.last_name
      this.ruleForm.gender = data.gender
      this.ruleForm.regno = data.regno
      this.ruleForm.position_id = data.position_id
      this.ruleForm.email = data.email
      this.ruleForm.img_url = data.img_url
      this.ruleForm.phone = data.phone
      this.ruleForm.description = data.description
      this.ruleForm.address = data.address
      this.ruleForm.duureg = data.duureg
      this.ruleForm.horoo = data.horoo
      this.isRegister = false
    },
    getCustomPositionsList () {
      const payload = {
        positionIds: ['14']
      }
      services.getCustomPositionsList(payload)
        .then((response) => {
          this.loading = false
          this.parentListData = response.result
        })
    },

    calculateMyData () {
      if (this.$route.params.id !== undefined) {
        this.$root.fullscreenLoading = true
        this.is_password_show = false
        const jishee = {
          student_id: this.$route.params.id
        }
        services.getParentStudentByID(jishee)
          .then(response => {
            if (response.status === 'success') {
              this.ruleForm.id = response.result.id
              this.ruleForm.student_id = this.$route.params.id
              this.ruleForm.name = response.result.name
              this.ruleForm.last_name = response.result.last_name
              this.ruleForm.gender = response.result.gender
              this.ruleForm.regno = response.result.regno
              this.ruleForm.phone = response.result.phone
              this.ruleForm.position_id = response.result.position_id
              this.ruleForm.email = response.result.email
              this.ruleForm.img_url = response.result.img_url
              this.ruleForm.password = response.result.password
              this.ruleForm.is_show_web = response.result.is_show_web
              this.ruleForm.is_who_name = response.result.parent.is_who_name
              this.ruleForm.description = response.result.description
              this.ruleForm.address = response.result.address
              this.ruleForm.duureg = response.result.duureg
              this.ruleForm.horoo = response.result.horoo
            }
            this.$root.fullscreenLoading = false
          })
      } else {
        console.log('undefined bn')
      }
    },
    getPositionList () {
      services.getPosition()
        .then((response) => {
          this.positionList = response.data
        })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          this.ruleForm.img_url = this.$root.img_url
          services.storeUserInfoParent(this.ruleForm)
            .then(response => {
              this.$root.fullscreenLoading = true
              if (response.status === 'success') {
                this.$root.fullscreenLoading = false
                notification('add_user_nofitcation')
                if (this.$router.currentRoute.path === '/publicStudentAdd2') {
                  this.$root.img_url = null
                  this.$confirm('Сурагчийн бүртгэл амжилттай хийгдлээ.Та менежертэй уулзана уу.', 'Амжилттай', {
                    confirmButtonText: 'OK',
                    type: 'success'
                  }).then(() => {
                    this.$router.push({
                      name: 'publicStudentAdd1'
                    })
                  })
                } else {
                  this.$router.push({
                    params: {
                      parent_id: response.result.id,
                      student_id: this.$route.params.id,
                      is_who_name: this.$route.params.is_who_name,
                      type: 'edit'
                    },
                    name: 'studentStep5'
                  })
                  this.$root.img_url = null
                }
                // if (this.activeStep++ > 2) this.activeStep = 1
                this.$root.fullscreenLoading = false
              } else if (response.status === 'failed') {
                returnValidationErrorMessage(response)
                this.$root.fullscreenLoading = false
                this.$root.img_url = null
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}

</script>
