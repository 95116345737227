<template>
  <el-dialog :visible.sync="this.$root.getStudentContractPageDialog" width="60%" center :before-close="handleClose"
    :show-close="false">
    <div id="printMe">
    <div  style="text-align: center; font-weight: 500; font-size: 7pt; text-align: justify; font-family: 'Times New Roman', Times, serif;">
      <p style="text-align: center;"><strong>СУРГАЛТЫН ГЭРЭЭ</strong></p>
      <div>

        <p>Улаанбаатар хот  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; Дугаар № 23-00{{ student.student_contract.id }} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          {{year}} оны {{month}}-р сарын {{ day }}</p>
      </div>
      <div>
        Энэхүү гэрээг нэг талаас <strong> {{student.regno}} </strong> регистрийн дугаартай, <strong> {{ student.name }}
        </strong> овогтой
        <strong> {{ student.last_name }} </strong> /цаашид Суралцагч гэх/, -ийг төлөөлж, хууль ёсны
        <span><strong
            v-if="student.parent_detail !== null">{{ student.parent_detail[0].parent_student.regno }}</strong></span>
        регистрийн дугаартай эцэг, эх /цаашид асран хамгаалагч/
        <span v-if="student.parent_detail !== null"><b>{{ student.parent_detail[0].parent_student.name }}</b></span>
        овогтой
        <span
          v-if="student.parent_detail !== null"><b>{{ student.parent_detail[0].parent_student.last_name }}</b></span>
        нөгөө талаас “Дэлхийн мэдлэг” ХХК-д менежер ажилтай
        <strong> Г.Солонго  </strong> /цаашид /Дэлхийн Мэдлэг Академи/- гэх нар МУ-ын иргэний хууль болон бусад хууль
        тогтоомжийг үндэслэн харилцан тохиролцож
        байгуулав. Энэхүү гэрээ нь Дэлхийн Мэдлэг Академи, сурагч, эцэг эх /асран хамгаалагч/-ийн эрх үүргийг
        тодорхойлж, тэдгээрийн хоорондын харилцааг зохицуулах
        үндсэн баримт бичиг болно.
      </div>
      <!-- НЭГ. ГЭРЭЭНИЙ НЭР ТОМЬЁО -->
      <div style="text-align: center">
        <strong>НЭГ. ГЭРЭЭНИЙ НЭР ТОМЬЁО</strong></div>
      <div class="txtleft">
        <div>
          1. &nbsp; Энэхүү гэрээнд дурдсан нэр томьёог дараах байдлаар ойлгоно. Үүнд:</div>
        <div class="m-l-12">
          1.1.1. “Дэлхийн мэдлэг академи” гэж Монгол Улсын бүртгэлд
          бүртгэгдсэн, үйл ажиллагаа явуулж буй хязгаарлагдмал хариуцлагатай хуулийн этгээдийг хэлнэ.
        </div>
        <div class="m-l-12">
          1.1.2. “Суралцагч” гэж Дэлхийн мэдлэг академид өөрийн болон
          асран хамгаалагчийн хүсэлтээр энэхүү гэрээг байгуулж, сургалтын хөтөлбөрт хамрагдаж буй бие даан суралцах
          чадвартай, өөрийгөө хөгжүүлэх <br> хүсэл, эрмэлзэлтэй,
          бусдын эрхийг хүндэтгэсэн иргэнийг хэлнэ.
        </div>
        <div class="m-l-12">
          1.1.3. “Багш” гэж сургалтын хөтөлбөрийн дагуу суралцагчид
          хичээл зааж буй, Дэлхийн мэдлэг академийн ажилтныг хэлнэ.
        </div>
        <div class="m-l-12">
          1.1.4. “Ажилтан” гэж суралцагчийг Дэлхийн мэдлэг академид
          сурах нөхцлөөр хангах, сургалтын орчныг сайжруулах, сургалтын хөтөлбөрт заасан арга хэмжээнд оролцоход дэмжлэг
          үзүүлэх зорилгоор ажиллаж буй суралцагчтай шууд
          харилцах эрх бүхий этгээдийг ойлгоно.
        </div>
        <div class="m-l-12">
          1.1.5. “Сургалт” гэж үндэсний болон олон улсын шаардлагад
          нийцсэн, Дэлхийн мэдлэг академийн хөтөлбөрийг энэ гэрээгээр суралцагч өөрөө болон асран хамгаалагч нь сурах
          боломжтой гэж үзсэн хичээлийн хөтөлбөрийг
          хэлнэ.
        </div>
        <div class="m-l-12">
          1.1.6. “Байгууллагын дүрэм” гэж энэхүү гэрээг байгуулахад
          суралцагч, түүний эцэг эхэд танилцуулсан “Дэлхийн мэдлэг академи”-ийн сургалтын орчинд суралцагч, багш,
          ажилтнуудын байгуулага дотор дагаж <br> мөрдөх дүрэм, журмыг
          хэлнэ.
        </div>
      </div>
      <!-- ХОЁР. ГЭРЭЭНИЙ ЕРӨНХИЙ НӨХЦӨЛ -->
      <div style="text-align: center"><strong>
          ХОЁР. ГЭРЭЭНИЙ ЕРӨНХИЙ НӨХЦӨЛ</strong>
      </div>
      <div class="txtleft">
        <div>
          2.1 &nbsp; <strong>{{ student.student_contract.contract_lessons[0].start_date.split('-')[0] }}</strong> оны <strong>
            {{ student.student_contract.contract_lessons[0].start_date.split('-')[1] }} </strong> сарын
          <strong> {{ student.student_contract.contract_lessons[0].start_date.split('-')[2] }} </strong> өдрөөс эхлэн
          <strong> {{ student.student_contract.contract_lessons[0].end_date.split('-')[0] }} </strong> оны <strong>
            {{ student.student_contract.contract_lessons[0].end_date.split('-')[1] }} </strong> сарын
          <strong> {{ student.student_contract.contract_lessons[0].end_date.split('-')[2] }} </strong> -ний өдрийг дуустал гэрээний хавсралтад
          заасан
          хөтөлбөрийг үндэслэн үе шаттайгаар хэрэгжинэ.<br>
          <!-- <div>
            2.2 &nbsp; Сургалтын хугацаа <strong>{{ student.student_contract.contract_lessons[0].student_month }}</strong> сар байна.
          </div>
          <div>
            2.3 &nbsp; Нэг сар нь 4-н долоо хоног байна.
          </div> -->
          <template>2.2 &nbsp; Суралцагч тал Дэлхийн Мэдлэг Академийн <span
              v-if="student.student_contract.contract_lessons[0].lesson_name == 'mat'"><b>Математик</b> </span>
            <span v-if="student.student_contract.contract_lessons[0].lesson_name == 'eng'"><b>Англи хэл </b></span></template>,
            <template v-if="student.student_contract.contract_lessons.length > 1"><span
              v-if="student.student_contract.contract_lessons[1].lesson_name == 'mat'"><b>Математик</b> </span>
            <span v-if="student.student_contract.contract_lessons[1].lesson_name == 'eng'"><b>Англи хэл </b></span></template>
            сургалтад долоо
          хоногт 5
          удаа хичээлийн хоёр /дөрөв/ цагаар суралцана.
          <!-- суралцах ба нийт суралцах хугацаа нь
          <strong>{{ student.student_contract.contract_lessons[0].student_time }}
            <template v-if="student.student_contract.contract_lessons.length > 1">,{{ student.student_contract.contract_lessons[1].student_time }}</template>
          </strong> цаг байна. -->
        <div>
          2.3 &nbsp; Хичээллэх өдөр: Даваа, Мягмар, Лхагва, Пүрэв, Баасан.
          <span
              v-if="student.student_contract.contract_lessons[0].lesson_name == 'mat'"><b>{{ eelj }}</b> </span>
        </div>
        <div>
          2.4 &nbsp; Суралцагчийн хичээлийн ирц 90%-аас доошгүй, хичээлийн явцын шалгалт 80%-аас дээш байх тохиолдолд
          гэрчилгээ олгогдоно. /Англи хэл/
        </div>
        <!-- <div>
          2.5 &nbsp; Гэрээний хугацаанд
           <span><span v-if="student.student_contract.contract_lessons[0].lesson_name == 'mat'"><b>Математик</b>
            </span>
            <span v-if="student.student_contract.contract_lessons[0].lesson_name == 'eng'"><b>Англи хэл </b></span></span>
            <span v-if="student.student_contract.contract_lessons.length == 2">,
              <span v-if="student.student_contract.contract_lessons[1].lesson_name == 'mat'"><b>Математик</b>
            </span>
            <span v-if="student.student_contract.contract_lessons[1].lesson_name == 'eng'"><b>Англи хэл </b></span>
          </span> хичээлээр үндсэн
          түвшинд
          нь хүргэх.
        </div> -->
        <div>
          2.5 &nbsp; Академийн ажлын цаг нь Даваа-Баасан гарагийн 08:00-19:30 цаг байна. Ажлын бус цагаар холбогдох
          тохиолдолд санал хүсэлтээ info@dma.mn имэйл хаягаар
          илгээнэ.
        </div>
        </div>
      </div>
      <!-- ГУРАВ. СУРГАЛТЫН ТӨЛБӨР -->
      <div style="text-align: center"><strong>
          ГУРАВ. СУРГАЛТЫН ТӨЛБӨР</strong>
      </div>
      <div class="txtleft">
        <div>
          3.1 &nbsp; Сургалтын төлбөр нь <strong>{{ calculatePayment() }}</strong> төгрөг ба төлбөрийг <b>Ариг банк</b>
          тоот /210 800 8989/ А.Болортуяа дансанд шилжүүлснээр
          гэрээг хүчин төгөлдөрт тооцно.
        </div>
        <div v-if="student.student_contract.payment_type == 'Зээлээр'">
            Төлбөрийн төрөл : {{ student.student_contract.payment_type }}, урьдчилгаа дүн: {{ student.student_contract.pay_amount | formatCurrency}} . Үлдэгдэл төлбөрийг зээлийн гэрээний дагуух хуваарийн дагуу төлнө.
          </div>
        <div>
          3.2 &nbsp; Сургалтын төлбөрийг төлснөөр сургалтад хамрагдах эрх үүснэ. Сургалтын төлбөрөө бүрэн төлсөн ч анги
          дүүрсэн тохиолдолд дараагийн ангид шилжүүлэн сургана.
        </div>
        <div>
          3.3 &nbsp; Сургалтын төлбөрийг сургалт эхлэхээс өмнө төлнө. Сургалтын төлбөрийг төлөөгүйгээс сургалтын
          хөтөлбөрөөс хоцорсон тохиолдолд Дэлхийн мэдлэг академи аливаа
          хариуцлага хүлээхгүй.
        </div>
        <div>
          3.4 &nbsp; Сургалтын төлбөрийг гэрээ байгуулсан өдрөө бүрэн төлж барагдуулна.
        </div>
        <div>
          3.5 &nbsp; Дараах тохиолдолд сургалтын төлбөрийг буцаан олгохгүй. Үүнд:
        </div>
        <div class="m-l-18">
          3.5.1 Суралцагч нь урьдчилан мэдэгдэлгүй, хүндэтгэн үзэх шалтгаангүй, асран хамгаалагчийн зөвшөөрөлгүйгээр
          хичээл тасалсан тохиолдолд “анги дүүргэлтэд бүртгэгдэн,
          сургалтын хөтөлбөрт хамрагдах өөр суралцагчийн орон тоог эзэлсэн” гэж үзэн сургалтын төлбөрийг буцаан
          олгохгүй.
        </div>
        <div class="m-l-18">
          3.5.2 Суралцагч нь сургалтын хөтөлбөрийг эхлүүлсэн боловч сайн дураар суралцахаас татгалзаж, сургалтын үйл
          ажиллагаанд идэвхи чармайлт гаргаж оролцоогүй бол
          сургалтын төлбөрийг буцаан олгохгүй.
        </div>
        <div class="m-l-18">
          3.5.3 Суралцагч болон асран хамгаалагч нь энэхүү сургалтын гэрээг байгуулахдаа сургалтын хөтөлбөр,
          байгууллагын
          дүрэмтэй танилцаж, дагаж мөрдөх үүрэгтэй бөгөөд
          сургалт эхлэсний дараа сургалтын хөтөлбөр болон байгууллагын дүрэмд гомдол гаргасан тохиолдолд сургалтын
          төлбөрийг буцаан олгохгүй.
        </div>
        <div class="m-l-18">
          3.5.4 Суралцагч нь сургалт эхлэхээс ажлын 5 хоногийн өмнө төлбөрөө буцаахыг шаардаагүй бол сургалтын төлбөрийг
          буцаан олгохгүй.
        </div>
        <div class="m-l-18">
          3.5.5 Суралцагч нь сургалт эхлэхээс ажлын 5 хоногийн өмнө төлбөрөө буцаахыг шаардсан тохиолдолд Дэлхийн мэдлэг
          академи нь сургалт эхлэхэд тухай суралцагчийн анги
          дүүргэлт, багшийн ажлын төлөвлөлт, байгууллагын олох байсан орлого зэргийг тооцож нийт төлбөрийн 10 хувийг
          буцаан олгохгүй.
        </div>
        <div class="m-l-18">
          3.5.6 Улс орныг хамарсан онц байдал зарласан, байгалийн гоц үзэгдэл болсон тохиолдолд сургалтын үйл ажиллагааг
          хэвийн эсвэл боломжит нөхцөл байдалд үргэлжлүүлэх
          боломжтой бол сургалтын төлбөрийг буцаан олгохгүй.
        </div>
        <div class="m-l-18">
          3.5.7 Суралцагч нь хүндэтгэн үзэх шалтгааны улмаас цаашид сургалтыг үргэлжлүүлэх боломжгүй болсон нь баримтаар
          нотлогдсон тохиолдолд уг нөхцөл байдал арилсны
          дараа хичээлийг үргэлжлүүлэн суух боломжтой гэж үзэн төлбөрийг буцаан олгохгүй.
        </div>
        <div class="m-l-18">
          3.5.8 Суралцагч, түүний асран хамгаалагч нь хууль болон байгууллагын дотоод дүрэм журам зэргийг зөрчиж, цаашид
          энэхүү гэрээг үргэлжлүүлэх боломжгүй болсон
          тохиолдолд төлбөрийг буцаан олгохгүй.
        </div>
        <div>
          3.6 &nbsp; Дараах тохиолдолд сургалтын төлбөрийг буцаан авах эрхтэй. Үүнд:
        </div>
        <div class="m-l-18">
          3.6.1 Суралцагч нь сургалт эхлэхээс ажлын 5 хоногоос багагүй хугацааны өмнө сургалтын төлбөрөө буцаахыг
          шаардах
          эрхтэй.
        </div>
        <div class="m-l-18">
          3.6.2 Дэлхийн мэдлэг академи нь сургалт эхлүүлэхээр тогтсон өдрөө 2 сараас илүү хугацаанд хойшлуулах эсвэл
          тухай
          сургалт цаашид эхлэх боломжгүй болсон
          тохиолдолд сургалтын төлбөрөө буцаан авах эрхтэй.
        </div>
        <div class="m-l-18">
          3.6.3 Суралцагч нь Дэлхийн мэдлэг академийн зарласан тэтгэлэг, тусгай хөтөлбөрт амжилттай хамрагдаж, болзол
          шалгуурыг хангаснаар тухайн хөтөлбөрт заасан
          төлбөрийн хувь хэмжээгээр буцаан авах эрхтэй.
        </div>
      </div>
      <!-- ДӨРӨВ. ДЭЛХИЙН МЭДЛЭГ АКАДЕМИЙН ЭРХ, ҮҮРЭГ -->
      <div style="text-align: center"><strong>
          ДӨРӨВ. ДЭЛХИЙН МЭДЛЭГ АКАДЕМИЙН ЭРХ, ҮҮРЭГ</strong>
      </div>
      <div class="txtleft">
        <div style="font-size:7pt; color:black; font-family:Times New Roman; text-align:left;" class="m-b-5">
          <i><u><h3>ДМА тал дараах эрхийг эдэлнэ</h3></u></i>
        </div>
        <div>
          4.1 Сургалтанд хамрагдаж буй суралцагчаас байгууллагын дотоод журмыг дагаж мөрдөх, гэрээний үүргээ
          биелүүлэхийг
          шаардах эрхтэй.
        </div>
        <div>
          4.2 Суралцагчийг бие дааж, идэвхи чармайлттай суралцах, багш, ажилчидтай зөв, боловсон харьцахыг шаардах
          эрхтэй.
        </div>
        <div>
          4.3 Суралцагчаас энэхүү гэрээнд заасан үүрэг болон хуулийн хэм хэмжээнд заасан эрх үүргийг шаардах эрхтэй.
        </div>
        <div>
          4.4 Суралцагчийн асран хамгаалагчаас энэхүү гэрээний даагуу биелүүлэх ёстой үүргийг болон суралцагчийн бусад
          үүргийг биелүүлэхийг шаардах эрхтэй.
        </div>
        <div>
          4.5 Суралцагч нь энэхүү гэрээний 2.4 заасан хугацаанд 10 цагаас дээш хугацаагаар хичээл тасалсан, эсвэл 5 удаа
          гэрийн даалгавар хийгээгүй, мөн тус гэрээний
          4.1, 4.2 дахь заалтыг удаа дараа биелүүлээгүй бол Дэлхийн мэдлэг академи нь тус гэрээний 4.11 дахь заалтын
          үүргийг хүлээхгүй болно.
        </div>
        <div>
          4.6 Суралцагч, түүний асран хамгаалагч нь гэрээний үүргээ биелүүлээгүйгээс үүдэн хоцорсон хичээлийн цагийг
          нөхөхгүй байх эрхтэй.
        </div>
        <div>
          4.7 Суралцагч, түүний асран хамгаалагч нь Дэлхийн мэдлэг академи, түүний багш, ажилтнууд, бусад суралцагчийн
          хууль ёсны эрх ашиг, сонирхолд илтэд хохирол
          учруулсан, байгууллагын нэр хүндэд халдсан бол зөрчигдсөн эрхийг сэргээлгэх, нөхөн сэргээх боломжгүй бол мөнгө
          төлбөрөөр хохиролыг барагдуулахыг шаардах эрхтэй.
        </div>
        <div>
          4.8 Байгууллага, багш, ажилтан нь суралцагч, түүний асран хамгаалагчийн хууль, дүрэм, журам зөрчиж, бусдын эрх
          ашгийг хөндсөн үйлдэл хийхээ зогсоохыг шаардах
          эрхтэй ба шаардлагатай тохиолдолд гарч болох эрсдлээс сэргийлэн өөрийгөө хамгаалах, төрийн байгууллагад
          мэдэгдэх
          эрхтэй.
        </div>
        <div>
          4.9 Дэлхийн мэдлэг академи нь зөрчигдсөн эрхээ сэргээлгэх, учирсан хохирлоо барагдуулахаар суралцагч, түүний
          асран хамаагалчтай тохиролцож эвлэрэх ба
          шаардлагатай тохиолдолд шүүхээр нэхэмжлэл гаргах эрхтэй.
        </div>
        <div>
          4.10 Дэлхийн мэдлэг академи нь суралцагч гэрээ байгуулсан хугацаандаа хичээлдээ бүрэн суусан эсэхээс үл
          хамаарч
          сургалтын төлбөрийг бүрэн авах эрхтэй.
        </div>
        <div style="font-size:7pt; color:black; font-family:Times New Roman; text-align:left;" class="m-b-5">
          <i><u><h3>ДМА тал дараах үүргийг хүлээнэ</h3></u></i>
        </div>
        <div>
          4.11 Сургалтын төлөвлөгөө, хичээлийн хөтөлбөрийн дагуу суралцагчийг чанартай сургах, суралцагчийн
          зорьсон түвшинд хүргэх, хоцрогдолыг бүрэн арилгах, хичээлийг системтэй ойлгуулах
        </div>
        <div>
          4.12 Академийн нэрэмжит үр дүнгийн шалгалтыг хөтөлбөрийн дагуу авч байх
        </div>
        <div>
          4.13 Суралцах хугацаанд хичээлийн байр, ном сурах бичиг, гарын авлагаар үнэгүй хангах
        </div>
        <div>
          4.14 Сургалт явуулахад зайлшгүй шаардлагатай тоног төхөөрөмж, багаж хэрэгсэл, ширээ сандал, сургалтын
          материалыг гэрээний хугацаанд, хичээлийн үеэр үнэ төлбөргүй ашиглуулах
        </div>
        <div>
          4.15 Дэлхийн мэдлэг академийн сургалтын дотоод орчин, нөхцөл нь эрүүл ахуйн шаардлага,
          сурах нөхцөл бололцоогоор бүрэн хангасан байна.
        </div>
        <div>
          4.16 Суралцагчийн асран хамгаалагчийн хүсэлтээр сургалтын явцын мэдээллээр бүрэн хангах. Ингэхдээ
          хичээлийн бус цагаар мэдээ мэдээлэл өгнө.
        </div>
        <div>
          4.17 Хүндэтгэн үзэх шалтгаанаар /гэнэтийн буюу давагдашгүй хүчний шинжтэй, онцгой нөхцөл байдал үүссэн,
          хичээлдээ биеэр эсвэл цахим сургалтад оролцох боломжгүй/ суралцагч хичээлд оролцож чадахгүйгээ нотолсон
          тохиолдолд хичээлийг нөхөж орох боломжтой. Энэ тохиолдолд төлбөр буцаагдахгүй бөгөөд эхний удаад нэмэлт төлбөр авахгүй.
        </div>
        <div>
          4.18 Багш нь сургалтын 1 цаг 30 минутын хугацаанд бүх мэдлэг, чадвараа дайчлан хичээлийг  заана
        </div>
        <div>
          4.19 Дэлхий мэдлэг академи нь суралцагчийн ирж буцах үеийн аюулгүй байдлыг хангах үүрэг хүлээхгүй
        </div>
      </div>
      <div class="m-t-10 left">
        <b><h3>Дэлхийн мэдлэг академийн нэр, гарын үсэг: ...........................................
          /&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/</h3></b>
      </div>
      <!-- ТАВ. СУРАЛЦАГЧИЙН ЭРХ, ҮҮРЭГ -->
      <div style="text-align: center"><strong>
          ТАВ. СУРАЛЦАГЧИЙН ЭРХ, ҮҮРЭГ</strong>
      </div>
      <div class="txtleft">
        <div class="m-b-5">
          <i><u><h3>Суралцагч дараах эрхийг эдэлнэ</h3></u></i>
        </div>
        <div>
          5.1 Дэлхийн Мэдлэг Академийн хичээлийн танхим, ширээ сандал, гарын авлагуудыг багшийн зөвшөөрлийн дагуу үнэгүй
          ашиглах
        </div>
        <div>
          5.2 Сургалтын явцад ойлгогдоогүй орхигдсон, мартагдсан хичээлүүдийг дахин суралцах хүсэлт тавих
        </div>
        <div>
          5.3 Дэлхийн Мэдлэг Академийг гэрээнд заасан үүргээ биелүүлэхийг шаардах, шаардлагатай тохиолдолд суралцах
          эрхээ
          хэрэгжүүлэхэд саад учирч буй нөхцөл байдлыг арилгуулахыг шаардах эрхтэй
        </div>
        <div>
          5.4 Суралцагч хичээлийн бус цагаар академийн танхимд гэрийн даалгавар хийх, шаардлагатай тоног төхөөрөмжийг
          ашиглах
        </div>
        <div>
          5.5 Академийн зүгээс зохион байгуулах ОУ-ын сурагч солилцооны болон сайн дурын төсөл, хөтөлбөр, гадаад аялал,
          математикийн ОУ-ын хэмжээний олимпиад зэрэг хөтөлбөрт хамрагдах
        </div>
        <div>
          5.6 Ойлгохгүй байгаа хичээлийг багшаас дахин тодруулж асуух, тайлбарлуулах.
        </div>
        <div>
          5.7 “Алтан гэрэгэ” хөтөлбөрт хамрагдах хүсэлтээ гаргах
        </div>
        <div>
          5.8 Дэлхийн мэдлэг академиас тодорхойлолт гаргуулах
        </div>
        <div>
          5.9 Суралцагч сургалттай холбоотой өөрийн санал хүсэлтийг чөлөөтэй илэрхийлэх
        </div>
        <div>
          5.10 Суралцагч нь асран хамгаалагчийн зөвшөөрлийг үндэслэн өөрийн хүсэлтээр Дэлхийн мэдлэг академийн олон
          нийтийн идэвхижүүлэх ажилд
          оролцох, видео болон тараах хэвлэмэл мэдээлэлд зургаа нийтлүүлэх, цахим суртачилгаанд ярилцлага өгөх, бичлэгт
          дүрсээ буулгах
        </div>
        <div style="font-size:7pt; color:black; font-family:Times New Roman; text-align:left;" class="m-b-5 m-t-5">
          <i><u><h3>Суралцагч дараах үүргийг хүлээнэ</h3></u></i>
        </div>
        <div>
          5.11 Суралцагч нь өөрийгөө бүрэн дайчилж, сургалтын хугацаанд өгсөн гэрийн даалгаврыг тогтмол хийж, багшийн
          өгсөн үүргийг хариуцлагатайгаар биелүүлэx
        </div>
        <div>
          5.12 Гэрээнд заасан хугацаанд хичээл таслахгүй, өөрийгөө тасралтгүй хөгжүүлж, бие дааж суралцах
        </div>
        <div>
          5.13 Хичээлийн танхимд байгаа ширээ, сандал, компьютер, хөгжим, өсгөгч гэх мэт тоног төхөөрөмжүүдэд хайр
          гамтай
          хандаж, хичээлийн
          өрөө тасалгааг цэвэр цэмцгэр ашиглах
        </div>
        <div>
          5.14 Дэлхийн Мэдлэг Академи, түүний багш, ажилтнууд, хамтран суралцагч нартай хүндэтгэлтэй харилцах, ёс зүйн
          хэм
          хэмжээг баримтлах,
          хууль болон байгууллагын дүрэм, журмыг дагаж мөрдөх
        </div>
        <div>
          5.15 Этгээд бүдүүлэг үг хэрэглэхгүй, бусдыг олон талт байдлаар нь ялгаварлан гадуурхахгүй, нэр хүнд, эрүүл
          мэнд,
          эд хөрөнгөд нь халдахгүй байх.
          Хэрэв суралцагч нь байгууллагын дүрэм, журмыг зөрчвөл түүнд тохирсон хариуцлагыг байгууллагын ажилтан
          хүлээлгэхийг зөвшөөрөх
        </div>
        <div>
          5.16 Өөрийгөө болон бусдыг хүндэтгэж, хичээлээ таслахгүй, хоцрохгүй, орхин гарахгүй байх
        </div>
        <div>
          5.17 Суралцагч нь суралцах хугацаандаа байгууллагын дотоод дүрэм журмыг мөрдөж, багш, ажилтан, бусад суралцагч нартай зүй ёсоор харьцаж, сургалтын хөтөлбөрийг өөрийн нөөц бололцоогоо ашиглаж амжилттай суралцах,
        </div>
        <div>
          5.18 Суралцагч нь Дэлхийн мэдлэг академид суралцах хугацаандаа байгууллагын дотоод дүрэм журмыг мөрдөж, багш,
          ажилтан, бусад суралцагч нартай зүй
          ёсоор харьцаж, сургалтын хөтөлбөрийг өөрийн нөөц бололцоогоо ашиглаж амжилттай суралцах
        </div>
        <div>
          5.19 Суралцагч нь Дэлхийн мэдлэг академид суралцах хугацаандаа олж авсан байгууллага, сургалтын хөтөлбөр,
          багш,
          ажилтнуудын хувийн болон нууцтай
          холбоотой мэдээллийг бусдад задруулахгүй байх
        </div>
        <div>
          5.20 Суралцагч нь дэлхийн мэдлэг академид суралцах хугацаанд ирж буцах үедээ эцэг эхийн хараа хяналттай явах
        </div>
        <div>
          5.21 Дэлхийн мэдлэг академид суралцах хугацаандаа байгуулага, багш, ажилчдын мэдээллийг нийгмийн сүлжээ
          ашиглаж
          нийтэд задруулахгүй, нэр хүндэд
          халдахгүй байх үүрэгтэй бөгөөд уг үүргээ зөрчсөн тохиолдолд байгууллагын дүрэмд заасан хохирлыг мөнгөн
          төлбөрөөр
          төлж барагдуулахыг хүлээн зөвшөөрөх
        </div>
      </div>
      <div class="m-t-10 left">
        <b><h3>Суралцагчийн нэр, гарын үсэг: ...........................................
          /&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/</h3></b>
      </div>
      <!-- ЗУРГАА. АСРАН ХАМГААЛАГЧИЙН ЭРХ, ҮҮРЭГ -->
      <div style="text-align: center"><strong>
          ЗУРГАА. АСРАН ХАМГААЛАГЧИЙН ЭРХ, ҮҮРЭГ</strong>
      </div>
      <div class="txtleft">
        <div style="font-size:7pt; color:black; font-family:Times New Roman; text-align:left;" class="m-b-5">
          <i><u><h3>Асран хамгаалагч дараах эрхийг эдэлнэ</h3></u></i>
        </div>
        <div>
          6.1 Сургалтын чанар болон багшийн заах арга зүйн талаар санал, хүсэлтээ info@dma.mn хаягт, эсвэл харилцааны албанд албан ёсоор бичгээр гаргах, хариу албан бичгийг ажлын 7 өдөрт санал хүсэлтэд дурьдсан хаягаар авах
        </div>
        <div>
          6.2 Эцэг, эх, асран хамгаалагч нь шаардлагатай тохиолдолд суралцагчийн хичээлийн явц сургалтын үр дүнг цахимаар авах
        </div>
        <div>
          6.3 Шаардлагатай тохиолдолд харилцааны албанд ямар асуудлаар уулзах талаар мэдэгдэж байгууллагын удирдлагатай уулзах хүсэлт гарган уулзалтын цагийг урьдчилан товлох, товлосон цагтаа ирэх, ирэхдээ шийдвэрлүүлэх асуудлын талаар зохих баримт, материалаа авч ирэх
        </div>
        <div>
          6.4 Хүүхдийн сургалтын явц, ирц, үнэлгээ, тайлан, мэдээлэл, тодорхойлолт авах
        </div>
        <div>
          6.5 Хүүхдийнхээ суралцаж буй тав тухтай орчин, аюулгүй байдлыг бүрдүүлэхийг шаардах
        </div>
        <div style="font-size:7pt; color:black; font-family:Times New Roman; text-align:left;" class="m-b-5 m-t-5">
          <i><u><h3>Асран хамгаалагч дараах үүргийг хүлээнэ</h3></u></i>
        </div>
        <div>
          6.6 Энэхүү гэрээ болон байгууллагын дүрэм, хичээлийн хөтөлбөртэй бүрэн танилцах, гэрээний үүргийг дагаж мөрдөх
        </div>
        <div>
          6.7 Суралцагчийн ирэх болон явах замд, хичээлийн бус цагаар үүсэж болзошгүй аюулгүй байдлыг эцэг, эх, асран
          хамгаалагч нь бүрэн хариуцах
        </div>
        <div>
          6.8 Суралцагчид гэрийн даалгавар хийх цаг гаргаж өгөх, хичээл хийх орчин нөхцлөөр хангах, хичээлд таслуулахгүй
          оролцуулах
        </div>
        <div>
          6.9 Академиас зарласан эцэг, эх, асран хамгаалагчидад зориулсан тайлан, үр дүнгийн уулзалтад биеэр оролцох
        </div>
        <div>
          6.10 Гэрээнд заасан төлбөрийг тохиролцсон цаг хугацаанд бүрэн төлөх
        </div>
        <div>
          6.11 Хичээлээс гадуурх зохион байгуулагдах сайн дурын ажилд <i>/уралдаан, олимпиад, хэлний түвшин тогтоох
            шалгалтын бүртгэлийн төлбөр, сурагч
            солилцооны хөтөлбөрийн зардал гэх мэт/ </i> суралцагчийн оролцоход гарах санхүүгийн бүх зардлыг асран
          хамгаалагч бүрэн хариуцах
        </div>
        <div style="font-size:7pt; color:black; font-family:Times New Roman; text-align:left;" class="m-b-5 m-t-5">
          <i><u><h3>Асран хамгаалагчид хориглох заалт</h3></u></i>
        </div>
        <div>
          6.12 Эцэг, эх, асран хамгаалагч нь сургалтын явцад танхимд зөвшөөрөлгүй нэвтрэх, сургалтад саад учруулах
          үйлдэл
          хийх
        </div>
        <div>
          6.13 Эцэг, эх, асран хамгаалагч нь Дэлхийн мэдлэг академийн багш, ажилчидтай хувийн харилцаа тогтоох,
          тогтоохыг
          оролдох,
          төлбөр мөнгө төлөх, төлбөртэй сургалт авах зэргийг хориглоно
        </div>
        <div>
          6.14 Суралцагчийн бие даах чадварт сөргөөр нөлөөлөх, багшийн өгсөн үүрэг даалгаварыг цалгардуулах, хойшлуулах,
          хичээл таслахад нөлөөлөх
        </div>
        <div>
          6.15 Суралцагчдад өөрийн зүй бус хүсэл, сонирхлыг тулган шаардах, бусдын өмнө нэр хүндэд нь халдах, суралцах,
          ойлгох хурдыг шүүмжлэх, бусадтай харьцуулах
        </div>
        <div>
          6.16 Академийн багш, ажилтнууд руу зүй зохисгүй дайрах, чангаар орилох, биеэ зохисгүй авч явах, хүний биед
          халдах, материалаг болон сэтгэл зүйн
          гэм хор, хохирол учруулах
        </div>
        <div>
          6.17 Асран хамгаалагч буюу эцэг, эхийн хувийн зохион байгуулалтгүй байдлаас шалтгаалан суралцагчийн хичээлийн
          хуваарийг дур мэдэн өөрчлөх, хуваарьт бус
          цагт академийн байранд хүүхдийг бие даан үлдээх, хичээл нөхөн орохыг шаардах, зүй бус шаардлага тавих
        </div>
        <div>
          6.18 Дэлхийн мэдлэг академид суралцах хугацаандаа байгуулага, багш, ажилчдын мэдээллийг нийгмийн сүлжээ
          ашиглаж
          нийтэд задруулахгүй, нэр хүндэд халдахгүй
          байх үүрэгтэй бөгөөд, уг үүргээ зөрчсөн тохиолдолд байгууллагын дүрэмд заасан хохирлыг мөнгөн төлбөрөөр төлж
          барагдуулахыг хүлээн зөвшөөрөх
        </div>
      </div>
      <div class="m-t-10 left">
        <b><h3>Асран хамгаалагчийн нэр, гарын үсэг: ...........................................
          /&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/</h3></b>
      </div>
      <!-- ДОЛОО. ХАРИУЦЛАГА -->
      <div style="text-align: center"><strong>
          ДОЛОО. ХАРИУЦЛАГА</strong>
      </div>
      <div class="txtleft">
        <div>
          7.1 Суралцагч, эцэг эх, асран хамгаалагч нь энэхүү гэрээнд заасан эрх, үүргээ биелүүлээгүй, хувийн зохион
          байгуулалтгүй байдлаас үүдэж гэрийн даалгаварын
          биелэлт дутуу, цагийн хуваарийн дагуу хичээлд бүрэн хамрагдаагүй, удаа дараа тасалсан эсвэл хоцорсон зэрэг
          шалтгаанаар эзэмшвэл зохих мэдлэгийг бүрэн
          эзэмшээгүйгээс үүдсэн хариуцлагыг Дэлхийн Мэдлэг Академи хүлээхгүй ба төлбөрийг буцаан олгохгүй
        </div>
        <div>
          7.2 Гэнэтийн болон давагдашгүй хүчин зүйлийн /давагдашгүй хүчин зүйл гэдэгт үер, гал түймэр, газар хөдлөлт,
          халдварт өвчний тархалт, зэвсэгт мөргөлдөөн,
          террорист үйлдэл, иргэдийн үймээн, ажил хаялт гэх мэт хянах боломжгүй үйлдлүүдийг ойлгоно/ улмаас гэрээний
          хэрэгжилтэнд аливаа саад учирсан тохиолдолд талууд
          харилцан үүрэг хүлээхгүй
        </div>
        <div>
          7.3 Гэнэтийн хөл хорио тавигдаж танхимын хичээл орох боломжгүй болсон тохиолдолд сургалтыг онлайн горимд
          шилжүүлнэ
        </div>
        <div>
          7.4 Суралцагч сургалтын явцад аливаа алдаа дутагдал гаргасан тохиолдолд доорх алхамуудаас хэрэгжүүлнэ. Үүнд:
        </div>
        <div class="m-l-15 m-t-5 m-b-5">
          <b>•</b> Тасалсан тохиолдолд асран хамгаалагчын бүртгэлтэй утсанд мессежээр сануулах<br>
          <b>•</b> Тасласан тохиолдолд  сурагч болон асран хамгаалагчид мэдэгдэж, үүргээ биелүүлэхийг шаардах
        </div>
        <div>
          7.5 Суралцагч, асран хамгаалагч нь төлбөрийн үүргээ бүрэн гүйцэтгээгүй тохиолдолд Дэлхийн мэдлэг академи нь
          гүйцэтгээгүй үүргийн үнийн дүнгийн 0.3
          хувиар алданга авах эрхтэй
        </div>
        <div>
          7.6 Дэлхийн мэдлэг академи нь суралцагч болон түүний асран хамгаалагчийн хууль бус үйлдлийн улмаас учирсан
          хохирлыг арилгуулахаар шүүхэд нэхэмжлэл гаргах эрхтэй
        </div>
      </div>
      <!-- НАЙМ. ГЭРЭЭ ЦУЦЛАХ ҮНДЭСЛЭЛ -->
      <div style="text-align: center"><strong>
          НАЙМ. ГЭРЭЭ ЦУЦЛАХ ҮНДЭСЛЭЛ</strong>
      </div>
      <div class="txtleft">
        <div>
          8.1 Суралцагч болон асран хамгаалагч хууль бус үйлдэл гаргах, суралцах чин хүсэл эрмэлзэл 2 сар болон түүнээс
          дээш хугацаанд гаргахгүй байх тохиолдолд
        </div>
        <div>
          8.2 Гэрээг хоёр тал сунгах хүсэлт гаргаагүй бол
        </div>
        <div>
          8.3 Гэрээг нэг талын санаачлагаар цуцлахгүй
        </div>
        <div>
          8.4 Сурагч, асран хамгаалагч гэрээг цуцлах хүсэлт гаргасан тохиолдолд урьдчилан төлсөн төлбөрийг буцаан
          олгохгүй
        </div>
        <div>
          8.5 Гэрээнд заасан хугацаанд төлбөр төлөгдөөгүй тохиолдолд гэрээг цуцлана
        </div>
      </div>
      <!-- ЕС. БУСАД ЗҮЙЛ -->
      <div style="text-align: center"><strong>
          ЕС. БУСАД ЗҮЙЛ</strong>
      </div>
      <div class="txtleft">
        <div>
          9.1 Шаардлагатай тохиолдолд талуудын санаачлагаар гэрээний нэмэлт өөрчлөлтийг гэрээний хавсралтаар оруулж
          болно
        </div>
        <div>
          9.2 Гэрээг Монгол хэлээр хуулийн адил хүчинтэй 2 хувь үйлдэж талуудад нэг нэг хувь хадгалагдана. Аль нэг тал
          гэрээний хувийг үрэгдүүлсэн тохиолдолд
          нөгөө талд байгаа гэрээний хувийг хүчин төгөлдөрт тооцно. Гэрээнд талууд гарын үсэг зурж баталгаажуулснаар
          хүчин
          төгөлдөрт тооцно
        </div>
        <div>
          9.3 Дэлхийн мэдлэг академи болон багш ажилтнуудын талаар санал, хүсэлт, гомдлыг зөвхөн info@dma.mn имэйл хаягаар хүлээн авна.
        </div>
      </div>
      <el-row type="flex" class="row-bg m-t-40 row-contract" justify="space-around">
        <el-col :span="10" class="column-contract">
          <strong>ГЭРЭЭ БАЙГУУЛСАН</strong><br><br>
          <span>“ДЭЛХИЙНМЭДЛЭГ”ХХК-ийг-төлөөлж:</span><br>
          <span>Менежер албан тушаалтай</span><br>
          <span class="m-l-15">Нэр: Г. Солонго </span><br>
          <span class="m-l-15">Мэйл хаяг: info@dma.mn</span><br><br>
          <span class="m-l-15">Гарын үсэг: _________________________</span><br>
          <span class="m-l-15">Хаяг: Монгол улс, Улаанбаатар хот, Баянгол дүүрэг,</span><br><br>
          <span class="m-l-15">2-р хороо, 2-р хороолол Энхтайваны өргөн чөлөө 38/3</span><br>
          <span class="m-l-15">Жэм палас 13 давхар</span><br>
          <span class="m-l-15">Утас: 7777-7757, 7777-7756</span>
        </el-col>
        <el-col :span="10" class="column-contract">
          <span class="left">Хэн болох: <span
              v-if="student.parent_detail !== null">{{ student.parent_detail[0].is_who_name }}</span></span><br>
          <span>Нэр:<span
              v-if="student.parent_detail !== null">{{ student.parent_detail[0].parent_student.last_name }}</span></span><br>
          <span>Мэйл хаяг: </span>
          <span v-if="student.parent_detail !== null">{{ student.parent_detail[0].parent_student.email }}</span><br><br>
          <span>Гарын үсэг: _________________________</span><br><br>
          <span>Утас1 : </span>
          <span v-if="student.parent_detail !== null">{{ student.parent_detail[0].parent_student.phone }}</span><br>
          <span>Утас2 : </span>{{ student.phone }}<br>
        </el-col>
      </el-row>
    </div>
  </div>
    <!-- <el-button type="info" icon="el-icon-printer" circle @click="print()"></el-button>
    <el-tooltip class="item" effect="dark" content="Гэрээ шинээр хийх" placement="top-start">
      <el-button type="success" icon="el-icon-plus" circle @click="createContract()"></el-button>
    </el-tooltip> -->
  </el-dialog>
</template>
<script>
export default {

  props: {
    student: Object
  },
  data () {
    return {
      dialogVisible: false,
      year: null,
      month: null,
      day: null
    }
  },
  created () {
    this.calculateDate()
  },
  mounted () {
  },
  methods: {
    // createContract () {
    //   this.$router.push({
    //     params: {
    //       parent_id: this.student.parent[0].parent_student.id,
    //       student_id: this.student.id,
    //       is_who_name: this.student.parent[0].is_who_name,
    //       type: 'new'
    //     },
    //     name: 'studentStep5'
    //   })
    // },
    calculateDate () {
      const currentTime = new Date()

      // returns the month (from 0 to 11)
      this.month = currentTime.getMonth() + 1

      // returns the day of the month (from 1 to 31)
      this.day = currentTime.getDate()

      // returns the year (four digits)
      this.year = currentTime.getFullYear()
    },
    calculatePayment () {
      let totalPayment = 0
      this.student.student_contract.contract_lessons.forEach(element => {
        totalPayment = totalPayment + element.payment
      })
      return totalPayment
    },
    handleClose (done) {
      this.$root.getStudentContractPageDialog = false
    },
    print () {
      this.$htmlToPaper('printMe')
    }
  }
}

</script>
