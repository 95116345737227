<template>
  <div :class="'page-wrapper chiller-theme ' + containerCollapsed">
    <!-- <SideBar /> -->
    <el-container>
      <el-header class="my-header" style="font-size: 12px">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :xs="12" :sm="6" :md="4" :lg="4" :xl="4" :offset="1">
            <el-button class="menu_button" @click="showMenu()" type="text"><i class="el-icon-menu"></i></el-button>
          </el-col>
          <el-col :xs="6" :sm="6" :md="6" :lg="3" :xl="3">
            <span class="m-r-10" prob="first_name">{{ user.name }}</span>
            <el-dropdown>
              <i class="el-icon-caret-bottom" style="margin-right:25px"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button @click="logout()">Гарах</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </el-header>
      <main class="page-content">
        <router-view />
      </main>
    </el-container>
    <loader-item :isLoader='this.$root.fullscreenLoading'></loader-item>
    <SideBarComponent v-if="this.$root.showMenuDrawerStatus" />
  </div>
</template>
<script>
// import { SidebarMenu } from 'vue-sidebar-menu'
import SideBarComponent from '@/components/sidebar/sidebar-view'
import LoaderItem from '@/components/loader/loader-item.vue'
// import services from '@/helpers/services.js'

// accessTokenaccessToken

export default {
  components: {
    SideBarComponent,
    LoaderItem
  },
  data() {
    return {
      containerCollapsed: 'toggled',
      tabledata: [],
      user: JSON.parse(localStorage.getItem('user'))

    }
  },
  created() {
  },
  methods: {
    showMenu() {
      this.$root.showMenuDrawerStatus = true;
    },
    logout() {
      localStorage.clear()
      this.$router.push({
        path: '/login'
      })
    }
  }
}

</script>
<style scoped>
.el-header {
  background-color: #ffffff;
  color: #333;
  line-height: 60px;
  padding: 0px;
  min-height: 68px;
}

.el-aside {
  color: #333;
}

.item {
  margin-top: 12px;
  margin-right: 20px;
}
</style>
