export const notificationText = [
  {
    key: 'success_empty',
    text_mon: 'Амжилттай.',
    text_eng: 'Success.',
    title_mon: 'Амжилттай',
    title_eng: 'Success',
    type: 'success'
  },
  {
    key: 'error_empty',
    text_mon: 'Алдаа гарлаа.',
    text_eng: 'Error.',
    title_mon: 'Алдаа гардаа',
    title_eng: 'Error',
    type: 'error'
  },
  {
    key: 'order_success',
    text_mon: 'Таны захиалга амжилттай боллоо.',
    text_eng: 'Order complete.',
    title_mon: 'Захиалга',
    title_eng: 'Order',
    type: 'success',
    position: 'top-left'
  },
  {
    key: 'order_failed',
    text_mon: 'Таны захиалга амжилтгүй боллоо.',
    text_eng: 'Order failed.',
    type: 'error',
    title_mon: 'Захиалга',
    title_eng: 'Order'
  },
  {
    key: 'success_change_status',
    text_mon: 'Төлөв амжилттай өөрчлөгдлөө.',
    text_eng: 'Success change status.',
    type: 'success',
    title_mon: 'Төлөв',
    title_eng: 'Status'
  },
  {
    key: 'success_change_error',
    text_mon: 'Төлөв өөрчлөхөд алдаа гарлаа.',
    text_eng: 'Error change status.',
    type: 'error',
    title_mon: 'Төлөв',
    title_eng: 'Status'
  },
  {
    key: 'start_date_required',
    text_mon: 'Эхлэх огноо оруулна уу.',
    text_eng: 'Be sure to enter a start date.',
    type: 'error',
    title_mon: 'Огноо',
    title_eng: 'Date'
  },
  // {
  //   key: 'end_date_is_before_start_date',
  //   text_mon: 'Дуусах огноо эхлэх огнооноос хойш байж болохгүй.',
  //   text_eng: 'The expiration date cannot be later than the start date',
  //   type: 'error',
  //   title_mon: 'Огноо',
  //   title_eng: 'Date'
  // },
  {
    key: 'auth_error',
    text_mon: 'Уучлаарай таны эрх хүрэлцэхгүй байна.',
    text_eng: 'Sorry',
    type: 'error',
    title_mon: 'Алдаа',
    title_eng: 'Error'
  },
  {
    key: 'save_product_notification',
    text_mon: 'Бүтээгдэхүүн  хадгалагдлаа.',
    text_eng: 'Save product success',
    type: 'success',
    title_mon: 'Амжилттай',
    title_eng: 'Success'
  },
  {
    key: 'add_user_nofitcation',
    text_mon: 'Амжилттай  хадгалагдлаа.',
    text_eng: 'Success',
    type: 'success',
    title_mon: 'Амжилттай',
    title_eng: 'Success'
  },
  {
    key: 'add_user_student',
    text_mon: 'Сурагч хадгалагдлаа.',
    text_eng: 'Success',
    type: 'success',
    title_mon: 'Амжилттай',
    title_eng: 'Success'
  },
  {
    key: 'delete_user_notification',
    text_mon: 'Ажилтан амжилттай устгагдлаа.',
    text_eng: 'Success',
    type: 'success',
    title_mon: 'Амжилттай',
    title_eng: 'Success'
  },
  {
    key: 'add_user_error_notiction',
    text_mon: 'Уучлаарай та дахин шалгана уу',
    text_eng: 'Sorry',
    type: 'error',
    title_mon: 'Алдаа',
    title_eng: 'Error'
  },
  {
    key: 'login_user_error',
    text_mon: 'Нэвтрэх нэр нууц үг буруу байна.',
    text_eng: 'Sorry',
    type: 'error',
    title_mon: 'Алдаа',
    title_eng: 'Error'
  },
  {
    key: 'delete_error_user',
    text_mon: 'Хэрэглэгч устгагхад алдаа алдаа.',
    text_eng: 'Sorry',
    type: 'error',
    title_mon: 'Алдаа',
    title_eng: 'Error'
  },
  {
    key: 'success_position',
    text_mon: 'Албан тушаал амжилттай хадгалагдлаа.',
    text_eng: 'Success',
    type: 'success',
    title_mon: 'Амжилттай',
    title_eng: 'Success'
  },
  {
    key: 'success_deleted',
    text_mon: 'Амжилттай устгагдлаа.',
    text_eng: 'Sorry',
    type: 'success',
    title_mon: 'Амжилттай',
    title_eng: 'Success'
  },
  {
    key: 'failed_deleted',
    text_mon: 'Устгахад алдаа гарлаа.',
    text_eng: 'Sorry',
    type: 'error',
    title_mon: 'Алдаа',
    title_eng: 'Success'
  },
  {
    key: 'success_save',
    text_mon: 'Амжилттай хадгалагдлаа.',
    text_eng: 'Success',
    type: 'success',
    title_mon: 'Амжилттай',
    title_eng: 'Success'
  },
  {
    key: 'schedule_unSuccess_save',
    text_mon: 'Хичээллэх ээлж болон хичээллэх өрөө давхардаж байна.',
    text_eng: 'Sorry',
    type: 'error',
    title_mon: 'Алдаа',
    title_eng: 'Success'
  },
  {
    key: 'delete_contract_lesson_success',
    text_mon: 'Таны сонгосон гэрээ устгагдлаа',
    text_eng: 'Success contract',
    type: 'success',
    title_mon: 'Амжилттай',
    title_eng: 'Success'
  },
  
]
