<template>
  <div class="home">
    <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
      <el-col :span="8">
        <h3 class="m-l-25">Блог жагсаалт</h3>
      </el-col>
      <el-col :span="3" class="user_head_add_button m-r-25">
        <el-button size="mini" type="success" @click="addBlogDialog()">+ Блог үүсгэх</el-button>
      </el-col>
    </el-row>
    <el-row class="m-l-30">
      <el-col :span="23" class="home_body p-20">
        <el-table v-loading="loading" :data="blogsList" stripe style="width: 100%">
          <el-table-column type="index" width="50">
          </el-table-column>
          <el-table-column prop="created_at" label="Огноо">
            <template slot-scope="scope">
              {{scope.row.created_at}}
            </template>
          </el-table-column>
          <el-table-column prop="title" label="Дэд гарчиг">
          </el-table-column>
          <el-table-column prop="sub_title" label="Гарчиг">
          </el-table-column>
          <!-- <el-table-column prop="description" label="Тайлбар">
          </el-table-column> -->
          <el-table-column prop="is_active" label="Төлөв">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_active" active-color="#13ce66" inactive-color="gray" :active-value="1"
                :inactive-value="0">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="profile_img_url" label="Зураг">
            <template slot-scope="scope">
              <el-image style="width: 50px; height: 50px; border-radius:10px"
                :src="'https://image.dma.mn/'+ scope.row.img_url" fit="fill">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="user.name" label="Оруулсан ажилтны нэр">
          </el-table-column>
          <el-table-column label="Үйлдэл">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="editBlogs(scope.row)" circle>
              </el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteBlogs(scope.row)" circle>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="Блог үүсгэх" :visible.sync="this.$root.createBlogDialog" width="50%" :before-close="handleClose">
      <el-form :model="createBlogDialog" :rules="rules" ref="ruleForm" label-width="120px" label-position="top"
        class="demo-ruleForm">
        <el-form-item label="Гарчиг" prop="title">
          <el-input v-model="createBlogDialog.title" placeholder="Гарчиг"></el-input>
        </el-form-item>
        <el-form-item label="Дэд гарчиг" prop="sub_title">
          <el-input v-model="createBlogDialog.sub_title" placeholder="Дэд гарчиг"></el-input>
        </el-form-item>
        <el-form-item label="Тайлбар" prop="description">
          <el-input type="textarea" v-model="createBlogDialog.description" placeholder="Тайлбар"></el-input>
        </el-form-item>
        <el-form-item label="Зураг" prop="img_url">
          <el-upload name="file" :headers="{ 'X-CSRF-TOKEN': csrf, 'Authorization':'Bearer '+ token }" class="avatar-uploader" :show-file-list="true"
            action="http://13.213.252.52/api/uploadImage"
            :on-preview="handlePreview" :on-change="success" :accept="acceptType()" :on-remove="handleRemove"
            :before-remove="beforeRemove" :limit="this.fileLimit" :on-exceed="handleExceed" :file-list="fileListAnswer">
            <img v-if="createBlogDialog.img_url"
              :src="'https://image.dma.mn/'+createBlogDialog.img_url" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="createBlog('ruleForm')">Үүсгэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import axios from 'axios'
import {
  notification
} from '@/helpers/custom'
export default {
  data () {
    return {
      token: JSON.parse(localStorage.getItem('idToken')),
      rules: {
        title: [{
          required: true,
          message: 'Гарчиг оруулна уу',
          trigger: 'blur'
        }],
        sub_title: [{
          required: true,
          message: 'Дэд гарчиг оруулна уу',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: 'Тайлбар оруулна уу',
          trigger: 'blur'
        }]
      },
      is_upload_image: true,
      createBlogDialog: {
        title: '',
        sub_title: '',
        description: '',
        is_active: '1',
        img_url: '',
        is_show_web: 0
      },
      blogsList: [],
      imageUrl: '',
      fileListAnswer: [],
      fileList: [],
      isShow: false,
      fileTitle: '',
      csrf: '',
      // csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
      fileTitleAnswer: 'Та хариултын файлыг хуулна уу',
      // centerDialogVisible:false
      fileLimit: 1,
      content_url: '',
      loading: true
    }
  },
  created () {
    this.getBlogsList()
  },
  methods: {
    deleteBlogs (data) {
      this.$confirm(data.title + ' нэртэй албан тушаалыг усгах гэж байна итгэлтэй байна уу.', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const payload = {
          id: data.id
        }
        services.deleteBlogs(payload)
          .then((response) => {
            if (response.status === 'success') {
              this.$root.createBlogDialog = false
              notification('success_deleted')
              this.$root.fullscreenLoading = false
              location.reload()
            }
          })
      }).catch(() => {})
    },
    createBlog (formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          services.createBlogs(this.createBlogDialog)
            .then((response) => {
              if (response.status === 'success') {
                this.$root.createBlogDialog = false
                notification('success_position')
                this.$root.fullscreenLoading = false
                location.reload()
              } else if (response.status === 'unSuccess') {
                this.$root.fullscreenLoading = false
              }
              // console.log(response)
            })
        }
      })
    },
    handleClose () {
      this.$root.createBlogDialog = false
    },
    editBlogs (data) {
      this.createBlogDialog = data
      this.$root.createBlogDialog = true
    },
    addBlogDialog () {
      this.createBlogDialog.title = ''
      this.createBlogDialog.sub_title = ''
      this.createBlogDialog.description = ''
      this.createBlogDialog.img_url = ''
      this.is_active = ''
      this.$root.createBlogDialog = true
    },
    getBlogsList () {
      services.getBlogs()
        .then((response) => {
          this.blogsList = response.data
          this.loading = false
        })
    },
    success (file, fileList) {
      this.createBlogDialog.img_url = file.response
      setTimeout(() => {
        this.isShow = true
      }, 10000)
    },
    acceptType (index) {
      return 'image/png, image/jpeg'
    },
    handleRemove (file, fileList) {
      // eslint-disable-next-line no-prototype-builtins
      if (!file.hasOwnProperty('response')) {
        let response = file.url.split('?')[0]
        response = response.split('.com/')[1]
        file.response = response
      }
      axios.post('https://api.dma.mn/api/removeImage', file).then((data) => {
        this.fileList = fileList
        this.createBlogDialog.img_url = ''
        this.isShow = false
      }).catch((err) => {
      })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`Сонгосон  ${file.name}  файлыг хавсаргасан файлын жагсаалтаас хасах уу?`)
    },
    handlePreview (file) {
    },
    handleExceed (files, fileList) {
      this.$message.warning(
          `Файл тоо хэтэрсэн байна. ${
                    files.length
                } файл хуулсан байна. ${
                    this.fileLimit
                } хэтрэхгүй файл хуулна уу.`
      )
    },
    handleUploadVariantfulImages (file, fileList) {
      this.fileList = fileList
    }
  }
}

</script>

<style>

</style>
