<template>
  <div class="home">
    <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
      <el-col :span="8">
        <h3 class="m-l-25">Хичээлийн жагсаалт</h3>
      </el-col>
      <el-col :span="3" class="user_head_add_button m-r-10">
        <el-button size="mini" type="success" @click="addLessonDialog()">+ Хичээл үүсгэх</el-button>
      </el-col>
    </el-row>
    <el-row class="m-l-30">
      <el-col :span="23" class="home_body p-20">
        <el-table v-loading="loading" :data="lessonList" stripe style="width: 100%">
          <el-table-column type="index" width="50">
          </el-table-column>
          <el-table-column prop="title" label="Хичээлийн нэр">
          </el-table-column>
          <el-table-column prop="profile_img_url" label="Зураг">
            <template slot-scope="scope">
              <el-image style="width: 50px; height: 50px; border-radius:10px"
                :src="'https://image.dma.mn/'+ scope.row.img_url" fit="fill">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="Төлөв" width="100px">
            <template slot-scope="scope">
              <el-switch :active-value="1" :inactive-value="0" active-color="#13ce66" v-model="scope.row.status">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="Төлбөр цагийн">
            <template slot-scope="scope">
              {{scope.row.price | formatCurrency}}
            </template>
          </el-table-column>
          <el-table-column prop="category" label="Хамаарах ангилал">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.category" :style="'background-color:'+ scope.row.category.color_code"> <span
                  style="color:white">{{scope.row.category.title}}</span></el-tag>
              <span style="color:#ccc" v-else>хоосон</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="teacher" label="Заах багш">
            <template slot-scope="scope">
              {{scope.row.teacher.name}}
            </template>
          </el-table-column> -->
          <el-table-column label="Үйлдэл">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" icon="el-icon-edit" circle  @click="editPosition(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="Хичээл үүсгэх" size="medium" :visible.sync="this.$root.categoryCreatDialog" width="30%"
      :before-close="handleClose">
      <el-form :model="selectedLesson" :rules="rules" ref="lessonForm" label-width="120px" label-position="top"
        class="demo-ruleForm">
        <el-form-item label="Хичээлийн нэр" prop="title">
          <el-input v-model="selectedLesson.title" placeholder="Хичээлийн нэр"></el-input>
        </el-form-item>
        <el-form-item label="Хичээлийн тайлбар" prop="description">
          <el-input v-model="selectedLesson.description" placeholder="Хичээлийн тайлбар"></el-input>
        </el-form-item>
        <el-form-item label="Хичээлийн зураг" prop="img_url">
          <el-upload name="file" :headers="{ 'X-CSRF-TOKEN': csrf, 'Authorization':'Bearer '+ token}" class="avatar-uploader" :show-file-list="true"
            action="http://13.213.252.52/api/uploadImage"
            :on-preview="handlePreview" :on-change="success" :accept="acceptType()" :on-remove="handleRemove"
            :before-remove="beforeRemove" :limit="this.fileLimit" :on-exceed="handleExceed" :file-list="fileListAnswer">
            <img v-if="selectedLesson.img_url"
              :src="'https://image.dma.mn/'+selectedLesson.img_url" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="Хичээлийн ангилал" prop="category_id">
          <el-select class="w-100" v-model="selectedLesson.category_id" placeholder="Ангилал сонгох">
            <el-option v-for="(categoryItem,index) in this.categoryList" :key="index" :label="categoryItem.title"
              :value="categoryItem.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Хичээлийн үнэ /цагаар/" prop="price">
          <el-input-number class="w-100" :controls="false" :min="0" v-model="selectedLesson.price"
            placeholder="Нэг цагийн үнэ"></el-input-number>
        </el-form-item>
      </el-form>
      <el-button class="w-100" type="success" @click="createLesson('lessonForm')">Үүсгэх</el-button>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import services from '@/helpers/services.js'
import {
  notification
} from '@/helpers/custom'
export default {
  name: 'LessonComponent',
  data () {
    return {
      token: JSON.parse(localStorage.getItem('idToken')),
      is_password_show: true,
      imageUrl: '',
      fileListAnswer: [],
      fileList: [],
      isShow: false,
      fileTitle: '',
      csrf: '',
      // csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
      fileTitleAnswer: 'Та хариултын файлыг хуулна уу',
      // centerDialogVisible:false
      fileLimit: 1,
      content_url: '',
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      selectedLesson: {
        title: '',
        description: '',
        status: 1,
        img_url: '',
        teacher_id: null,
        price: 0,
        category_id: null
      },
      lessonList: [],
      teacherList: [],
      categoryList: [],
      loading: true,
      rules: {
        title: [{
          required: true,
          message: 'Ангилал нэр заавал оруулна уу',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: 'Тайлбар оруулна уу.Энэхүү тайлбар веб дээр харагдахыг анхаарна уу',
          trigger: 'blur'
        }],
        category_id: [{
          required: true,
          message: 'Хичээлийн ангилал сонгоно уу.',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: 'Хичээл хичээлийн цагийн үнэлэмжийг оруулна уу. Энэхүү үнэлгээ нь нэгж цагийн үнэлгээ гэдгийг анхаарна уу?',
          trigger: 'blur'
        }]
      }
    }
  },
  created () {
    this.getLessonList()
    this.getTeacherList()
    this.getCategoryList()
  },
  methods: {
    getCategoryList () {
      services.getCategory()
        .then((response) => {
          this.categoryList = response.data
          this.loading = false
        })
    },
    getTeacherList () {
      services.getUserList()
        .then((response) => {
          this.teacherList = response.result
          this.loading = false
        })
    },
    deletePosition (data) {
      this.$confirm(data.position_name + ' нэртэй албан тушаалыг усгах гэж байна итгэлтэй байна уу.', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const payload = {
          id: data.id
        }
        services.deletePosition(payload)
          .then((response) => {
            if (response.status === 'success') {
              this.$root.categoryCreatDialog = false
              notification('success_position')
              this.$root.fullscreenLoading = false
              location.reload()
            }
            // console.log(response)
          })
      }).catch(() => {})
    },
    createLesson (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          services.createLesson(this.selectedLesson)
            .then((response) => {
              if (response.status === 'success') {
                this.$root.categoryCreatDialog = false
                notification('success_position')
                this.$root.fullscreenLoading = false
                // location.reload()
              }
              // console.log(response)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose () {
      this.$root.categoryCreatDialog = false
    },
    editPosition (data) {
      this.selectedLesson = data
      this.$root.categoryCreatDialog = true
    },
    addLessonDialog () {
      this.$root.categoryCreatDialog = true
    },
    getLessonList () {
      services.getLesson()
        .then((response) => {
          this.lessonList = response.data
          this.loading = false
        })
    },
    success (file, fileList) {
      console.log(file)
      this.selectedLesson.img_url = file.response
      setTimeout(() => {
        this.isShow = true
      }, 10000)
    },
    acceptType (index) {
      return 'image/png, image/jpeg'
    },
    handleRemove (file, fileList) {
      // eslint-disable-next-line no-prototype-builtins
      if (!file.hasOwnProperty('response')) {
        let response = file.url.split('?')[0]
        response = response.split('.com/')[1]
        file.response = response
      }
      axios.post('https://api.dma.mn/api/removeImage', file).then((data) => {
        this.fileList = fileList
        console.log(data)
        this.selectedLesson.img_url = ''
        this.isShow = false
      }).catch((err) => {
        console.log(err)
      })
      console.log(file, fileList)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`Сонгосон  ${file.name}  файлыг хавсаргасан файлын жагсаалтаас хасах уу?`)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(
          `Файл тоо хэтэрсэн байна. ${
                    files.length
                } файл хуулсан байна. ${
                    this.fileLimit
                } хэтрэхгүй файл хуулна уу.`
      )
    },
    handleUploadVariantfulImages (file, fileList) {
      this.fileList = fileList
    }
  }
}

</script>

<style>

</style>
