<template>
    <div class="user_add">
      <div class="page-header panel">
        <!-- <el-row class="user_head_layout m-t-20">
        <el-col :span="12">
          <h3 class="m-l-25 m-b-25">Сурагч бүртгэх</h3>
        </el-col>
      </el-row> -->
      </div>
      <el-row class="user_body">
          <div class="text-center m-t-40">
            <el-steps :active="activeStep" align-center finish-status="success">
              <el-step title="Сурагчийн мэдээлэл"></el-step>
              <el-step title="Асран хамгаалагчийн мэдээлэл"></el-step>
              {{ this.$route.currentRoute }}
              <el-step v-if="this.$router.currentRoute.path !== '/publicStudentAdd2' && this.$router.currentRoute.path !== '/publicStudentAdd1' && this.$router.currentRoute.path !== '/publicStudentAdd5'" title="Суралцах хичээл"></el-step>
            </el-steps>
          </div>
          <div class="m-t-60 p-b-40">
          <router-view></router-view>
          </div>
        <!-- </el-col> -->
      </el-row>
    </div>
  </template>
<script>
export default {
  created () {
    this.updated()
  },
  data () {
    return {
      activeStep: 1
    }
  },
  methods: {
    updated () {
      console.log(this.$route.name)
      if (this.$route.name === 'studentadd' && this.$route.name === 'publicStudentAdd1') {
        this.activeStep = 1
      } else if (this.$route.name === 'studentStep2' || this.$route.name === 'publicStudentAdd2') {
        this.activeStep = 2
      } else if (this.$route.name === 'studentStep5' || this.$route.name === 'publicStudentAdd5') {
        this.activeStep = 3
      }
    }
  }
}
</script>
