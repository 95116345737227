<template>
  <div class="home">
    <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
      <el-col :span="8">
        <h3 class="m-l-25">Ангилал жагсаалт</h3>
      </el-col>
      <el-col :span="3" class="user_head_add_button m-r-25">
        <el-button size="mini" type="success" @click="addCategoryDialog()">+ Ангилал үүсгэх</el-button>
      </el-col>
    </el-row>
    <el-row class="m-l-30">
      <el-col :span="23" class="home_body p-20">
        <el-table v-loading="loading" :data="positionList" stripe style="width: 100%">
          <el-table-column type="index" width="50">
          </el-table-column>
              <el-table-column prop="color_code" label="Ангилал харагдах байдал">
                  <template slot-scope="scope">
                        <el-tag :style="'background-color:'+ scope.row.color_code"> <span style="color:white">{{scope.row.title}}</span></el-tag>
                  </template>
          </el-table-column>
          <!-- <el-table-column label="Үйлдэл">
            <template slot-scope="scope">
              <el-button size="mini" type="info" @click="editPosition(scope.row)">Засах</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="Ангилал үүсгэх" :visible.sync="this.$root.categoryCreatDialog" width="50%"
      :before-close="handleClose">
      <el-form :model="selectedCategory" :rules="rules" ref="categoryForm" label-width="120px" label-position="top"
        class="demo-ruleForm">
        <el-form-item label="Ангилал гарчиг" prop="title">
          <el-input v-model="selectedCategory.title" placeholder="Гарчиг"></el-input>
        </el-form-item>
        <el-form-item label="Ангилал илэрхийлэх өнгө" prop="color_code">
          <el-color-picker v-model="selectedCategory.color_code" show-alpha :predefine="predefineColors">
          </el-color-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="createPosition('categoryForm')">Үүсгэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import {
  notification
} from '@/helpers/custom'
export default {
  name: 'LessonCategory',
  data () {
    return {
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      selectedCategory: {
        title: '',
        color_code: ''
      },
      positionList: [],
      loading: true,
      rules: {
        title: [{
          required: true,
          message: 'Ангилал нэр заавал оруулна уу',
          trigger: 'blur'
        }],
        color_code: [{
          required: true,
          message: 'Илэрхийлэх өнгө заавал сонгоно уу',
          trigger: 'blur'
        }]
      }
    }
  },
  created () {
    this.getCategoryList()
  },
  methods: {
    deletePosition (data) {
      this.$confirm(data.position_name + ' нэртэй албан тушаалыг усгах гэж байна итгэлтэй байна уу.', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const payload = {
          id: data.id
        }
        services.deletePosition(payload)
          .then((response) => {
            if (response.status === 'success') {
              this.$root.categoryCreatDialog = false
              notification('success_position')
              this.$root.fullscreenLoading = false
              location.reload()
            }
          })
      }).catch(() => {})
    },
    createPosition (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          services.createCategory(this.selectedCategory)
            .then((response) => {
              if (response.status === 'success') {
                this.$root.categoryCreatDialog = false
                notification('success_save')
                this.$root.fullscreenLoading = false
                location.reload()
              }
            })
        } else {
          return false
        }
      })
    },
    handleClose () {
      this.$root.categoryCreatDialog = false
    },
    editPosition (data) {
      this.selectedCategory = data
      this.$root.categoryCreatDialog = true
    },
    addCategoryDialog () {
      this.selectedCategory.title = ''
      this.selectedCategory.color_code = ''
      this.$root.categoryCreatDialog = true
    },
    getCategoryList () {
      services.getCategory()
        .then((response) => {
          this.positionList = response.data
          this.loading = false
        })
    }
  }
}

</script>

<style>

</style>
