<template>
  <div class="home">
    	<el-row class="page_head_zone">
			<el-col :xs="12" :sm="16" :md="18" :lg="20" :xl="20">
        <h3 class="m-l-25">Хичээлийн хуваарийн жагсаалт</h3>
			</el-col>
			<el-col :xs="4" :sm="4" :md="4" :lg="3" :xl="3" class="user_head_add_button m-r-25 flr">
				 <el-button size="mini" type="success" @click="addLessonScheduleDialog()">+ Хичээлийн хуваарь үүсгэх</el-button>
			</el-col>
		</el-row>
    <el-row>
      <el-col :span="24" class="home_body p-20">
        <el-tabs v-model="activeTab" type="border-card">
          <el-tab-pane label="1-р ээлж">
          </el-tab-pane>
          <el-tab-pane label="2-р ээлж"></el-tab-pane>
          <el-tab-pane label="3-р ээлж"></el-tab-pane>
          <el-tab-pane label="4-р ээлж"></el-tab-pane>
          <el-tab-pane label="5-р ээлж"></el-tab-pane>
          <el-tab-pane label="6-р ээлж"></el-tab-pane>
          <el-tab-pane label="7-р ээлж"></el-tab-pane>
          <el-table v-loading="loading" :data="lessonScheduleList" size="mini" stripe height="666" style="width: 100%">
            <el-table-column type="index" width="50">
            </el-table-column>
            <el-table-column prop="room_name" label="Анги">
              <template slot-scope="scope">
                <span v-if="scope.row.room !== null"> {{scope.row.room.room_name}}</span>
                <span style="font-weight:300; color:#ccc" v-if="scope.row.room === null"> Холбогдоогүй</span>
              </template>
            </el-table-column>
            <el-table-column prop="room_name" label="Багш">
              <template slot-scope="scope">
                <span v-if="scope.row.teacher !== null"> {{scope.row.teacher.name}}</span>
                <span style="font-weight:300; color:#ccc" v-if="scope.row.teacher === null"> Холбогдоогүй</span>
              </template>
            </el-table-column>
            <el-tabs type="border-card">
            </el-tabs>
            <el-table-column prop="student_limit" label="Дүүргэлт">
              <template slot-scope="scope">
                <el-tag size="mini">{{ scope.row.student_limit }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="students.length" label="Сурч байгаа">
              <template slot-scope="scope">
                <el-button slot="reference" round size="mini" type="success" @click="viewStudent(scope.row)">
                  {{scope.row.students.length}}</el-button>
              </template>
            </el-table-column>
            <el-table-column label="Үйлдэл" width="200px">
              <template slot-scope="scope">
                <el-tooltip v-if="checkPermissionsPage('lesson_schedule_teacher_add')" class="item" effect="dark" content="Энэхүү хуваарьт хамаарах багш солих засах" placement="top-start">
                <el-button size="mini" type="success" icon="el-icon-user-solid" @click="editTeach(scope.row)" circle>
                </el-button>
                </el-tooltip>
                <el-tooltip v-if="checkPermissionsPage('lesson_schedule_change')" class="item" effect="dark" content="Хичээлийн хуваарь засах" placement="top-start">
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="LessonSchedule(scope.row)" circle>
                </el-button>
                </el-tooltip>
                <el-tooltip v-if="checkPermissionsPage('lesson_schedule_delete')" class="item" effect="dark" content="Хичээлийн хуваарь устгах" placement="top-start">
                <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteLessonSchedule(scope.row)"
                  circle></el-button>
                  </el-tooltip>
                  <el-tooltip v-if="checkPermissionsPage('lesson_schedule_attendance_history')" class="item" effect="dark" content="Ирцийн түүх" placement="top-start">
                <el-button size="mini" type="warning" icon="el-icon-document-checked" @click="getScheduleLog(scope.row)"
                  circle></el-button>
                  </el-tooltip>
                  <el-tooltip v-if="checkPermissionsPage('lesson_schedule_student_add')" class="item" effect="dark" content="Сурагч нэмэх" placement="top-start">
                  <el-button :disabled="scope.row.student_limit <= scope.row.students.length" size="mini" type="success" icon="el-icon-plus" class="button_right"
                    @click="editStudentDrawerFunction(scope.row)" circle></el-button>
                  </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-tabs>
      </el-col>
    </el-row>
    <el-dialog title="Хичээлийн хуваарь үүсгэх" :visible.sync="this.$root.createLessonScheduleDialog" width="45%"
      :before-close="handleClose">
      <el-form :model="selectedLessonSchedule" :rules="rules" ref="ruleForm" label-width="120px" label-position="top"
        class="demo-ruleForm">
        <!-- <el-form-item label="Хичээллэх өдөр сонгох" prop="day">
          <el-radio-group v-model="selectedLessonSchedule.day" size="medium">
            <el-radio-button v-for="(selectItem, index) in this.jishee" :key="index" :label="selectItem.value">
              {{selectItem.name}}</el-radio-button>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="Хичээллийн ээлж" prop="eelj">
          <el-radio-group v-model="selectedLessonSchedule.eelj" size="medium"  @change="clickEelj()">
            <el-radio-button v-for="(Item, index) in this.lesson_eelj" :key="index" :label="Item.value">{{Item.name}}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Багш" prop="teacher_id">
          <el-select v-model="selectedLessonSchedule.teacher_id" placeholder="Багш сонгоно уу">
            <el-option v-for="(teach,index) in this.TeacherList" :key="index" :label="teach.name" :value="teach.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Хичээллийн эхлэх хугацаа болон дуусах хугацаа" prop="start_time">
          <el-time-picker v-model="selectedLessonSchedule.start_time" placeholder="Эхлэх хугацаа" format="hh:mm:ss"
            value-format="h:m:s">
          </el-time-picker>
          --
          <el-time-picker arrow-control v-model="selectedLessonSchedule.end_time" placeholder="Дуусах хугацаа"
            format="hh:mm:ss" value-format="h:m:s">
          </el-time-picker>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="26">
            <el-form-item label="Хичээллэх анги" prop="room_id">
              <el-select v-model="selectedLessonSchedule.room_id" placeholder="Анги">
                <el-option v-for="(roomItem,index) in this.roomList" :key="index" :label="roomItem.room_name"
                  :value="roomItem.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="26">
            <el-form-item label="Хичээллэх хүүхдийн нийт багтаамж" prop="student_limit">
              <el-input-number v-model="selectedLessonSchedule.student_limit" :controls="false" :min="null"
                placeholder="Багтаамж"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="createLessonSchedule('ruleForm')">Хадгалах</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Сурагчийн жагсаалт" :visible.sync="this.$root.studentList" width="45%"
      :before-close="handleCloseStudentsDialog">
      <el-table :data="studentDialogSelectedData.students" style="width: 100%">
        <el-table-column type="index" width="50">
        </el-table-column>
        <el-table-column prop="name" label="Овог">
        </el-table-column>
        <el-table-column prop="last_name" label="Сурагчийн нэр">
        </el-table-column>
        <el-table-column
      label="Үйлдэл">
      <template slot-scope="scope">
        <el-button
          size="mini"
          type="danger"
          @click="handleRemoveStudent(scope.row)">Хасах</el-button>
      </template>
    </el-table-column>
      </el-table>
    </el-dialog>
    <el-drawer title="Багш засах" :visible.sync="this.$root.editDrawer" custom-class="demo-drawer" ref="drawer"
      size="25%" :before-close="handleClose2">
      <div class="p-20">
        <el-form :model="selectedLessonSchedule" :rules="rules" ref="ruleForm" label-width="120px" label-position="top"
          class="demo-ruleForm">
          <el-form-item label="Багш">
            <el-select v-model="selectedLessonSchedule.teacher_id" placeholder="Багш сонгоно уу">
              <el-option v-for="(teach,index) in this.TeacherList" :key="index" :label="teach.name" :value="teach.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="TeacherEdit('ruleForm')">Хадгалах</el-button>
        </span>
      </div>
    </el-drawer>
    <el-drawer title="Сурагч нэмэх" :visible.sync="this.$root.editStudentDrawer" custom-class="demo-drawer" ref="drawer"
      size="25%" :before-close="handleClose3">
      <div class="p-20" v-loading="load">
        <div>
          <el-input
          v-model="findStudent"
          size="mini"
          clearable
          placeholder="Хайх сурагчийн нэр оруулна уу"/>
          <el-checkbox-group v-model="selectedStudents">
            <el-checkbox border class="m-t-10" v-for="student, index in studentListArray.filter(data => !findStudent || data.last_name.toLowerCase().includes(findStudent.toLowerCase()))" :label="student.id" :key="student.id">
             <b>{{ (index+1) + '.  '+ student.name + '.' + student.last_name}}</b>
             <!-- <b>{{ (index+1) + '.  '+ student.name.slice(0, 2) + '.' + student.last_name}}</b> -->
            </el-checkbox><br/>
          </el-checkbox-group> <br/>
          </div>
        <span slot="footer" class="dialog-footer m-t-50">
          <el-button type="success" size="mini" @click="createStudentSchedule('ruleForm')">Хадгалах</el-button>
        </span>
      </div>
    </el-drawer>
    <el-drawer title="Хичээлийн хуваарын түүх" :visible.sync="historyLogDialogStatus" ref="drawer"  size="35%"
      :before-close="handleCloseScheduleLog">
      <el-table :data="scheduleLogHistory">
        <el-table-column prop="created_at" label="Хичээллэсэн огноо">
          <template slot-scope="scope">
            {{ scope.row.created_at.split('T')[0] }}
          </template>
        </el-table-column>
        <el-table-column prop="eelj" label="Ээлж">
        </el-table-column>
        <el-table-column prop="teacher_name" label="Багш">
        </el-table-column>
        <el-table-column prop="room_name" label="Хичээллэсэн анги">
        </el-table-column>
        <el-table-column
      fixed="right"
      label="Operations"
      width="120">
      <template slot-scope="scope">
        <el-button @click="showAttendance(scope.row.id)" type="text" size="small">Ирц</el-button>
      </template>
    </el-table-column>
      </el-table>
    </el-drawer>
    <el-drawer :title="attendanceDetail.created_at.split('T')[0] + '-ны өдрийн ирц'" :visible.sync="showAttendanceStatus" ref="drawer"  size="35%"
      :before-close="handleCloseShowAttendanceStatus">
      <div class="p-30" v-loading="attendanceLoading">
        <el-row :gutter="24" type="flex" class="row-bg" justify="space-between">
                   <el-col :span="6">
                    <div class="schedule_detail_count">
                    Ирсэн <br/>
                    <span style="color:green"> {{returnStudentStatusCount(100)}}</span>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="schedule_detail_count">
                    Чөлөө <br/>
                    <span style="color:orange">{{returnStudentStatusCount(102)}}</span>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="schedule_detail_count">
                    Тас <br/>
                    <span style="color:orange">{{returnStudentStatusCount(101)}}</span>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="schedule_detail_count">
                    Өвчтэй <br/>
                    <span style="color:#281C9D">{{returnStudentStatusCount(103)}}</span>
                    </div>
                </el-col>
            </el-row>
        <div class="m-t-30">
          <span><span class="m-l-25">Сурагчийн нэр</span><span class="m-l-160">Ирц</span></span>
                    <el-card v-for="student, index in attendanceDetail.student_attendances" :key="index" class="m-t-10">
                    <el-row v-if="attendanceDetail.student_attendances.length > 0"  style="height:40px">
                            <el-col :span="1">
                            {{index + 1}}
                            </el-col>
                            <el-col :span="10">
                                {{student.student.last_name}}
                            </el-col>
                            <template >
                            <el-col :span="12">
                              <el-button :disabled = "student.status_id == 100" :type="student.status_id == 100 ? 'success' : ''" size="mini" @click="changeAttendanceValue(student.student.id, 100, student.id)">и</el-button>
                                <el-button :disabled = "student.status_id == 80" :type="student.status_id == 102 ? 'warning' : ''" size="mini"  @click="changeAttendanceValue(student.student.id, 102,student.id)">ч</el-button>
                                <el-button :disabled = "student.status_id == 101" :type="student.status_id == 101 ? 'danger' : ''" size="mini"  @click="changeAttendanceValue(student.student.id, 101,student.id)">т</el-button>
                                <el-button :disabled = "student.status_id == 60" :type="student.status_id == 103 ? 'primary' : ''" size="mini"  @click="changeAttendanceValue(student.student.id, 103,student.id)">ө</el-button>
                           </el-col>
                          </template>
                          <el-col :span="22" :offset="1" v-if="student.status_id === 100">
                    <el-slider disabled v-model="student.percent" :step="10" show-stops>
                    </el-slider>
                  </el-col>
                      </el-row>
                </el-card>
                  </div>
                        </div>
    </el-drawer>

  </div>
</template>

<script>
import services from '@/helpers/services.js'
import {
  notification, returnValidationErrorMessage, checkPermissions
} from '@/helpers/custom'
export default {
  data () {
    return {
      marks: {
        10: '10',
        20: '20',
        30: '30',
        100: {
          style: {
            color: '#1989FA'
          },
          label: this.$createElement('strong', '100%')
        }
      },
      attendanceLoading: false,
      historyLogDialogStatus: false,
      scheduleLogHistory: [],
      load: true,
      selectedStudents: [],
      findStudent: '',
      activeTab: '0',
      lesson_eelj: [{
        id: 1,
        name: '1-р ээлж',
        value: 1
      },
      {
        id: 2,
        name: '2-р ээлж',
        value: 2
      },
      {
        id: 3,
        name: '3-р ээлж',
        value: 3
      },
      {
        id: 4,
        name: '4-р ээлж',
        value: 4
      },
      {
        id: 5,
        name: '5-р ээлж',
        value: 5
      },
      {
        id: 6,
        name: '6-р ээлж',
        value: 6
      },
      {
        id: 7,
        name: '7-р ээлж',
        value: 7
      }
      ],
      rules: {
        day: [{
          required: true,
          message: 'Өдөр оруулна уу',
          trigger: 'blur'
        }],
        eelj: [{
          required: true,
          message: 'Хичээлийн ээлж оруулна уу',
          trigger: 'blur'
        }],
        teacher_id: [{
          required: true,
          message: 'Багш сонгоно уу',
          trigger: 'blur'
        }],
        start_time: [{
          required: true,
          message: 'Хичээлийн эхлэх цаг оруулна уу',
          trigger: 'blur'
        }],
        end_time: [{
          required: true,
          message: 'Хичээлийн дуусах цаг оруулна уу',
          trigger: 'blur'
        }],
        room_id: [{
          required: true,
          message: 'Хичээл орох анги оруулна уу',
          trigger: 'blur'
        }],
        student_limit: [{
          required: true,
          message: 'Хичээл өрөөний хүүхдийн багтаамж оруулна уу',
          trigger: 'blur'
        }]
      },
      selectedLessonSchedule: {
        day: '',
        eelj: '',
        lesson_id: '',
        start_time: '',
        end_time: '',
        room_id: '',
        student_limit: null
      },
      studentListArray: [],
      TeacherList: [],
      roomList: [],
      lessonScheduleList: [],
      loading: true,
      students: [],
      studentDialogSelectedData: {},
      selectLoader: false,
      options: [],
      showAttendanceStatus: false,
      attendanceDetail: {
        created_at: '2022-02-03T010101',
        student_attendances: []
      }
    }
  },
  created () {
    this.getTeacherView()
    this.getroomList()
    this.getView()
    this.$root.showMenuDrawerStatus = false;
  },
  watch: {
    activeTab () {
      this.getView()
    }
  },
  methods: {
    checkPermissionsPage (role) {
      return checkPermissions(role)
    },
    clickEelj () {
      if (this.selectedLessonSchedule.eelj === 1) {
        this.selectedLessonSchedule.start_time = '08:00:00'
        this.selectedLessonSchedule.end_time = '09:30:00'
      }
      if (this.selectedLessonSchedule.eelj === 2) {
        this.selectedLessonSchedule.start_time = '09:30:00'
        this.selectedLessonSchedule.end_time = '11:00:00'
      }
      if (this.selectedLessonSchedule.eelj === 3) {
        this.selectedLessonSchedule.start_time = '11:00:00'
        this.selectedLessonSchedule.end_time = '12:30:00'
      }
      if (this.selectedLessonSchedule.eelj === 4) {
        this.selectedLessonSchedule.start_time = '13:30:00'
        this.selectedLessonSchedule.end_time = '15:00:00'
      }
      if (this.selectedLessonSchedule.eelj === 5) {
        this.selectedLessonSchedule.start_time = '15:00:00'
        this.selectedLessonSchedule.end_time = '16:30:00'
      }
      if (this.selectedLessonSchedule.eelj === 6) {
        this.selectedLessonSchedule.start_time = '16:30:00'
        this.selectedLessonSchedule.end_time = '18:00:00'
      }
      if (this.selectedLessonSchedule.eelj === 7) {
        this.selectedLessonSchedule.start_time = '18:00:00'
        this.selectedLessonSchedule.end_time = '19:30:00'
      }
    },
    handleRemoveStudent (data) {
      this.$confirm('Сурагч хичээлийн хуваариас хасах гэж байна.Үргэлжлүүлэх үү?', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        const payload = {
          student_id: data.id,
          schedule_id: this.studentDialogSelectedData.id
        }
        services.removeScheduleCustomStudent(payload)
          .then((response) => {
            if (response.status === 'success') {
              this.$root.createLessonScheduleDialog = false
              notification('success_deleted')
              this.$root.fullscreenLoading = false
              location.reload()
            }
            // console.log(response)
          })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },
    returnStudentStatusCount (status) {
      let count = 0
      this.attendanceDetail.student_attendances.forEach(element => {
        if (status === element.status_id) {
          count = count + 1
        }
      })
      return count
    },
    changeAttendanceValue (studentId, statusId, attendanceId) {
      this.attendanceLoading = true
      const payload = {
        schedule_log_id: this.attendanceDetail.id,
        student_id: studentId,
        status_id: statusId,
        attendance_id: attendanceId
      }
      services.createAttendance(payload)
        .then((response) => {
          if (response.status === 'success') {
            notification('success_empty')
            this.getScheduleLogHistoryByIDService(this.attendanceDetail.id)
          }
          // console.log(response)
        })
    },
    handleCloseShowAttendanceStatus () {
      this.showAttendanceStatus = false
      this.attendanceDetail = {
        created_at: '2022-02-03T010101',
        student_attendances: []
      }
    },
    showAttendance (id) {
      this.getScheduleLogHistoryByIDService(id)
      this.showAttendanceStatus = true
    },
    getScheduleLogHistoryByIDService (id) {
      this.attendanceLoading = true
      const payload = {
        schedule_log_id: id
      }
      services.getScheduleLogHistoryByID(payload)
        .then((response) => {
          if (response.success === true) {
            this.attendanceDetail = response.data
            this.attendanceLoading = false
          } else {
            notification('error_empty')
          }
        })
    },
    handleCloseScheduleLog () {
      this.historyLogDialogStatus = false
    },
    getScheduleLog (data) {
      this.$root.fullscreenLoading = true
      const payload = {
        schedule_id: data.id
      }
      services.getScheduleLogHistory(payload)
        .then((response) => {
          if (response.success === true) {
            this.scheduleLogHistory = response.data
            this.historyLogDialogStatus = true
            this.$root.fullscreenLoading = false
          } else {
            notification('error_empty')
            this.$root.fullscreenLoading = false
          }
      this.$root.fullscreenLoading = false
        })
    },
    viewStudent (data) {
      this.studentDialogSelectedData = data
      this.$root.studentList = true
    },
    handleCloseStudentsDialog () {
      this.ststudentDialogSelectedDataudents = {}
      this.$root.studentList = false
    },
    // getLessonList () {
    //   services.getTeacherList()
    //     .then((response) => {
    //       this.LessonList = response.data
    //     })
    // },
    getroomList () {
      services.getRoom()
        .then((response) => {
          this.roomList = response.data
        })
    },
    getTeacherView () {
      const payload = {
        position_type: 0
      }
      services.getTeacherList(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.TeacherList = response.result
          }
        })
    },
    getStudentsView (id) {
      const payload = {
        id: id
      }
      services.getStudentsView(payload)
        .then((response) => {
          this.studentListArray = response.data
          this.load = false
        })
    },
    deleteLessonSchedule (data) {
      this.$confirm('Устгах гэж байна итгэлтэй байна уу.', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const payload = {
          id: data.id
        }
        services.deleteLessonSchedule(payload)
          .then((response) => {
            if (response.status === 'success') {
              this.$root.createLessonScheduleDialog = false
              notification('success_deleted')
              this.$root.fullscreenLoading = false
              location.reload()
            }
            // console.log(response)
          })
      }).catch(() => {})
    },
    TeacherEdit (formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          services.teacherChange(this.selectedLessonSchedule)
            .then((response) => {
              if (response.status === 'success') {
                this.$root.createLessonScheduleDialog = false
                notification('success_save')
                this.$root.fullscreenLoading = false
                location.reload()
              } else if (response.status === 'unSuccess') {
                this.$root.fullscreenLoading = false
              }
              // console.log(response)
            })
        }
      })
    },
    createStudentSchedule () {
      this.$root.fullscreenLoading = true
      const payload = {
        schedule_id: this.selectedLessonSchedule.id,
        students: this.selectedStudents
      }
      services.createStudentSchedule(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.$root.fullscreenLoading = false
            this.selectedStudents = []
            this.handleClose3()
            this.handleCloseStudentsDialog()
            this.getView()
          } else if (response.status === 'unSuccess') {
            returnValidationErrorMessage(response)
            this.$root.fullscreenLoading = false
          }
          // console.log(response)
        })
    },
    createLessonSchedule (formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          services.createLessonSchedule(this.selectedLessonSchedule)
            .then((response) => {
              if (response.status === 'success') {
                this.$root.createLessonScheduleDialog = false
                notification('success_save')
                this.$root.fullscreenLoading = false
                location.reload()
              } else if (response.status === 'unSuccess') {
                notification('schedule_unSuccess_save')
                this.$root.fullscreenLoading = false
              }
              // console.log(response)
            })
        }
      })
    },
    editStudentDrawerFunction (data) {
      this.selectedLessonSchedule = data
      this.$root.editStudentDrawer = true
      this.getStudentsView(data.id)
    },
    handleClose3 () {
      this.studentListArray = []
      this.$root.editStudentDrawer = false
    },
    editTeach (data) {
      this.selectedLessonSchedule = data
      this.$root.editDrawer = true
    },
    handleClose2 () {
      this.$root.editDrawer = false
    },
    handleClose1 () {
      this.$root.studentList = false
    },
    handleClose () {
      this.$root.createLessonScheduleDialog = false
    },
    LessonSchedule (data) {
      this.selectedLessonSchedule = data
      this.$root.createLessonScheduleDialog = true
    },
    addLessonScheduleDialog () {
      this.selectedLessonSchedule.day = ''
      this.selectedLessonSchedule.eelj = ''
      this.selectedLessonSchedule.lesson_id = ''
      this.selectedLessonSchedule.start_time = ''
      this.selectedLessonSchedule.end_time = ''
      this.selectedLessonSchedule.room_id = ''
      this.selectedLessonSchedule.student_limit = ''
      this.$root.createLessonScheduleDialog = true
    },
    getView () {
      this.$root.fullscreenLoading = true
      const payload = {
        eelj: parseInt(this.activeTab) + 1
      }
      services.getLessonScheduleFSADMIN(payload)
        .then((response) => {
          if (response.success === true) {
            this.lessonScheduleList = response.data
            this.loading = false
            this.$root.fullscreenLoading = false
          }
        })
    }
  }
}

</script>

<style>

</style>
