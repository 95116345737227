<template>
       <el-dialog
  title="Сурагчийн дэлгэрэнгүй"
  :visible.sync="this.$root.studentDetailDialogVariable"
  :before-close="handleClose"
  width="600px"
  center>
  <span>Овог: {{ selectedStudent.to_contract.student.name }}</span> <br/>
  <span>Нэр: {{ selectedStudent.to_contract.student.last_name }}</span> <br/>
  <!-- <span>Утас: {{ selectedStudent.to_contract.student.phone }}</span> <br/> -->
  <span>
    <el-table
      :data="selectedStudent.to_contract.student.student_note"
      style="width: 100%">
      <el-table-column
        prop="last_name"
        label="Бүртгэсэн ажилтан"
        width="180">
        <template slot-scope="scope">
          {{ scope.row.created_user.last_name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="Бүртгэсэн огноо"
        width="180">
        <template slot-scope="scope">
          {{ scope.row.created_at.split('T')[0]}}
        </template>
      </el-table-column>
      <el-table-column
        prop="description"
        label="Тэмдэглэл"
        width="180">
      </el-table-column>
    </el-table>
    <el-input
    class="m-5"
  type="textarea"
  placeholder="Тэмдэглэл"
  v-model="valueDesc"
  maxlength="30"
  show-word-limit
>
</el-input>
<el-button class="m-t-10" type="success" @click="saveDesciptionStudent()">Тэмдэглэл хадгалах</el-button>
  </span>
  <span slot="footer" class="dialog-footer">
  </span>
</el-dialog>
</template>
<script>
import services from '@/helpers/services.js'
export default {
  props: {
    selectedStudent: Object
  },
  data () {
    return {
      valueDesc: ''
    }
  },
  methods: {
    handleClose () {
      this.$root.studentDetailDialogVariable = false
    },
    saveDesciptionStudent () {
      this.$root.fullscreenLoading = true
      const payload = {
        student_id: this.selectedStudent.to_contract.student.id,
        valueDesc: this.valueDesc
      }
      services.createStudentNote(payload)
        .then((response) => {
          if (response.status === 'success') {
            location.reload()
          }
        })
    }
  }
}
</script>
