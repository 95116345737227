<template>
  <div class="p-l-30">
    <el-row>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="220px" class="demo-ruleForm p-20"
        label-position="top">
        <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
          <el-form-item label="Сурагчийн овог" prop="name">
            <el-input v-model="ruleForm.name" clearable placeholder="Сурагчийн овог оруулна уу"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
          <el-form-item label="Сурагчийн нэр" prop="last_name">
            <el-input v-model="ruleForm.last_name" clearable placeholder="Сурагчийн нэр оруулна уу"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
          <el-form-item label="Регистрийн Дугаар" clearable prop="regno">
            <el-input v-model="ruleForm.regno" clearable placeholder="Регистрийн дугаар оруулна уу" minlength="10"
              maxlength="10" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-form-item label="Албан тушаал" prop="position_id">
              <el-select v-model="ruleForm.position_id" placeholder="Хэрэглэгчийн төрөл">
                <el-option v-for="(roleItem,index) in this.positionList" :key="index" :label="roleItem.position_name"
                  :value="roleItem.id"></el-option>
              </el-select>
            </el-form-item> -->
        <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
          <el-form-item label="Утас" prop="phone">
            <el-input class="w-100" v-model="ruleForm.phone" placeholder="Утас" clearable :minlength="8" :maxlength="8"
              show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
          <el-form-item label="Сургууль" prop="school">
            <el-input v-model="ruleForm.school" placeholder="Та сургуулиа оруулна уу">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
          <el-form-item label="Анги" prop="grade">
            <el-select class="w-100" v-model="ruleForm.grade" placeholder="Та ангиа оруулна уу">
              <el-option v-for="item in grades" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
          <el-form-item label="Ам бүлийн тоо" prop="family">
            <el-input v-model="ruleForm.family" clearable placeholder="Ам бүлийн тоо оруулна уу"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
          <el-form-item label="Айлын хэд дэх хүүхэд" prop="children_level">
            <el-input v-model="ruleForm.children_level" clearable placeholder="Айлын хэд дэх хүүхэд вэ?"></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
          <el-form-item v-if="this.is_password_show" label="Нууц үг" prop="password">
            <el-input v-model="ruleForm.password" placeholder="Та нууц үгээ оруулна уу" show-password clearable>
            </el-input>
          </el-form-item>
        </el-col> -->
        <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
          <el-form-item label="Хүйс" v-model="ruleForm.gender" prop="gender">
            <el-radio-group v-model="ruleForm.gender" size="small">
              <el-radio-button :label="1">Эр</el-radio-button>
              <el-radio-button :label="2">Эм</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
          <el-form-item label="Орж ирсэн суваг" prop="chanel">
            <el-select class="w-100" v-model="ruleForm.chanel" placeholder="Орж ирсэн суваг оруулна уу">
              <el-option v-for="item in users" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <el-col :span="22">
        <el-button type="primary" class="button" @click="submitForm('ruleForm')">Хадгалах</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import services from '@/helpers/services.js'
import {
  notification,
  returnValidationErrorMessage
} from '@/helpers/custom.js'
export default {
  props: {
    user: Object
  },
  created () {
    this.getPositionList()
    this.calculateMyData()
  },
  components: {},
  data () {
    return {
      grades: [{
        value: '1-р анги',
        label: '1-р анги'
      }, {
        value: '2-р анги',
        label: '2-р анги'
      }, {
        value: '3-р анги',
        label: '3-р анги'
      }, {
        value: '4-р анги',
        label: '4-р анги'
      }, {
        value: '5',
        label: '5-р анги'
      }, {
        value: '6',
        label: '6-р анги'
      }, {
        value: '7-р анги',
        label: '7-р анги'
      }, {
        value: '8-р анги',
        label: '8-р анги'
      }, {
        value: '9-р анги',
        label: '9-р анги'
      }, {
        value: '10-р анги',
        label: '10-р анги'
      }, {
        value: '11-р анги',
        label: '11-р анги'
      }, {
        value: '12-р анги',
        label: '12-р анги'
      },
      {
        value: 'Бусад',
        label: 'Бусад'
      }
      ],
      users: [{
        value: 'Фэйс бүүк',
        label: 'Фэйс бүүк'
      }, {
        value: 'Найз',
        label: 'Найз'
      }, {
        value: 'Багш',
        label: 'Багш'
      }, {
        value: 'Ах, эгч',
        label: 'Ах, эгч'
      }, {
        value: 'Манай суралцагч',
        label: 'Манай суралцагч'
      }
      ],
      positionList: [],
      ruleForm: {
        name: '',
        last_name: '',
        gender: 1,
        regno: '',
        phone: null,
        position_id: 13,
        email: '',
        img_url: '',
        password: '12345',
        is_show_web: 0,
        school: '',
        grade: '',
        family: 0,
        children_level: 0,
        chanel: ''
      },
      is_upload_image: true,
      rules: {
        profile_img_url: [{
          required: true,
          message: 'Та  зурагаа уу',
          trigger: 'change '
        }],
        name: [{
          required: true,
          message: 'Та овог оруулна уу',
          trigger: 'change '
        }],
        last_name: [{
          required: true,
          message: 'Та нэрээ оруулна уу',
          trigger: 'change'
        }],
        regno: [{
          required: true,
          message: 'Та бөглөнө үү',
          trigger: 'change'
        },
        {
          min: 10,
          max: 10,
          message: 'Буруу РД дугаар байна',
          trigger: 'blur'
        }
        ],
        // position_id: [{
        //   required: true,
        //   message: 'Та хэрэглэгчийн эрх сонгоно уу',
        //   trigger: 'change'
        // }],
        // phone: [{
        //   required: true,
        //   message: 'Та утасны дугаараа оруулна уу',
        //   trigger: 'blur'
        // }],
        gender: [{
          required: true,
          message: 'Та бөглөнө үү',
          trigger: 'change'
        }],
        password: [{
          required: true,
          message: 'Та нууц үгээ оруулна уу',
          trigger: 'change'
        }]
      },
      tableData: [],
      is_password_show: true
    }
  },

  methods: {
    getStudentById () {

    },
    calculateMyData () {
      if (this.$route.params.student_id !== undefined) {
        this.$root.fullscreenLoading = true
        this.is_password_show = false
        const jishee = {
          id: this.$route.params.student_id
        }
        services.getUserById(jishee)
          .then(response => {
            this.ruleForm = response.result
            this.$root.fullscreenLoading = false
          })
      } else {
        console.log('undefined bn')
      }
    },
    getPositionList () {
      services.getPosition()
        .then((response) => {
          this.positionList = response.data
        })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          this.ruleForm.img_url = this.$root.img_url
          services.storeUserInfo(this.ruleForm)
            .then(response => {
              if (response.status === 'success') {
                if (this.$router.currentRoute.path === '/publicStudentAdd1') {
                  notification('add_user_nofitcation')
                  this.$root.fullscreenLoading = false
                  this.$root.img_url = null
                  this.$router.push({
                    params: {
                      id: response.result.id
                    },
                    name: 'publicStudentAdd2'
                  })
                } else {
                  this.$root.img_url = null
                  this.$router.push({
                    params: {
                      id: response.result.id
                    },
                    name: 'studentStep2'
                  })
                }
                // if (this.activeStep++ > 2) this.activeStep = 1
                this.$root.fullscreenLoading = false
              } else if (response.status === 'failed') {
                returnValidationErrorMessage(response)
                this.$root.fullscreenLoading = false
                this.$root.img_url = null
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}

</script>
