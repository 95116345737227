<template>
  <div>
    <el-dialog title="Сурагчийн төлөв өөрчлөх" :visible.sync="this.$root.studentStatus" width="50%" :before-close="handleClose">
      <el-form :model="ruleForm" ref="ruleForm" label-width="350px" class="demo-ruleForm p-20" label-position="top">
          <el-form-item  prop="status">
            <el-radio-group v-model="ruleForm.user_status" size="small">
              <!-- <el-radio-button :label="10" >Хүлээгдэж байгаа</el-radio-button >/ -->
              <!-- <el-radio-button :label="60" >Гэрээ хийгдсэн</el-radio-button > -->
              <!-- <el-radio-button type="success" :label="70">Төлбөр төлсөн</el-radio-button> -->
              <!-- <el-radio-button :label="80">Суралцаж байгаа</el-radio-button > -->
              <!-- <el-radio-button :label="20">Цаг дууссан</el-radio-button> -->
              <el-radio-button type="danger" :label="30">Цуцлах</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div class="m-t-20">
            Тайлбар: <br />
            <el-input class="m-t-10" type="textarea"  placeholder="Цуцлах болсон шалтгаанаа оруулна уу?" v-model="ruleForm.description"></el-input>
          </div><br/>
          <el-col :span="24">
            <el-button type="success" @click="changeStatus()" class="button_right">Хадгалах</el-button>
          </el-col>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import services from '@/helpers/services.js'
export default {
  props:{
    student: Object
  },
  data () {
    return {
      ruleForm: {
        student_id:null,
        user_status: 0,
        contract_id:null,
        description: ''
      }
    }
  },
  created(){
    if(this.student.id){
      this.ruleForm.student_id = this.student.id
      if(this.student.user_status){
        this.ruleForm.user_status = this.student.user_status.status_code
        if (this.student.student_contract) {
        this.ruleForm.contract_id = this.student.student_contract.id
        
        }
      }
    }
  },
  methods: {
    handleClose () {
      this.$root.studentStatus = false
    },
    changeStatus() {
            this.$root.fullscreenLoading = true
            services.studentStatusChange(this.ruleForm)
              .then(response => {
                if (response.status === 'success') {
                  location.reload()
                  this.$root.fullscreenLoading = false
                } else if (response.status === 'failed') {
                  this.$root.fullscreenLoading = true
                  Object.keys(response.message).forEach((element, index) => {
                    this.$notify.error({
                      title: 'Алдаа',
                      message: response.message[element],
                      offset: (index * 150)
                    })
                    this.$root.fullscreenLoading = false
                  })
                }
              })
      }
  }
}

</script>
