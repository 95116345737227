<template>
  <el-drawer :show-close="true" title="I am the title" :visible.sync="this.$root.showMenuDrawerStatus" :size="300" :destroy-on-close="true"
    direction="ltr" :before-close="handleClose">
    <nav id="sidebar" class="sidebar-wrapper">
      <div class="sidebar-content">
        <div class="sidebar-brand">
          <span class="sidebar-header-title-text">
            Дэлхийн мэдлэг академи
          </span>
          <div id="close-sidebar">
          </div>
        </div>

        <div class="__sideL">
          <div class="__logo"><span></span></div>
        </div>
        <el-menu class="sidebar-menu side_bar_radius" :default-active="defaultActiveMenu" background-color="#252B3B"
          text-color="#ffffff" style="border: none; font-weight: 400" active-text-color="#0AAADC">
          <!-- <li class="header-menu">
            <span>Үндсэн мэню</span>
          </li> -->

          <el-menu-item-group v-for="(parent, parentIndex) in menuList" :key="parent.caption + '_' + parentIndex"
            :title="parent.caption" class="m-t-20">
            <div v-for="(mainmenu, mainmenuIndex) in parent.list" :key="mainmenuIndex + '_' + mainmenuIndex">

              <!-- main menu has submenu -->
              <div v-if="mainmenu.submenu">
                <template v-if="checkPermissionsPage(mainmenu.role)">
                  <el-submenu :index="parentIndex + '-' + mainmenuIndex">
                    <template slot="title"><i :class="mainmenu.icon"></i><span>{{ mainmenu.title }}</span></template>
                    <el-menu-item-group>
                      <div v-for="(submenu, submenuIndex) in mainmenu.submenu" :key="submenu.name + '_' + submenuIndex">

                        <!-- submenu has childmenu -->
                        <div v-if="submenu.childmenu">
                          <el-submenu :index="parentIndex + '-' + mainmenuIndex + '-' + submenuIndex">
                            <template slot="title"><i :class="submenu.icon"></i><span>{{ submenu.name }}</span></template>
                            <el-menu-item-group>
                              <router-link v-for="(childmenu, childmenuIndex) in submenu.childmenu"
                                :key="childmenu.name + '_' + childmenuIndex" :to="childmenu.to">
                                <el-menu-item
                                  :index="parentIndex + '-' + mainmenuIndex + '-' + submenuIndex + '-' + childmenuIndex">
                                  <template slot="title">
                                    <i :class="childmenu.icon"></i>
                                    <span>{{ childmenu.name }}</span>
                                  </template>
                                </el-menu-item>
                              </router-link>
                            </el-menu-item-group>
                          </el-submenu>
                        </div>
                        <!-- -->

                        <!-- submenu has no childmenu -->
                        <div v-else>
                          <router-link :to="submenu.to">
                            <el-menu-item :index="parentIndex + '-' + mainmenuIndex + '-' + submenuIndex">
                              <i :class="submenu.icon"></i>
                              <span>{{ submenu.name }}</span>
                            </el-menu-item>
                          </router-link>
                        </div>
                        <!-- -->

                      </div>
                    </el-menu-item-group>
                  </el-submenu>
                </template>
              </div>
              <!-- -->

              <!-- main menu has no submenu -->
              <div v-else>
                <router-link :to="mainmenu.to">
                  <el-menu-item class="sidebar-dropdown" :index="parentIndex + '-' + mainmenuIndex">
                    <i :class="mainmenu.icon"></i>
                    <span>{{ mainmenu.title }}</span>
                  </el-menu-item>
                </router-link>
              </div>
              <!-- -->
            </div>
          </el-menu-item-group>
        </el-menu>
      </div>

      <!-- sidebar-content  -->
      <div class="sidebar-footer">
        <!-- <a @click="clickMenu('Гарах')">
          <i class="fa fa-power-off"></i>
        </a> -->
      </div>
    </nav>
    <div class="__hB">
      <div class="__dg __hSM">
        <div class="__hM">
          <div class="__hSF">
            <div class="__sFB">
              <div class="__h"></div>
            </div>
          </div>
          <div class="__hAB"><button type="button" class="__btn __btnD __dfc">
              <div class="__aNT __dfcc">{{ userInfo.phone }}</div><span class="__aU_D">
                <p class="__aNameU">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      {{ userInfo.email }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click="logout()">Гарах</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </p>
              </span>
            </button></div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import Logo from '@/assets/dms_logo_png.png'
import {
  checkPermissions
} from '@/helpers/custom'
export default {
  created() {
    // this.accessToken()
    // this.$store.commit('keepSidebarMenuData', this.menuList)
  },
  data() {
    return {
      url: Logo,
      containerCollapsed: '',
      sidebarStatus: '',
      submenuView: 'none',
      reports: null,
      token: '',
      role: [],
      defaultActiveMenu: '1-1-0-0',
      userInfo: {
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
      },
      menuList: [{
        caption: 'Үндсэн мэню',
        list: [{
          title: 'Хянах самбар',
          icon: 'el-icon-data-analysis',
          to: '/',
          role: 'get_dashboard'
        },
        {
          title: 'Ажилтан удирдах',
          icon: 'el-icon-user-solid',
          role: 'get_employee',
          submenu: [{
            name: 'Албан тушаал',
            icon: 'el-icon-star-on',
            to: '/position'
          },
          {
            name: 'Ажилтаны жагсаалт',
            icon: 'el-icon-user-solid',
            to: '/user'
          }
          ]
        },
        {
          title: 'Хичээл удирдах',
          role: 'get_schedule',
          icon: 'el-icon-s-cooperation',
          submenu: [
            //   {
            //   name: 'Хичээлийн ангилал',
            //   icon: 'el-icon-s-management',
            //   to: '/category'
            // },
            // {
            //   name: 'Хичээлийн жагсаалт',
            //   icon: 'el-icon-s-management',
            //   to: '/lesson'
            // },
            {
              name: 'Хичээлийн хуваарь',
              icon: 'el-icon-s-management',
              to: '/LessonSchedule'
            },
            {
              name: 'Хичээлийн сэдэв',
              icon: 'el-icon-s-management',
              to: '/topic'
            },
            {
              name: 'Хичээлийн ирц',
              icon: 'el-icon-s-management',
              to: '/lessonAttendance'
            }
          ]
        },
        {
          title: 'Оффис удирдах',
          icon: 'el-icon-takeaway-box',
          role: 'get_office',
          submenu: [{
            name: 'Өрөөний сонголт',
            icon: 'el-icon-document-copy',
            to: '/room'
          },
          {
            name: 'Санал хүсэлт',
            icon: 'el-icon-document-copy',
            to: '/userRequest'
          },
          // {
          //   title: 'Мессеж',
          //   icon: 'el-icon-document-copy',
          //   role: 'get_message',
          //   submenu: [{
          //     name: 'Мсж тохиргоо',
          //     icon: 'el-icon-document-copy',
          //     to: '/sms'
          //   },
          //   {
          //     name: 'Мсж түүх',
          //     icon: 'el-icon-document-copy',
          //     to: '/smsHistory'
          //   }]
          // },
          {
            name: 'Мсж тохиргоо',
            icon: 'el-icon-document-copy',
            to: '/sms'
          },
          {
            name: 'Мсж түүх',
            icon: 'el-icon-document-copy',
            to: '/smsHistory'
          },
          {
            name: 'Үйл ажиллагаа',
            icon: 'el-icon-document-copy',
            to: '/event'
          }
          ]
        },
        // {
        //   title: 'Ажилтан удирдах',
        //   icon: 'el-icon-s-custom',
        //   submenu: [{
        //     name: 'Нийт ажилтны жагсаалт',
        //     icon: 'el-icon-milk-tea',
        //     to: '/user'
        //   },
        //   {
        //     name: 'Эрх удирдах',
        //     icon: 'el-icon-milk-tea',
        //     to: '/roleList'
        //   }
        //   ]
        // },
        {
          title: 'Цахим хуудас удирдах',
          icon: 'el-icon-monitor',
          role: 'get_web',
          submenu: [{
            name: 'Блог жагсаалт',
            icon: 'el-icon-milk-tea',
            to: '/blogslist'
          },
          {
            name: 'Асуулт удирдах',
            icon: 'el-icon-milk-tea',
            to: '/listQuestion'
          },
          {
            name: 'Эцэг эх сэтгэгдэл',
            icon: 'el-icon-document-copy',
            to: '/parents'
          }
          ]
        },
        {
          title: 'Сурагч удирдах',
          icon: 'el-icon-s-custom',
          role: 'get_student',
          submenu: [{
            name: 'Сурагч жагсаалт',
            icon: 'el-icon-user',
            to: '/student'
          },
          // {
          //   name: 'Хичээл эхлэх сураг',
          //   icon: 'el-icon-user',
          //   to: '/student'
          // },
          {
            name: 'Суралцаж эхлэх сурагчид',
            icon: 'el-icon-user',
            to: '/willStartStudent'
          },
          {
            name: 'Математикийн сурагчдийн жагсаалт',
            icon: 'el-icon-user',
            to: '/matStudentList'
          },
          {
            name: 'Англи хэлний сурагчдийн жагсаалт',
            icon: 'el-icon-user',
            to: '/engStudentList'
          },
          {
            name: 'Өвчтэй, чөлөөтэй сурагчдийн жагсаалт',
            icon: 'el-icon-user',
            to: '/vacationStudentsList'
          },
          {
            name: 'excel',
            icon: 'el-icon-user',
            to: '/studentExcelList'
          },
          {
            name: 'Асран хамгаалагчийн мэдээлэл',
            icon: 'el-icon-user',
            to: '/parentDetail'
          }
          ]
        },
        {
          title: 'Гэрээ удирдах',
          icon: 'el-icon-document',
          role: '',
          submenu: [{
            name: 'Гэрээний жагсаалт',
            icon: 'el-icon-document',
            to: '/contractCancel'
          }
          ]
        }]
      }
      ]
    }
  },
  methods: {
    handleClose() {
      this.$root.showMenuDrawerStatus = false;
    },
    checkPermissionsPage(data) {
      return checkPermissions(data)
    },
    // accessToken: function () {
    //   getUser().then(resp => {
    //     this.userInfo.email = resp.attributes.email
    //     const x = resp.username
    //     this.userInfo.phone = x.charAt(0)
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
    logout() {
      //   signOut()
    },
    // // Тайлангийн жагсаалт авах
    // fetchReports () {
    //   getGroups().then(data => {
    //     this.role = data
    //     if (data.length > 0) {
    //       const baseURI = 'https://api.kfcmongolia.com/manager/getreportgroup/'
    //       axios.get(baseURI, {
    //         headers: {
    //           'Content-Type': 'application/json',
    //           Authorization: 'Bearer ' + this.token,
    //           role: data[0]
    //         }
    //       }).then((result) => {
    //         const tempArr = []
    //         result.data.forEach(el => {
    //           // if (el.role.includes(data[0])) {
    //           tempArr.push(el)
    //           // }
    //         })
    //         this.reports = tempArr
    //         // this.reports = result.data;
    //       })
    //     }
    //   })
    // },
    profileEdit() {
      alert('ss')
    },
    // matchSidebarActiveStatus () {
    //   const path = this.$router.history.current.path
    //   const match = []
    //   this.menuList.map((parent, pIndex) => {
    //     parent.list && parent.list.map((main, mIndex) => {
    //       if (main.to === path) return match.push(pIndex + '-' + mIndex)
    //       main.submenu && main.submenu.map((sub, sIndex) => {
    //         if (sub.to === path) return match.push(pIndex + '-' + mIndex + '-' + sIndex)
    //         sub.childmenu && sub.childmenu.map((child, cIndex) => {
    //           if (child.to === path) {
    //             return match.push(pIndex + '-' + mIndex + '-' + sIndex + '-' +
    //                 cIndex)
    //           }
    //         })
    //       })
    //     })
    //   })
    //   this.defaultActiveMenu = match[0] || '0-0'
    // },
    clickReportMenu(path) {
      this.$router.push('/report/' + path)
    },
    clickMenu(item) {
      switch (item) {
        case 'Гарах':
          this.signOut()
          break
        default:
          break
      }
    }
  }
}

</script>
<style scoped>
.item {
  margin-left: 10%;
}

.sidebar-header-title-text {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 400;
}
</style>
