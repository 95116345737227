<template>
  <div class="user_add">
    <el-row class="user_head_layout m-t-20">
      <el-col :span="6">
        <h3 class="m-l-25 m-b-25">Ажилтан нэмэх</h3>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="23" class="user_body m-l-20 p-20">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="220px" class="demo-ruleForm">
          <el-form-item label="Ажилтны овог" prop="last_name">
            <el-input v-model="ruleForm.last_name" clearable placeholder="Ажилтны овог оруулна уу"></el-input>
          </el-form-item>
          <el-form-item label="Ажилтны нэр" prop="name">
            <el-input v-model="ruleForm.name" clearable placeholder="Ажилтны нэр оруулна уу"></el-input>
          </el-form-item>
          <el-form-item label="Рд Дугаар" clearable prop="regno">
            <el-input v-model="ruleForm.regno" clearable placeholder="Регистрийн дугаар оруулна уу" minlength="10"
              maxlength="10" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="Албан тушаал" prop="position_id">
            <el-select v-model="ruleForm.position_id" placeholder="Хэрэглэгчийн төрөл">
              <el-option v-for="(roleItem,index) in this.positionList" :key="index" :label="roleItem.position_name"
                :value="roleItem.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Утас" prop="phone">
            <el-input v-model="ruleForm.phone" placeholder="Утас" type="text" minlength="8" maxlength="8" clearable
              show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="И-мэйл" prop="email">
            <el-input v-model="ruleForm.email" clearable placeholder="Та и-мэйл оруулна уу">
            </el-input>
          </el-form-item>
          <el-form-item v-if="this.is_password_show" label="Нууц үг" prop="password">
            <el-input v-model="ruleForm.password" placeholder="Та нууц үгээ оруулна уу" show-password clearable>
            </el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="Хүйс" v-model="ruleForm.gender">
                <el-radio-group v-model="ruleForm.gender" size="small">
                  <el-radio-button :label="1">Эр</el-radio-button>
                  <el-radio-button :label="2">Эм</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Веб дээр харагдах эсэх" v-model="ruleForm.gender">
                <el-radio-group v-model="ruleForm.is_show_web" size="small">
                  <el-radio-button :label="1">Тийм</el-radio-button>
                  <el-radio-button :label="0">Үгүй</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Зураг" prop="img_url">
            <Upload/>
          </el-form-item>
          <el-button type="success" class="button" @click="submitForm('ruleForm')">Үүсгэх</el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import services from '@/helpers/services.js'
import Upload from '@/components/uploadImage/dynamicUploadImage.vue'
import {
  notification, returnValidationErrorMessage
} from '@/helpers/custom.js'
export default {
  props: {},
  components: {
    Upload
  },
  created () {
    this.getPositionList()
    this.calculateMyData()
  },
  data () {
    return {
      token: JSON.parse(localStorage.getItem('idToken')),
      positionList: [],
      ruleForm: {
        name: '',
        last_name: '',
        gender: '',
        regno: '',
        phone: '',
        position_id: '',
        email: '',
        img_url: '',
        password: '',
        is_show_web: 0
      },
      is_upload_image: true,
      rules: {
        profile_img_url: [{
          required: true,
          message: 'Та  зурагаа уу',
          trigger: 'change '
        }],
        name: [{
          required: true,
          message: 'Та овог оруулна уу',
          trigger: 'change '
        }],
        last_name: [{
          required: true,
          message: 'Та нэрээ оруулна уу',
          trigger: 'change'
        }],
        regno: [{
          required: true,
          message: 'Та бөглөнө үү',
          trigger: 'change'
        },
        {
          min: 10,
          max: 10,
          message: 'Буруу РД дугаар байна',
          trigger: 'blur'
        }
        ],
        position_id: [{
          required: true,
          message: 'Та хэрэглэгчийн эрх сонгоно уу',
          trigger: 'change'
        }],
        phone: [{
          required: true,
          message: 'Та утасны дугаараа оруулна уу',
          trigger: 'change'
        }
        // ,
        // {
        //   min: 8,
        //   max: 8,
        //   message: 'Утасны дугаар байна',
        //   trigger: 'blur'
        // }
        ],
        gender: [{
          required: true,
          message: 'Та бөглөнө үү',
          trigger: 'change'
        }],
        email: [{
          required: true,
          message: 'Та и-мэйл бөглөнө үү',
          trigger: 'change'
        },
        {
          type: 'email',
          message: 'Та и-мэйл бөглөнө үү',
          trigger: ['blur', 'change']
        }
        ],
        password: [{
          required: true,
          message: 'Та нууц үгээ оруулна уу',
          trigger: 'change'
        }]
      },
      tableData: [],
      is_password_show: true
    }
  },

  methods: {
    calculateMyData () {
      if (this.$route.params.id !== undefined) {
        this.$root.fullscreenLoading = true
        this.is_password_show = false
        const jishee = {
          id: this.$route.params.id
        }
        services.getUserById(jishee)
          .then(response => {
            this.ruleForm = response.result
            this.$root.fullscreenLoading = false
          })
      } else {
        console.log('undefined bn')
      }
    },
    getPositionList () {
      services.getPosition(0)
        .then((response) => {
          this.positionList = response.data
        })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.img_url = this.$root.img_url
          services.storeUserInfo(this.ruleForm)
            .then(response => {
              this.$root.fullscreenLoading = true
              if (response.status === 'success') {
                notification('success_save')
                this.$router.push({
                  name: 'User'
                })
                this.$root.fullscreenLoading = false
                this.$root.img_url = null
              } else if (response.status === 'failed') {
                returnValidationErrorMessage(response)
                this.$root.fullscreenLoading = false
                this.$root.img_url = null
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}

</script>
