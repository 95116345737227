<template>
  <div class='home'>
    <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
      <el-col :span="8">
        <h3 class="m-l-25">Хичээлийн ирц</h3>
      </el-col>
    </el-row>
    <el-row class="m-l-30">
      <el-col :span="24" class="home_body p-20">
        <el-tabs type="border-card" @tab-click="changeQueue">
          <el-tab-pane label="1-р ээлж">
            <ag-grid-vue
                style="width: 100%; height: 100%;"
                :rowHeight="30"
                :defaultColDef="defaultColDef"
                class="ag-theme-alpine"
                :columnDefs="columnDefs"
                :rowData="rowData">
            </ag-grid-vue>
          </el-tab-pane>
          <el-tab-pane label="2-р ээлж">2-р ээлж</el-tab-pane>
          <el-tab-pane label="3-р ээлж">3-р ээлж</el-tab-pane>
          <el-tab-pane label="4-р ээлж">4-р ээлж</el-tab-pane>
          <el-tab-pane label="5-р ээлж">5-р ээлж</el-tab-pane>
          <el-tab-pane label="6-р ээлж">6-р ээлж</el-tab-pane>
          <el-tab-pane label="7-р ээлж">7-р ээлж</el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'

export default {
  name: 'LessonAttendance',
  components: {
    AgGridVue
  },
  data: () => {
    return {
      columnDefs: null,
      rowData: null,
      defaultColDef: {
        resizable: true
      }
    }
  },
  beforeMount () {
    this.columnDefs = [
      { headerName: 'Овог нэр', field: 'name', pinned: 'left', width: 180 },
      { headerName: 'Суух цаг', field: 'total', pinned: 'left', width: 90 },
      { headerName: 'Суусан цаг', field: 'progress', pinned: 'left', width: 110 },
      { headerName: '5/1', field: '5/1', width: 60, resizable: false },
      { headerName: '5/2', field: '5/2', width: 60, resizable: false },
      { headerName: '5/3', field: '5/3', width: 60, resizable: false },
      { headerName: '5/4', field: '5/4', width: 60, resizable: false },
      { headerName: '5/5', field: '5/5', width: 60, resizable: false },
      { headerName: '5/6', field: '5/6', width: 60, resizable: false },
      { headerName: '5/7', field: '5/7', width: 60, resizable: false },
      { headerName: '5/8', field: '5/8', width: 60, resizable: false },
      { headerName: '5/9', field: '5/9', width: 60, resizable: false }
    ]

    this.rowData = [
      {
        name: 'aaa',
        total: 14,
        progress: 2,
        '5/1': 'ирсэн',
        '5/2': 'ирсэн',
        '5/3': 'ирсэн',
        '5/4': 'ирсэн',
        '5/5': 'ирсэн',
        '5/6': 'ирсэн',
        '5/7': 'ирсэн',
        '5/8': 'ирсэн',
        '5/9': 'ирсэн'
      },
      {
        name: 'aaa',
        total: 14,
        progress: 2,
        '5/1': 'ирсэн',
        '5/2': 'ирсэн',
        '5/3': 'ирсэн',
        '5/4': 'ирсэн',
        '5/5': 'ирсэн',
        '5/6': 'ирсэн',
        '5/7': 'ирсэн',
        '5/8': 'ирсэн',
        '5/9': 'ирсэн'
      }
    ]
  },
  methods: {
    changeQueue: (tab) => {
      console.log(tab)
    }
  }
}
</script>

<style>
.ag-root-wrapper-body.ag-layout-normal{
  height: 100% !important;
}
</style>
