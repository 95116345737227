<template>
<div  class="error">
  <el-row class="error_bg_white">
    <el-col :span="20" :offset="2" class="auth p-150">
      <h1 class="authtitle">Уучлаарай таны эрх боломжгүй байна</h1>
       <el-image
       class="m-t-50 m-b-50"
      style="width: 150px; height: 150px"
      :src="imageUrl"
      fit="fill"></el-image><br/>
        <el-button class="button2" type="info" icon="el-icon-back" @click="submitForm('dynamicValidateForm')">
          Буцах</el-button>
    </el-col>
  </el-row>
</div>
</template>
<script>
import AuthErrorIcon from '@/assets/crying.png'
export default {
  data () {
    return {
      imageUrl: AuthErrorIcon

    }
  },
  methods: {

    submitForm () {
      this.$router.go(-1)
    }
  }
}

</script>
