<template>
  <div>
    <el-row type="flex" justify="space-between" class="page_head_zone">
      <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8">
        <h3 class="m-l-25">Сурагчийн жагсаалт</h3>
      </el-col><br />
    </el-row>
    <el-row type="flex" justify="space-between" class="page_head_zone">
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <h5 class="m-l-25">Хичээл</h5>
        <el-select size="mini" class="m-l-20 m-t-20" v-model="lesson" placeholder="Хичээл" clearable>
          <el-option v-for="item in lessons" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <h5 class="m-l-25">Ээлж</h5>
        <el-select size="mini" class="m-l-20 m-t-20" v-model="eelj" placeholder="Ээлж" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
        <h5 class="m-l-25">Өнөөдрөөс өмнө хэдэн өдрөөр татах</h5>
        <el-input-number size="mini" class="m-l-20 m-t-20" v-model="days" placeholder="Интервал" clearable>
        </el-input-number>
      </el-col>
      <el-col>
        <div class="m-t-35">
          <el-button size="mini" @click="studentExcelList()" type="success">Хайх</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-input v-model="search" size="mini" placeholder="Сурагчийн нэрээр хайх" />
      </el-col>
    </el-row>
    <el-row>
      <el-table :data="studentList.filter(data => !search || data.last_name.toLowerCase().includes(search.toLowerCase()))" size="mini" striped hover>
        <el-table-column type="index" fixed="left" label="№"></el-table-column>
        <el-table-column prop="name" label="Овог" width="150px" fixed="left">
        </el-table-column>
        <el-table-column prop="last_name" label="Нэр" width="150px"  fixed="left">
        </el-table-column>
        <el-table-column prop="grade" label="Анги" width="100px"  fixed="left">
        </el-table-column>
        <el-table-column prop="view_to_attendance" label="lesson_name" width="150px">
          <template slot-scope="scope" v-if="scope.row.view_to_attendance">
            {{ scope.row.view_to_attendance.lesson_name }}
          </template> 
        </el-table-column>
        <el-table-column prop="teacher_name" label="Багш" width="100px"  fixed="left"
        :filters="[{text: 'Болортуяа', value: 'Болортуяа'},
                    {text: 'Алтанзаяа', value: 'Алтанзаяа'}, 
                    {text: 'Хосбаяр', value: 'Хосбаяр'}, 
                    {text: 'Молор-Эрдэнэ', value: 'Молор-Эрдэнэ'}, 
                    {text: 'Болортуяа.ж', value: 'Болортуяа.ж'}]" :filter-method="filterHandler">
        </el-table-column>
        <el-table-column prop="student_note" label="Тэмдэглэл" width="150px">
          <template slot-scope="scope" v-if="scope.row.student_note">
            {{ scope.row.student_note.description }}
          </template> 
        </el-table-column>
        <el-table-column prop="student_time" label="Суух цаг" width="100px">
          <template slot-scope="scope">
            <el-tag type="primary" size="mini" v-if="scope.row.remaining_time <= 20">
              {{ scope.row.student_time }}
              </el-tag>
              <el-tag type="warning" size="mini" v-else-if="scope.row.student_time == 360">
              жил
              </el-tag>
              <el-tag type="info" size="mini" v-else>
                {{ scope.row.student_time }}
              </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="school" label="Сургууль" width="150px">
        </el-table-column>
        <el-table-column prop="remaining_time" label="Үлдсэн цаг" width="100px">
          <template slot-scope="scope">
            <el-tag type="primary" size="mini" v-if="scope.row.remaining_time <= 20">
              {{ scope.row.remaining_time }}
              </el-tag>
              <el-tag type="warning" size="mini" v-else-if="scope.row.student_time == 360">
              жил
              </el-tag>
              <el-tag type="info" size="mini" v-else>
                {{ scope.row.remaining_time }}
              </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Шилжүүлэх" width="100px" prop="students.length">
          <template slot-scope="scope">
            <el-button size="mini" icon="el-icon-user-solid" type="primary" @click="studentTransactionDialog(scope.row)"></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="eelj" label="Ээлж" width="100px">
        </el-table-column>
        <el-table-column :label="item" width="150px" v-for="item, index in getWorkingDays()" :key="index">
          <template slot-scope="scope">
            <div v-for="i, index in scope.row.view_to_attendance" :key="index">
              <template v-if="i.created_at.split('T')[0] === item">
                <el-tag size="mini" v-if="i.status_id == 100" type="success">И</el-tag>
                <el-tag size="mini" v-if="i.status_id == 101" type="danger">Т</el-tag>
                <el-tag size="mini" v-if="i.status_id == 102" type="primary">Ч</el-tag>
                <el-tag size="mini" v-if="i.status_id == 103" type="warning">Ө</el-tag>
              </template>
              <template v-if="i.created_at.split('T')[0] === item">
                <el-tag v-if="i.status_id == 100" size="mini" type="warning">{{ i.percent }}</el-tag>
              </template>
              <template v-if="i.created_at.split('T')[0] === item">
                <el-tag v-if="i.status_id == 100" size="mini" type="primary">{{ i.late_minute }}</el-tag>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="id" width="150px">
        </el-table-column>
      </el-table>
    </el-row>
    <el-dialog title="Сурагч шилжүүлэх" :visible.sync="this.$root.studentTransactionDialog" :before-close="handleClose">
      <el-form>
        <el-form-item label="Хичээллийн ээлж"><br>
          <el-radio-group v-model="studentEelj">
            <el-radio-button v-for="item in optionsTwo" :key="item.value" :label="item.label"> {{ item.label }} -р ээлж</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <!-- ab4572 -->
        <el-row>Шилжүүлэх багш сонгох</el-row><br>
        <el-form-item>
          <el-select v-model="teacher_name" placeholder="Сонгох">
            <el-option
              v-for="item,index in teacherList"
              :key="index"
              :label="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span class="dialog-footer">
        <el-button type="success" @click="changeStudentSchedule()">Хадгалах</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import moment from 'moment'
  import services from '@/helpers/services.js'
  export default {
    data() {
      return {
        studentList: [],
        now_date: new Date().toJSON(),
        dates: ['2024-08-28'],
        sortedDays: [],
        days:30,
        eelj:1,
        selectedStudent:{
          student_id: 0,
          name:'',
          last_name: '',
          schedule_id: 0,
          student_id: 0,
          lesson_name: ''
        },
        lesson:'mat',
        search: '',
        studentEelj:null,
        teacher_name:null,
        options: [{
          value: 1,
          label: '1-р ээлж'
        }, {
          value: 2,
          label: '2-р ээлж'
        }, {
          value: 3,
          label: '3-р ээлж'
        }, {
          value: 4,
          label: '4-р ээлж'
        }, {
          value: 5,
          label: '5-р ээлж'
        }, {
          value: 6,
          label: '6-р ээлж'
        }, {
          value: 7,
          label: '7-р ээлж'
        }],
        lessons: [{
          value: 'eng',
          label: 'Англи хэл'
        }, {
          value: 'mat',
          label: 'Математик'
        }],
        optionsTwo: [{
          label: 1
        }, {
          label: 2
        }, {
          label: 3
        }, {
          label: 4
        }, {
          label: 5
        }, {
          label: 6
        }, {
          label: 7
        }],
        teacher: '',
        teacherList: []
      }
    },
    created() {
      this.studentExcelList(),
      this.getMatTeachersList()
    },
    methods: {
      getWorkingDays() {
        const workingDays = [];
        const today = moment();

        for (let i = 0; i <= this.days; i++) {
          const date = today.clone().subtract(i, 'days');
          if (date.isoWeekday() < 6) {
            workingDays.push(date.format('YYYY-MM-DD'));
          }
        }
        return workingDays.reverse();
      },
      studentExcelList() {
        this.$root.fullscreenLoading = true;
        var payload = {
          eelj: this.eelj,
          lesson:this.lesson
        }
        services.viewTestService(payload)
          .then((response) => {
            this.studentList = response.result
            this.$root.fullscreenLoading = false;
          })
      },
      filterHandler (value, row, column) {
            const property = column.property
            return row[property] === value
      },
      studentTransactionDialog (data) {
      this.selectedStudent = data
      this.studentEelj = data.eelj
      this.teacher_name = data.teacher_name
      this.$root.studentTransactionDialog = true
      },
      handleClose () {
        this.$root.studentTransactionDialog = false
        this.selectedStudent.name = ''
      },
      getMatTeachersList () {
      services.getMatTeachers()
        .then((response) => {
          this.teacherList = response.result
          this.loading = false
        })
      },
      changeStudentSchedule () {
      this.$root.fullscreenLoading = true
      var teacher = this.teacherList.find((element) => element.name == this.teacher_name)
      const payload = {
        schedule_id: this.selectedStudent.schedule_id,
        student_id: this.selectedStudent.id,
        lesson_name: this.selectedStudent.lesson_name,
        teacher_id: teacher.id,
        eelj: this.studentEelj
      }
      services.changeStudentSchedule(payload)
        .then((response) => {
          if (response.result === 'success') {
            this.$root.fullscreenLoading = false
          } else if (response.result === 'unSuccess') {
            returnValidationErrorMessage(response)
            this.$root.fullscreenLoading = false
          }
          // console.log(response)
        })
      },
    }
  }

</script>

<style>
.class_col{
  background-color: antiquewhite !important;
}
</style>
