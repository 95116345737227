<template>
  <el-dialog title="Илгээгдэх сурагчдийн жагсаалт" :visible.sync="this.$root.sendSmsResultDialog" width="50%"
    :before-close="handleClose1">
    <span>Илгээгдэх текст жишээ: </span>
    <div class="sms_text_zone">
    <span class="sendSmsTestTitleStyle p-t-40">{{sendSmsResultUserList[0].value.name}} {{ selectedSmsTemplate.sms_text }} {{sendSmsResultUserList[0].value.custom_text}}</span>

    </div>
    <div class="m-t-20">
      <span>Илгээгдэх дугаарын жагсаалт: </span>
      <el-select v-model="sendSmsResultUserThisPage" multiple placeholder="Select" class="w-100">
        <el-option v-for="item, index in sendSmsResultUserList" :key="index" :label="item.value.name + '-' + item.value.phone"
          :value="item.index">
        </el-option>
      </el-select>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="success" @click="sendSms()">Илгээх</el-button>
    </span>
  </el-dialog>
</template>
<script>
import services from '@/helpers/services.js'
export default {
  props: {
    sendSmsResultUserList: Array,
    selectedSmsTemplate: Object
  },
  data () {
    return {
      templateIsCheck: false,
      sendSmsFormDialog: this.sendSmsForm,
      smsData: [],
      sendSmsResultUserThisPage: [],
      handleSelect (item) {
        console.log(item)
      }
    }
  },
  created () {
    this.getList()
    this.setData()
  },
  methods: {
    setData () {
      this.sendSmsResultUserList.forEach(element => {
        this.sendSmsResultUserThisPage.push(element.index)
      })
    },
    getList () {
      services.getSmsList()
        .then((response) => {
          this.smsData = response.data
        })
    },
    handleClose1 () {
      this.$root.sendSmsResultDialog = false
    },
    sendSms () {
      this.sendSmsResultUserThisPage.forEach(element => {
        this.sendSmsResultUserList.forEach(e => {
          if (element === e.index) {
            const payload = {
              to: e.value.phone,
              text: e.value.name + ' ' + this.selectedSmsTemplate.sms_text + ' ' + e.value.custom_text
            }
            services.sendSms(payload)
              .then((response) => {
                if (response[0].Result === 'SUCCESS') {
                  this.$notify({
                    title: 'Амжилттай',
                    message: payload.to + ' дугаарлуу амжилттай илгээгдлээ',
                    type: 'success'
                  })
                  this.handleClose1()
                } else {
                  this.$notify.error({
                    title: 'Уучлаарай',
                    message: 'Алдаа гарлаа инженертэй холбогдоно уу'
                  })
                  this.handleClose1()
                }
              })
          }
        })
      })
    }
  }
}

</script>
