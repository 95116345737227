<template>
    <el-dialog title="МСЖ илгээх" :visible.sync="this.$root.sendCustomNumberSmsDialog" width="80%"
        :before-close="handleClose1">
        <el-form :model="sendSmsFormDialog" label-width="120px" label-position="top"
          class="demo-ruleForm">
          <el-form-item label="Утасны дугаар">
            <el-input-number class="w-100" v-model="sendSmsFormDialog.to" placeholder="Утасны дугаар" :controls="false"></el-input-number>
          </el-form-item>
          <el-form-item label="Өмнөх загвараас сонгох">
    <el-switch v-model="templateIsCheck"></el-switch>
  </el-form-item>
          <el-form-item v-if="!templateIsCheck" label="Мсж текст">
            <el-input  type="textarea" class="w-100" v-model="sendSmsFormDialog.text" placeholder="МСЖ текст"   :autosize="{ minRows: 4, maxRows: 4}"            ></el-input>
          </el-form-item>
          <el-form-item v-if="templateIsCheck" label="Мсж текст">
            <el-select class="w-100" v-model="sendSmsFormDialog.text" clearable placeholder="Мсж текст">
    <el-option
      v-for="(item, index) in this.smsData"
      :key="index"
      :label="item.sms_text"
      :value="item.sms_text">
    </el-option>
  </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="sendSms()">Илгээх</el-button>
        </span>
      </el-dialog>
</template>
<script>
import services from '@/helpers/services.js'
import {
  notification
} from '@/helpers/custom'
export default {
  props: {
    sendSmsForm: Object
  },
  data () {
    return {
      templateIsCheck: false,
      sendSmsFormDialog: this.sendSmsForm,
      smsData: [],
      handleSelect (item) {
        console.log(item)
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      services.getSmsList()
        .then((response) => {
          this.smsData = response.data
        })
    },
    handleClose1 () {
      this.$root.sendCustomNumberSmsDialog = false
      this.sendSmsFormDialog.to = null
      this.sendSmsFormDialog.text = ''
    },
    sendSms () {
      console.log(this.sendSmsFormDialog, 'ss')
      if (this.sendSmsFormDialog.to === 0 || this.sendSmsFormDialog.text === '') {
        this.$notify.error({
          title: 'Error',
          message: 'Алдаа утасны дугаар хоосон байна.'
        })
      } else {
        this.$root.fullscreenLoading = true
        services.sendSms(this.sendSmsForm)
          .then((response) => {
            if (response[0].Result === 'SUCCESS') {
              notification('success_empty')
              this.$root.fullscreenLoading = false
              this.handleClose1()
            } else {
              this.$notify.error({
                title: 'Уучлаарай',
                message: 'Алдаа гарлаа инженертэй холбогдоно уу'
              })
              this.$root.fullscreenLoading = false
            }
          })
      }
    }
  }
}
</script>
