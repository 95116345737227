<template>
  <el-row class="login">
    <el-col class="logo" :span="12">
      <!-- <div class="grid-content bg-purple"> -->
      <div class="login_text_div">
        <div class="title1">TENOX</div>
        <div class="tseg">.</div>
        <h3 class="title2">Шатахуун түгээх станцын цогц систем</h3>
      </div>
      <!-- </div> -->
    </el-col>
    <el-col class="bg-purple-light" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <div>
        <div class="reguser">
          <el-row>
            <el-col>
              <h1 class="regusertitle">Бүртгүүлэх</h1><br>
              <h3 class="reguserliltitle">Баталгаажуулсаны дараа нэвтрэнэ үү</h3>
            </el-col>
          </el-row>
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm">
            <el-form-item class="m-t-20" prop="email" :rules="[
            { required: true, message: 'Таны и-мэйл хаяг дутуу байна', trigger: 'blur' },
            { type: 'email', message: 'Та и-мэйл оруулна уу', trigger: ['blur', 'change'] }]">
              <el-input v-model="ruleForm.email" clearable placeholder="Та нэвтрэх и-мэйл ээ оруулна уу">
                <i slot="prefix" class="el-input__icon el-icon-user"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="pass">
              <el-input type="password" v-model="ruleForm.pass" autocomplete="off" placeholder="Нууц үг оруулна уу">
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
              <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"
                placeholder="Нууц үг давтан оруулна уу">
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="reguserbuttonsub" type="primary" @click="submitForm('dynamicValidateForm')">Бүртгүүлэх
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button class="reguserbuttonhome" type="info" @click="butsah('dynamicValidateForm')">Буцах</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Та нууц үгээ оруулна уу'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Та нууц үгээ давтан оруулна уу'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('Нууц үг буруу байна'))
      } else {
        callback()
      }
    }

    return {
      ruleForm: {
        email: '',
        pass: '',
        checkPass: ''
      },
      rules: {
        pass: [{
          validator: validatePass,
          trigger: 'blur'
        }],
        checkPass: [{
          validator: validatePass2,
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    butsah () {
      this.$router.push({
        name: 'LoginPage'
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}

</script>
