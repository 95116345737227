<template>
    <div class="home">
      <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
        <el-col :span="8">
          <h3 class="m-l-25">Мсж илгээсэн түүх</h3>
        </el-col>
      </el-row>
      <el-row class="m-l-30">
        <el-col :span="23" class="home_body p-20">
          <el-table v-loading="loading" :data="historyList" stripe style="width: 100%" height="550" size="mini">
            <el-table-column prop="created_at" label="Илгээсэн огноо">
              <template slot-scope="scope">
                {{dateFormatFunctionPage(scope.row.created_at)}}
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="Утас">
            </el-table-column>
            <el-table-column prop="sms_text" label="Мсж текст">
            </el-table-column>
            <el-table-column prop="sms_created_user.name" label="Илгээсэн ажилтан">
            </el-table-column>
            <el-table-column prop="status" label="Төлөв">
              <template slot-scope="scope">
                 <el-tag  type="success" size="mini">{{JSON.parse(scope.row.status)}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="operator" label="Оператор">
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </template>

<script>
import services from '@/helpers/services.js'
import { dateFormatFunction } from '@/helpers/custom'
// import axios from 'axios'
export default {
  name: 'SmsHistory',
  data () {
    return {
      historyList: [],
      laoding: false
    }
  },
  created () {
    this.getHistoryList()
  },
  methods: {
    dateFormatFunctionPage (date) {
      return dateFormatFunction(date)
    },
    getHistoryList () {
      this.loading = true
      services.getSmsHistoryList()
        .then((response) => {
          this.historyList = response.data
          this.loading = false
        })
    }
  }
}

</script>
