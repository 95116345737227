<template>
  <div class="home">
    <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
      <el-col :span="8">
        <h3 class="m-l-25">Түгээмэл асуулт хариулт жагсаалт</h3>
      </el-col>
      <el-col :span="3" class="user_head_add_button m-r-25">
        <el-button size="mini" type="success" @click="addQuestionDialog()">+ Асуулт, хариулт үүсгэх</el-button>
      </el-col>
    </el-row>
    <el-row class="m-l-30">
      <el-col :span="23" class="home_body p-20">
        <el-table v-loading="loading" :data="tabledata" stripe style="width: 100%" height="550">
          <el-table-column type="index" width="50">
          </el-table-column>
          <el-table-column prop="question" label="Асуулт" width="300px">
          </el-table-column>
              <el-table-column prop="is_active" label="Төлөв" width="100px">
            <template slot-scope="scope">
              <el-switch @change="changeSwitchQuestion(scope.row.id, scope.row.is_active, scope.row.question)" v-model="scope.row.is_active" active-color="#13ce66" inactive-color="gray" :active-value="1"
                :inactive-value="0">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="answer" label="Хариулт">
          </el-table-column>
          <el-table-column label="Үйлдэл" width="120px">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="editBlogs(scope.row)" circle>
              </el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteBlogs(scope.row)" circle>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-dialog title="Блог үүсгэх" :visible.sync="this.$root.createQuestionDialog" width="50%"
      :before-close="handleClose">
      <el-form :model="createQuestionDialog" :rules="rules" ref="ruleForm" label-width="120px" label-position="top"
        class="demo-ruleForm">
        <el-form-item label="Асуулт" prop="question">
          <el-input v-model="createQuestionDialog.question" placeholder="Асуулт"></el-input>
        </el-form-item>
        <el-form-item label="Хариулт" prop="answer">
          <el-input v-model="createQuestionDialog.answer" type="textarea" placeholder="Хариулт"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="createQuestion('ruleForm')">Үүсгэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import {
  notification
} from '@/helpers/custom'
export default {
  data () {
    return {
      rules: {
        question: [{
          required: true,
          message: 'Асуулт оруулна уу',
          trigger: 'blur'
        }],
        answer: [{
          required: true,
          message: 'Хариулт оруулна уу',
          trigger: 'blur'
        }]
      },
      createQuestionDialog: {
        question: '',
        answer: '',
        is_active: 1
      },
      tabledata: [],
      loading: true
    }
  },
  created () {
    this.getList()
  },
  methods: {
    deleteBlogs (data) {
      this.$confirm(data.question + ' нэртэй албан тушаалыг усгах гэж байна итгэлтэй байна уу.', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const payload = {
          id: data.id
        }
        services.deleteFrequentlyQuestions(payload)
          .then((response) => {
            if (response.status === 'success') {
              this.$root.createQuestionDialog = false
              notification('success_deleted')
              this.$root.fullscreenLoading = false
              location.reload()
            }
            // console.log(response)
          })
      }).catch(() => {})
    },
    createQuestion (formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          services.createFrequentlyQuestions(this.createQuestionDialog)
            .then((response) => {
              if (response.status === 'success') {
                this.$root.createQuestionDialog = false
                notification('success_position')
                this.$root.fullscreenLoading = false
                location.reload()
              } else if (response.status === 'unSuccess') {
                this.$root.fullscreenLoading = false
              }
              // console.log(response)
            })
        }
      })
    },
    changeSwitchQuestion (id, newStatus, name) {
      this.$confirm(name + ' асуултын өөрчлөхдөө итгэлтэй бнуу?', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Үгүй',
        type: 'warning'
      }).then(() => {
        const payload = {
          id: id,
          status: newStatus
        }
        services.statusChangeFrequently(payload)
          .then((response) => {
            if (response.status === 'success') {
              notification('success_position')
              this.$root.fullscreenLoading = false
              this.getList()
            }
            // console.log(response)
          })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },
    handleClose () {
      this.$root.createQuestionDialog = false
    },
    editBlogs (data) {
      this.createQuestionDialog = data
      this.$root.createQuestionDialog = true
    },
    addQuestionDialog () {
      this.createQuestionDialog.question = ''
      this.createQuestionDialog.answer = ''
      this.$root.createQuestionDialog = true
    },
    getList () {
      services.getFrequentlyQuestion()
        .then((response) => {
          this.tabledata = response.result
          this.loading = false
        })
    }
  }
}

</script>
