<template>
  <div class="home">
    <div class="home_body p-10">
      <el-row :gutter="10" class="m-b-30">
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-card shadow="never" class="box-card four carousel-card">
            <el-row>
              <el-col>
                <span class="dashboard-box-card-title">Нийт төлөх төлбөр</span>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="m-t-10">
              <el-col :span="6">
                <i class="dashboard_icon el-icon-money"></i>
              </el-col>
              <el-col :span="6">
                <span class="dashboard-box-card-count">{{ dashboardData.niitTuluhTulbur | formatCurrency }}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-card shadow="never" class="box-card five carousel-card">
            <el-row>
              <el-col>
                <span class="dashboard-box-card-title">Төлсөн төлбөр</span>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="m-t-10">
              <el-col :span="6">
                <i class="dashboard_icon el-icon-money"></i>
              </el-col>
              <el-col :span="6">
                <span class="dashboard-box-card-count">{{ dashboardData.tulsunTulbur | formatCurrency }}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-card shadow="never" class="box-card five carousel-card">
            <el-row>
              <el-col>
                <span class="dashboard-box-card-title">Төлөх төлбөр</span>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="m-t-10">
              <el-col :span="6">
                <i class="dashboard_icon el-icon-money"></i>
              </el-col>
              <el-col :span="6">
                <span class="dashboard-box-card-count">{{ dashboardData.tuluhTulbur | formatCurrency }}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="m-t-20">
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-card shadow="never" class="box-card one carousel-card ">
            <el-row>
              <el-col>
                <span class="dashboard-box-card-title">Нийт бүртгэлтэй</span>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="m-t-10">
              <el-col :span="6">
                <i class="dashboard_icon el-icon-circle-plus-outline"></i>
              </el-col>
              <el-col :span="6">
                <span class="dashboard-box-card-count">{{ returnTotalCount() }}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-card shadow="never" class="box-card two carousel-card">
            <el-row>
              <el-col>
                <span class="dashboard-box-card-title">Хүлээгдэж байгаа</span>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="m-t-10">
              <el-col :span="6">
                <i class="dashboard_icon el-icon-loading"></i>
              </el-col>
              <el-col :span="6">
                <span class="dashboard-box-card-count">{{ dashboardData.pendingCount }}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-card shadow="never" class="box-card two carousel-card">
            <el-row>
              <el-col>
                <span class="dashboard-box-card-title">Гэрээ хийгдсэн</span>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="m-t-10">
              <el-col :span="6">
                <i class="dashboard_icon el-icon-circle-check"></i>
              </el-col>
              <el-col :span="6">
                <span class="dashboard-box-card-count">{{ dashboardData.contractCount }}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-card shadow="never" class="box-card three carousel-card">
            <el-row>
              <el-col>
                <span class="dashboard-box-card-title">Суралцаж байгаа</span>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="m-t-10">
              <el-col :span="6">
                <i class="dashboard_icon el-icon-star-off"></i>
              </el-col>
              <el-col :span="6">
                <span class="dashboard-box-card-count">{{ dashboardData.studyCount }}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>

      <el-row :gutter="10" class="m-t-20">
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-card shadow="never" class="box-card four carousel-card">
            <el-row>
              <el-col>
                <span class="dashboard-box-card-title">Төлбөр төлөгдсөн</span>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="m-t-10">
              <el-col :span="6">
                <i class="dashboard_icon el-icon-shopping-bag-2"></i>
              </el-col>
              <el-col :span="6">
                <span class="dashboard-box-card-count">{{ dashboardData.payCount }}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-card shadow="never" class="box-card five carousel-card">
            <el-row>
              <el-col>
                <span class="dashboard-box-card-title">Цаг дууссан</span>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="m-t-10">
              <el-col :span="6">
                <i class="dashboard_icon el-icon-time"></i>
              </el-col>
              <el-col :span="6">
                <span class="dashboard-box-card-count">{{ dashboardData.endTimeCount }}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-card shadow="never" class="box-card five carousel-card">
            <el-row>
              <el-col>
                <span class="dashboard-box-card-title">Цуцлагдсан</span>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="m-t-10">
              <el-col :span="6">
                <i class="dashboard_icon el-icon-warning-outline"></i>
              </el-col>
              <el-col :span="6">
                <span class="dashboard-box-card-count">{{ dashboardData.cancelCount }}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
          <el-card shadow="never" class="box-card five carousel-card">
            <el-row>
              <el-col>
                <span class="dashboard-box-card-title">Нийт ажилчид</span>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="m-t-10">
              <el-col :span="6">
                <i class="dashboard_icon el-icon-warning-outline"></i>
              </el-col>
              <el-col :span="6">
                <span class="dashboard-box-card-count">{{ dashboardData.amountEmployee }}</span>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
        <el-row :gutter="10">
                        <span class="demonstration m-t-40 home_title_text m-t-20">Хамрах хугацааг сонгож өгнө үү</span> <br />
          <el-col :span="5">
              <el-date-picker class="m-t-20 w-100 m-l-20" v-model="start_date" type="date" placeholder="Эхлэх огноо"
                default-value="2010-10-01">
              </el-date-picker>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-date-picker class="m-t-20 w-100" v-model="end_date" type="date" placeholder="Дуусах огноо"
              default-value="2010-10-01">
            </el-date-picker>
          </el-col>
          <el-col :span="4">
            <span></span><br/>
                        <el-button class="m-l-40" type="success">Хайх</el-button> 
          </el-col>
        </el-row>
      </el-row>
      <el-row :gutter="10" class="m-t-20">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card shadow="never" class="box-card five carousel-card">
            <h5>Хичээлүүдийн хамаарлаар саруудад элссэн хүүхдийн тоо</h5>
            <div class="m-t-20">
              <BarChartComponent />
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card shadow="never" class="box-card five carousel-card">
            <LineChartComponent />
          </el-card>
        </el-col>
      </el-row>
      <div>
      </div>
    </div>
  </div>
</template>
<script>
  // import Upload from '@/components/uploadImage/dynamicUploadImage.vue'
  import BarChartComponent from '@/components/chart/bar.vue'
  import LineChartComponent from '@/components/chart/line.vue'
  import services from '@/helpers/services.js'
  export default {
    name: 'countHuleegdejBaigaaSt',
    data() {
      return {
        start_date: null,
        end_date: null,
        dashboardData: {
          totalCount: 0,
          pendingCount: 0,
          contractCount: 0,
          studyCount: 0,
          payCount: 0,
          endTimeCount: 0,
          cancelCount: 0,
          amountEmployee: 0,
          niitTuluhTulbur: 0,
          tulsunTulbur: 0,
          tuluhTulbur: 0
        }
      }
    },
    components: {
      // Upload
      BarChartComponent,
      LineChartComponent
    },
    created() {
      this.getUserGroupByStatus()
      this.getPaymentPay()
    },

    methods: {
      returnTotalCount() {
        return (this.dashboardData.pendingCount + this.dashboardData.contractCount + this.dashboardData.studyCount +
          this.dashboardData.payCount + this.dashboardData.endTimeCount + this.dashboardData.cancelCount)
      },
      getUserGroupByStatus() {
        services.getUserGroupByStatus()
          .then((response) => {
            response.data.forEach(element => {
              if (element.user_status === 10) {
                this.dashboardData.pendingCount = this.dashboardData.pendingCount + element.count
              } else if (element.user_status === 60) {
                this.dashboardData.contractCount = this.dashboardData.contractCount + element.count
              } else if (element.user_status === 80) {
                this.dashboardData.studyCount = this.dashboardData.studyCount + element.count
              } else if (element.user_status === 70) {
                this.dashboardData.payCount = this.dashboardData.payCount + element.count
              } else if (element.user_status === 50) {
                this.dashboardData.endTimeCount = this.dashboardData.endTimeCount + element.count
              } else if (element.user_status === 30) {
                this.dashboardData.cancelCount = this.dashboardData.cancelCount + element.count
              } else if (element.user_status === 90) {
                this.dashboardData.amountEmployee = this.dashboardData.amountEmployee + element.count
              }
            })
            this.loading = false
          })
      },
      getPaymentPay() {
        services.getPaymentPay()
          .then((response) => {
            response.data.forEach(element => {
              this.dashboardData.niitTuluhTulbur = this.dashboardData.niitTuluhTulbur + element.niitTuluhTulbur
              this.dashboardData.tulsunTulbur = this.dashboardData.tulsunTulbur + element.tulsunTulbur
              this.dashboardData.tuluhTulbur = this.dashboardData.tuluhTulbur + element.tuluhTulbur
            })
            this.loading = false
          })
      }
    }
  }

</script>
