<template>
  <div class="user_list">
    <el-row class="user_head_layout m-t-20">
      <el-col :span="6">
        <h3 class="m-l-25">Ажилтны жагсаалт</h3>
      </el-col>
      <el-col :span="3" class="user_head_add_button m-r-5">
        <el-button size="mini" type="success" @click="goToAddUser()">+ Ажилтан нэмэх</el-button>
      </el-col>
    </el-row>
    <el-row class="m-t-20">
      <el-col :span="23" class="user_body m-l-20 p-20">
        <el-table size="mini" :data="tabledata" style="width: 100%" v-loading="loading">
          <el-table-column type="index" width="50">
          </el-table-column>
          <el-table-column prop="name" label="Нэр">
          </el-table-column>
          <el-table-column prop="last_name" label="Овог">
          </el-table-column>
          <el-table-column prop="profile_img_url" label="Зураг" width="100">
            <template slot-scope="scope">
              <el-image style="width: 50px; height: 50px; border-radius:10px"
                :src="'https://image.dma.mn/'+ scope.row.img_url" fit="fill">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="gender" label="Хүйс" width="80">
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.gender === 1">Эр
              </el-tag>
              <el-tag type="primary" v-if="scope.row.gender === 2">Эм
              </el-tag>
              <span style="color:#eee"  v-if="scope.row.gender === null">Хоосон
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="position_id" label="Албан тушаал">
            <template slot-scope="scope">
              <el-tag size="mini" type="warning" v-if="scope.row.position !== null"> {{scope.row.position.position_name}}</el-tag>
              <span style="font-weight:300; color:#ccc" v-if="scope.row.position === null"> Холбогдоогүй</span>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="Утас" width="100">
            <template slot-scope="scope">
              <el-tag size="mini" v-if="scope.row.phone !== null ">{{ scope.row.phone }}</el-tag>
            <span style="color:#eee" v-if="scope.row.phone === null">Хоосон</span>
            </template>
          </el-table-column>
          <el-table-column prop="email" label="И-мэйл">
          </el-table-column>
          <!-- <el-table-column prop="is_show_web" label="Веб сайтад харагдах эсэх">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_show_web" active-color="#13ce66" :active-value="1"
                :inactive-value="0">
              </el-switch>
            </template>
          </el-table-column> -->
          <el-table-column fixed="right" width="130px" label="Үйлдэл">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleEdit(scope.row)">
              </el-button>
              <!-- <el-button v-loading="loading" type="info" size="mini" @click="viewDetail(scope.row)  ">Дэлгэрэнгүй
              </el-button> -->
              <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="handleDelete(scope.row)">
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import {
  notification
} from '@/helpers/custom.js'
export default {

  data () {
    return {
      tabledata: [],
      selectedUserInfo: {},
      viewDetailViewDialog: false,
      loading: true,
      roleList: []
    }
  },
  created () {
    // this.getData()
    this.getView()
  },
  methods: {
    goToAddUser () {
      this.$router.push({
        path: '/userAdd'
      })
    },
    getView () {
      const payload = {
        position_type: 0
      }
      services.getTeacherList(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.tabledata = response.result
            this.loading = false
          }
        })
    },
    handleEdit (row) {
      this.$router.push({
        name: 'Useradd',
        params: {
          id: row.id
        }
      })
    },
    handleDelete (row) {
      this.$confirm(row.name + ' хэрэглэгчийн мэдээллийг устгахдаа итгэлтэй байна уу?', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const del = {
          id: row.id
        }
        services.deleteUser(del)
          .then((response) => {
            if (response.status === 'success') {
              notification('delete_user_notification')
              // this.getData()
              location.reload()
              this.$root.fullscreenLoading = false
            } else if (response.status === 'unSuccess') {
              notification('delete_error_user')
              this.$root.fullscreenLoading = false
            } else {
              // console.log('error')
            }
          })
      })
    }
  }
}

</script>

<style>

</style>
