<template>
    <el-upload name="file" class="avatar-uploader" :show-file-list="true"
          :headers="{'Authorization':'Bearer '+ token }" action="https://rest.dma.mn/api/uploadImage"
          :on-preview="handlePreview" :on-change="success" :accept="acceptType()" :on-remove="handleRemove"
          :before-remove="beforeRemove" :limit="this.fileLimit" :on-exceed="handleExceed" :file-list="fileListAnswer">
          <img v-if="this.$root.img_url" :src="'https://image.dma.mn/' + this.$root.img_url"
            class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
</template>

<script>
import services from '@/helpers/services.js'
export default {
  data () {
    return {
      token: JSON.parse(localStorage.getItem('idToken')),
      disabled: false,
      fileListAnswer: [],
      fileList: [],
      isShow: false,
      fileTitle: '',
      fileTitleAnswer: 'Та хариултын файлыг хуулна уу',
      // centerDialogVisible:false
      fileLimit: 1
    }
  },
  methods: {
    success (file, fileList) {
      if (file.response) {
        this.$root.img_url = file.response.path
      }
      setTimeout(() => {
        this.isShow = true
      }, 10000)
    },
    acceptType (index) {
      return 'image/png, image/jpeg'
    },
    handleRemove (file, fileList) {
      // eslint-disable-next-line no-prototype-builtins
      if (!file.hasOwnProperty('response')) {
        let response = file.url.split('?')[0]
        response = response.split('.com/')[1]
        file.response = response
      }
      services.removeImage(file)
        .then((response) => {
          this.fileList = fileList
          this.$root.img_url = null
          this.isShow = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`Сонгосон  ${file.name}  файлыг хавсаргасан файлын жагсаалтаас хасах уу?`)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(
          `Файл тоо хэтэрсэн байна. ${
                    files.length
                } файл хуулсан байна. ${
                    this.fileLimit
                } хэтрэхгүй файл хуулна уу.`
      )
    },
    handleUploadVariantfulImages (file, fileList) {
      this.fileList = fileList
    }
  }
}

</script>

<style>

</style>
