<template>
    <div class="home">
      <el-row>
          <el-col>
            <el-row type="flex" class="row-bg m-t-20" justify="center">
                <el-col v-for="(item, index)  in 7" :key="index" >
                    <el-tag shadow="always" type="primary" class="p-10 m-b-10">
                    {{(index+1) + "-р ээлж " + statusCounter(index+1)}}
                    </el-tag>
                </el-col>
            </el-row>
            <el-row type="flex" class="row-bg m-t-20" justify="center">
                <el-col v-for="(item, index)  in 7" :key="index" >
                    <el-tag shadow="always" type="success" class="p-10 m-b-10">
                    {{(index+1) + " : " + teacherCounter(index+1)}}
                    </el-tag>
                </el-col>
            </el-row>
              <el-input v-model="search" size="mini" placeholder="Сурагчийн нэрээр хайх" />
              <el-table :data="this.passUserListData.filter(data => !search || data.ner.toLowerCase().includes(search.toLowerCase()))">
                  <el-table-column type="index" width="80">
                  </el-table-column>
                  <el-table-column label="Багшийн нэр" prop="bagshiin_ner" width="140px" :filters="[{text: 'Намуун', value: 'Намуун'}, {text: 'Алтанзаяа', value: 'Алтанзаяа'},
                        {text: 'Болортуяа', value: 'Болортуяа'}, {text: 'Молор-Эрдэнэ', value: 'Молор-Эрдэнэ'}]"
                  :filter-method="filterHandler">
                </el-table-column>
                  <el-table-column label="Ээлж" prop="eelj" :filters="[{text: '1-р ээлж', value: 1},
                    {text: '2-р ээлж', value: 2}, {text: '3-р ээлж', value: 3}, {text: '4-р ээлж', value: 4}, {text: '5-р ээлж', value: 5},
                    {text: '6-р ээлж', value: 6}, {text: '7-р ээлж', value: 7}]" :filter-method="filterHandler">
                  </el-table-column>
                  <el-table-column label="Овог" prop="ovog" width="145px">
                  </el-table-column>
                  <el-table-column label="Нэр" prop="ner" width="140px">
                  </el-table-column>
                  <el-table-column label="Анги" prop="grade" width="100px"
                  :filters="[{text: '1-р анги', value: 1},
                    {text: '2-р анги', value: '2-р анги'}, {text: '3-р анги', value: '3-р анги'}, {text: '4-р анги', value: '4-р анги'}, 
                    {text: '5-р анги', value: '5-р анги'},
                    {text: '6-р анги', value: '6-р анги'}, {text: '7-р анги', value: '7-р анги'}, {text: '8-р анги', value: '8-р анги'},
                    {text: '9-р анги', value: '9-р анги'}, {text: '10-р анги', value: '10-р анги'}, {text: '11-р анги', value: '11-р анги'},
                    {text: '12-р анги', value: '12-р анги'}, {text: 'Бусад', value: 'Бусад'}]" :filter-method="filterHandler">
                  </el-table-column>
                  <el-table-column label="Тэмдэглэл" prop="description" width="140px">
                  </el-table-column>
                  <el-table-column label="Сургууль" prop="school" width="140px">
                  </el-table-column>
                  <el-table-column label="Суух цаг" prop="student_time" width="100px">
                  </el-table-column>
                  <el-table-column label="Үлдсэн цаг" prop="remaining_time" width="100px">
                  </el-table-column>
                  <el-table-column label="Ирсэн" prop="student_id_count">
                      <el-button :disabled="!scope.row.irsen" @click="attendanceDetailDialogOpen(scope.row, 100)" size="mini" type="success" slot-scope="scope">
                          <span v-if="scope.row.irsen == null">0</span>
                          <span v-else>{{scope.row.irsen}}</span>
                      </el-button> 
                  </el-table-column>
                  <el-table-column label="Тасалсан"  prop="student_id_count" width="100px">
                      <el-button :disabled="!scope.row.tasalsan" size="mini" @click="attendanceDetailDialogOpen(scope.row, 101)" type="danger" slot-scope="scope">
                          <span v-if="scope.row.tasalsan == null">0</span>
                          <span v-else>{{scope.row.tasalsan}}</span>
                      </el-button> 
                  </el-table-column>
                  <el-table-column label="Өвчтэй" prop="student_id_count">
                      <el-button :disabled="!scope.row.uvchtei" size="mini" @click="attendanceDetailDialogOpen(scope.row, 103)" type="warning" slot-scope="scope">
                          <span v-if="scope.row.uvchtei == null">0</span>
                          <span v-else>{{scope.row.uvchtei}}</span>
                      </el-button> 
                  </el-table-column>
                  <el-table-column label="Чөлөөтэй" prop="student_id_count" width="100px">
                      <el-button :disabled="!scope.row.chuluutei" size="mini" @click="attendanceDetailDialogOpen(scope.row, 102)" type="primary" slot-scope="scope">
                          <span v-if="scope.row.chuluutei == null">0</span>
                          <span v-else>{{scope.row.chuluutei}}</span>
                      </el-button>
                  </el-table-column>
                  <el-table-column label="Эхэлсэн огноо" prop="start_date" width="140px">
                  </el-table-column>
                  <el-table-column label="Дуусах огноо" prop="end_date" width="140px">
                  </el-table-column>
                  <el-table-column label="Төлөв" prop="user_status" width="140px">
                  </el-table-column>
              </el-table>
          </el-col>
        </el-row>
        <AttenDetailDialog :data = attendanceDetailData  :studentData="selectedStudent" :selectedType="selectedType"/>
    </div>
  </template>
  
  <script>
  import AttenDetailDialog from '@/components/student/component/attendanceDetail.vue'
  import services from '@/helpers/services.js'

  export default {
    components:{
        AttenDetailDialog
    },
      props:{
        passUserListData:Array,
        lesson_name: String
      },
      data () {
          return {
            attendanceDetailData:[],
            selectedStudent:{},
            selectedType:'',
              search: '',
              bagshiin_ner: '',
              eelj: ''
          }
      },
    created(){
      },
      methods:{
        attendanceDetailDialogOpen(row, type){
            this.$root.fullscreenLoading= true
            this.selectedStudent= row
            if(type=== 100){
                this.selectedType = "ирсэн"
            }else if (type === 101){
                this.selectedType = "тасалсан"
            }else if (type === 102){
                this.selectedType = "чөлөөтэй"
            }else if (type === 103){
                this.selectedType = "өвчтэй"
            }else{
                this.selectedType = "тодорхойгүй"
            }
            var payload = {
				status : type,
                student_id: row.id,
                lesson_name: this.lesson_name
			}
			services.getStatusAttendanceDetailByUser(payload)
				.then((response) => {
				this.attendanceDetailData = response.result
                this.$root.attendaceDetailDialog = true
                this.$root.fullscreenLoading= false
			})
        },
        filterHandler (value, row, column) {
            const property = column.property
            return row[property] === value
        },
        statusCounter(eelj_too) {
          let count = 0
          this.passUserListData.forEach(element => {
            if (element.eelj === eelj_too) {
              count = count + 1
            }
          })
          return count
        },
        teacherCounter(teacher) {
          let count = 0
          this.passUserListData.forEach(element => {
            if (element.bagshiin_ner === teacher) {
              count = count + 1
            }
          })
          return count
        }
      }
  }
  </script>
  
  <style>
  
  </style>