<template>
    <div class="home">
      <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
        <el-col :span="8">
          <h3 class="m-l-25">Мсж загвар жагсаалт</h3>
        </el-col>
        <el-col :span="7" class="user_head_add_button m-r-40">
          <el-button size="mini" type="success" @click="addQuestionDialog()">+ МСЖ загвар үүсгэх</el-button>
          <el-button size="mini" type="primary" @click="sendPhoneNumberSms()" >+ МСЖ илгээх</el-button>
        </el-col>
      </el-row>
      <el-row class="m-l-30">
        <el-col :span="23" class="home_body p-20">
          <el-table v-loading="loading" :data="tabledata" stripe style="width: 100%" height="550">
            <el-table-column type="index" width="50">
            </el-table-column>
            <el-table-column prop="sms_text" label="Мсж текст">
            </el-table-column>
            <el-table-column label="Үйлдэл" width="320px">
              <template slot-scope="scope">
                <el-button size="mini" type="success" icon="el-icon-mobile" @click="sendPhoneNumberSms(scope.row)" circle>
                </el-button>
                <el-button size="mini" type="primary" icon="el-icon-mobile" @click="editBlogs(scope.row)" circle>
                </el-button>
                <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteBlogs(scope.row)" circle>
                </el-button>
                <el-button size="mini" type="info" v-if="scope.row.query != null" @click="requiredList(scope.row)">
                  Жагсаалт
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-dialog title="МСЖ загвар үүсгэх" :visible.sync="this.$root.smsTemplateCreateDialog" width="80%"
        :before-close="handleClose">
        <el-form :model="smsTemplateDialogForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="top"
          class="demo-ruleForm">
          <el-form-item label="МСЖ текст" prop="question">
            <el-input type="textarea" v-model="smsTemplateDialogForm.sms_text" placeholder="Асуулт"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="createQuestion('ruleForm')">Үүсгэх</el-button>
        </span>
      </el-dialog>
      <SmsSendDialog :sendSmsForm="this.sendSmsForm"/>
      <smsSendingResultDialog v-if="this.$root.sendSmsResultDialog" :sendSmsResultUserList= "this.smsUserResult" :selectedSmsTemplate = "selectedSmsTemplate"/>
    </div>
  </template>

<script>
import services from '@/helpers/services.js'
import SmsSendDialog from './SmsSendDialog.vue'
import smsSendingResultDialog from './smsSendingResultDialog.vue'
// import axios from 'axios'
import {
  notification
} from '@/helpers/custom'
export default {
  components: {
    SmsSendDialog,
    smsSendingResultDialog
  },
  data () {
    return {
      rules: {
        sms_text: [{
          required: true,
          message: 'Мсж загвар хоосон байна.',
          trigger: 'blur'
        }]
      },
      rules1: {
        to: [{
          required: true,
          message: 'Дугаар хоосон байна.',
          trigger: 'blur'
        },
        { min: 8, message: 'Утасны дугаарын орон дутуу байна', trigger: 'blur' }]
      },
      smsTemplateDialogForm: {
        sms_text: ''
      },
      tabledata: [],
      loading: true,
      sendSmsForm: {
        to: null,
        text: ''
      },
      smsUserResult: [],
      selectedSmsTemplate: {}
    }
  },
  created () {
    this.getList()
  },
  methods: {
    requiredList (data) {
      this.$root.fullscreenLoading = true
      this.selectedSmsTemplate = data
      if (data.query) {
        services.getSmsRequirementList(data)
          .then((response) => {
            console.log(response)
            const tempArray = []

            response.data.forEach(element => {
              const obj = {
                index: null,
                value: {
                  name: '',
                  phone: null,
                  custom_text: null
                }
              }
              obj.index = element.id
              obj.value.name = element.name
              obj.value.phone = element.phone
              obj.value.custom_text = element.custom_text
              tempArray.push(obj)
            })
            this.smsUserResult = tempArray
            this.$root.sendSmsResultDialog = true
            this.$root.fullscreenLoading = false
          })
      } else {
        this.$notify.info({
          title: 'Уучлаарай',
          message: 'Энэхүү мсж илгээх нөхцөл хоосон байна.'
        })
        this.$root.fullscreenLoading = false
      }
    },
    sendPhoneNumberSms (data) {
      this.$root.sendCustomNumberSmsDialog = true
      if (data) {
        this.sendSmsForm.text = data.sms_text
      } else {
        this.sendSmsForm.text = ''
      }
    },
    deleteBlogs (data) {
      this.$confirm(data.sms_text + ' усгах гэж байна итгэлтэй байна уу.', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const payload = {
          id: data.id
        }
        services.deleteSmsTemplate(payload)
          .then((response) => {
            if (response.status === 'success') {
              this.$root.smsTemplateCreateDialog = false
              notification('success_deleted')
              this.$root.fullscreenLoading = false
              location.reload()
            }
            // console.log(response)
          })
      }).catch(() => {})
    },
    createQuestion (formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          services.createSmsTemplate(this.smsTemplateDialogForm)
            .then((response) => {
              if (response.status === 'success') {
                this.$root.smsTemplateCreateDialog = false
                notification('success_position')
                this.$root.fullscreenLoading = false
                location.reload()
              } else if (response.status === 'unSuccess') {
                this.$root.fullscreenLoading = false
              }
              // console.log(response)
            })
        }
      })
    },
    handleClose () {
      this.$root.smsTemplateCreateDialog = false
    },
    handleClose1 () {
      this.$root.sendCustomNumberSmsDialog = false
    },
    editBlogs (data) {
      this.smsTemplateDialogForm = data
      this.$root.smsTemplateCreateDialog = true
    },
    addQuestionDialog () {
      this.smsTemplateDialogForm.sms_text = ''
      this.$root.smsTemplateCreateDialog = true
    },
    getList () {
      services.getSmsList()
        .then((response) => {
          this.tabledata = response.data
          this.loading = false
        })
    }
  }
}

</script>
