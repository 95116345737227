<template>
  <div>
    <el-row>
      {{ruleForm}}
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="220px" class="demo-ruleForm">
            <el-col :span="21">
          <el-form-item label="Гэрээ" prop="file_name">
            <el-input v-model="ruleForm.file_name" clearable placeholder="Гэрээ оруулна уу"></el-input>
          </el-form-item>
          <el-form-item label="Файл" prop="url">
            <el-upload name="file" :headers="{ 'X-CSRF-TOKEN': csrf }" class="avatar-uploader" :show-file-list="true"
              action="https://e28vgwsnai.execute-api.ap-southeast-1.amazonaws.com/production/api/uploadImage" :on-preview="handlePreview" :on-change="success"
              :accept="acceptType()" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="this.fileLimit"
              :on-exceed="handleExceed" :file-list="fileListAnswer">
              <img v-if="ruleForm.url" :src="'https://image.dma.mn/'+ruleForm.url"
                class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        </el-form>
    </el-row>
    <el-row>
        <el-col :span="21">
            <el-button type="success" class="button" @click="submitForm('ruleForm')">Үүсгэх</el-button>
        </el-col>
    </el-row>
  </div>
</template>
<script>
import services from '@/helpers/services.js'
import {
  notification
} from '@/helpers/custom.js'
import axios from 'axios'
export default {
  props: {},
  created () {
    console.log('Params: ', this.$route.params)
  },
  data () {
    return {
      positionList: [],
      ruleForm: {
        student_id: this.$route.params.id,
        file_name: '',
        url: ''
      },
      is_upload_image: true,
      rules: {
        file_name: [{
          required: true,
          message: 'Та овог оруулна уу',
          trigger: 'change '
        }],
        class: [{
          required: true,
          message: 'Та нэрээ оруулна уу',
          trigger: 'change'
        }]
      },
      imageUrl: '',
      fileListAnswer: [],
      fileList: [],
      isShow: false,
      fileTitle: '',
      csrf: '',
      // csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
      fileTitleAnswer: 'Та хариултын файлыг хуулна уу',
      // centerDialogVisible:false
      fileLimit: 1,
      content_url: ''
    }
  },

  methods: {
    success (file, fileList) {
      console.log(file)
      this.ruleForm.url = file.response
      setTimeout(() => {
        this.isShow = true
      }, 10000)
    },
    acceptType (index) {
      return 'image/png, image/jpeg'
    },
    handleRemove (file, fileList) {
      // eslint-disable-next-line no-prototype-builtins
      if (!file.hasOwnProperty('response')) {
        let response = file.url.split('?')[0]
        response = response.split('.com/')[1]
        file.response = response
      }
      axios.post('https://api.dma.mn/api/removeImage', file).then((data) => {
        this.fileList = fileList
        console.log(data)
        this.ruleForm.url = ''
        this.isShow = false
      }).catch((err) => {
        console.log(err)
      })
      console.log(file, fileList)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`Сонгосон  ${file.name}  файлыг хавсаргасан файлын жагсаалтаас хасах уу?`)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(
          `Файл тоо хэтэрсэн байна. ${
                    files.length
                } файл хуулсан байна. ${
                    this.fileLimit
                } хэтрэхгүй файл хуулна уу.`
      )
    },
    handleUploadVariantfulImages (file, fileList) {
      this.fileList = fileList
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          services.createStudentFile(this.ruleForm)
            .then(response => {
              this.$root.fullscreenLoading = true
              if (response.status === 'success') {
                notification('add_user_nofitcation')
                this.$router.push({
                  name: 'studentadd'
                })
                this.$root.fullscreenLoading = false
              } else if (response.status === 'failed') {
                this.$root.fullscreenLoading = true
                Object.keys(response.message).forEach((element, index) => {
                  this.$notify.error({
                    title: 'Алдаа',
                    message: response.message[element],
                    offset: (index * 150)
                  })
                  this.$root.fullscreenLoading = false
                })
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}

</script>
