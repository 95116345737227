import {
  mainRequests
} from './custom'
export default {
  login (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post('/login', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getRole () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getRole')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserById (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getUserById', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  studentStatusChange (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/studentStatusChange', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createStudentVacation (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createStudentVacation', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteVacation (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deleteStudentVacation', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // getUserList () {
  //   return new Promise(function (resolve, reject) {
  //     mainRequests
  //       .postAuth('/getTeacherList')
  //       .then(res => {
  //         resolve(res)
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // },

  deleteUser (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deleteUser', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeUserInfo (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post('/storeUserInfo', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeUserInfoParent (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post('/storeUserInfoParent', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getParentStudentByID (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getParentStudentByID', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductList () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/manager/getproductlist')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  saveRole (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/manager/saveRole', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteProduct (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/manager/deleteproduct', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTankList () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/manager/gettankList')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getProductById (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/manager/getproductById', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getRoleList () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/manager/getRoleList')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBranchList () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/manager/getBranchList')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addProduct (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/manager/addProduct', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  storeBranch (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/manager/storeBranch', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTankById (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/manager/getTankById', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBranchById (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/manager/getBranchById', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBranchByTank (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/manager/getBranchByTank', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteBranchTank (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/manager/deleteBranchTank', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // daagii training
  getPosition (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getPosition?position_type=' + payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createPosition (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createPosition', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deletePosition (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deletePosition', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCategory () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getCategory')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAllStudentList () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getAllStudentList')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createCategory (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createCategory', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getLesson () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getLesson')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createLesson (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createLesson', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getRoom () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getRoom')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getContract () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getContract')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createRoom (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createRoom', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createEvent (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/event', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getEvents () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/event')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteRoom (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deleteRoom', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getLessonScheduleFSADMIN (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getLessonScheduleFSADMIN', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTeacherList (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getTeacherList', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createStudentSchedule (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createStudentSchedule', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getStudentsView (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getNotInScheduleStudents', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getStudentList (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getStudentList', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getStudentVacation (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getStudentVacation', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createLessonSchedule (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createLessonSchedule', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteLessonSchedule (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deleteLessonSchedule', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getBlogs () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getBlogs')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createBlogs (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createBlogs', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteBlogs (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deleteBlogs', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getFrequentlyQuestion () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getFrequentlyQuestion')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteFrequentlyQuestions (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deleteFrequentlyQuestions', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createFrequentlyQuestions (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createFrequentlyQuestions', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  statusChangeFrequently (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/statusChangeFrequently', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getParentsComment () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getParentsComment')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserGroupByStatus () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getUserGroupByStatus')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPaymentPay () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getPaymentPay')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createParentsComment (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createParentsComment', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteParentsComment (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deleteParentsComment', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  statusChangeComment (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/statusChangeComment', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTopic () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getTopic')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createTopic (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createTopic', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteTopic (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deleteTopic', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createStudentDetail (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createStudentDetail', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createStudentFile (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createStudentFile', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  student_detail () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getStudentDetail')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  student_file () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getStudentFile')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getstudentlist () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getStudentList')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  userStatusChange (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/userStatusChange', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  removeUpload (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/removeImage', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserRequest () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getUserRequest')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateUserReq (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/updateRequest', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getLessonContractTeacherList (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getLessonContractTeacherList', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  removeImage (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/removeImage', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createContract (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createContract', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getContractStatus (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getContractStatus', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createContractCancel (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createContractCancel', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  teacherChange (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/teacherChange', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getStudentByContract (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getStudentByContract', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCountHuleegdejBaigaaStudents (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getCountHuleegdejBaigaaStudents', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getPayment (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getPayment', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createPayment (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createPayment', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getScheduleLogHistory (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getScheduleLogHistoryAdmin', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  removeScheduleCustomStudent (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deleteStudentFromSchedule', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getScheduleLogHistoryByID (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getScheduleLogHistoryByID', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createAttendance (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createAttendance', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSmsList () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getSmsList')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSmsHistoryList (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getSmsHistoryList', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createSmsTemplate (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createSmsTemplate', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteSmsTemplate (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deleteSmsTemplate', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendSms (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/sendSms', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCustomPositionsList (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getCustomPositionsList', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSmsRequirementList (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getSmsRequirementList', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getContractLesson (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getContractLesson', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createStudentNote (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createStudentNote', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  deleteContractLesson(payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/deleteContractLesson', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getStudentAttendance(payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getStudentAttendance', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getActiveStudentView(payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getActiveStudentView', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getStatusAttendanceDetailByUser(payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getStatusAttendanceDetailByUser', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createPaymentPlan(payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createPaymentPlan', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getStudentByPayment(payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getStudentByPayment', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  chagePaymentStatus(payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/chagePaymentStatus', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  studentExcelList(payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getStudentExcelList', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  viewTestService(payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/viewTest', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changeStudentSchedule(payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/changeStudentSchedule', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getMatTeachers() {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getMatTeachers')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getParent (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getParent', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
