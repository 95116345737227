<template>
  <div>
    <el-dialog title="Сурагчийн ирц (өвчтэй, чөлөөтэй)" :visible.sync="this.$root.studentVacation" width="50%"
      :before-close="handleClose">
      {{this.student}} ---- {{this.ruleForm}}
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="350px" class="demo-ruleForm p-20"
        label-position="top">
        <el-form-item prop="status_code">
          Чөлөөний төрөл: <br />
          <el-radio-group v-model="ruleForm.status_code" size="small">
            <el-radio-button :label="103">Өвчтэй</el-radio-button>
            <el-radio-button :label="102">Чөлөөтэй</el-radio-button>
            <el-radio-button :label="101">Тас</el-radio-button>
          </el-radio-group>
        </el-form-item>
        Чөлөө сонгосон өдөр: <el-tag v-for="i,index in ruleForm.student_vacation_detail" :key="index"> {{ i }}</el-tag>
        <div class="m-t-20">
          Өдөр сонгох: <br />
          <el-form-item prop="student_vacation_detail">
            <el-date-picker class="w-100 m-t-20" type="dates" value-format="yyyy-MM-dd"
              v-model="ruleForm.student_vacation_detail"
              :picker-options="pickerOptions"
              placeholder="Сурагчийн чөлөө авах болон өвчтэй өдрүүдийг сонгоно уу?">
            </el-date-picker>
          </el-form-item>
        </div><br />
        <el-form-item prop="description">
          Тайлбар: <br />
          <el-input class="m-t-10" type="textarea" placeholder="Ямар шалтгаанаар чөлөө авч байгааг тайлбарлах"
            v-model="ruleForm.description"></el-input>
        </el-form-item>
        <el-form-item>
          <el-col :span="24">
            <el-button type="success" @click="StudentVacation('ruleForm')" class="button_right">Хадгалах</el-button>
          </el-col>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import services from '@/helpers/services.js'
  export default {
    props: {
      student: Object
    },
    data() {
      return {
        ruleForm: {
          student_id: null,
          status_code: null,
          contract_id: null,
          description: '',
          student_vacation_detail: null
        },
        rules: {
          status_code: [{
            required: true,
            message: 'Та заавал сонгоно уу',
            trigger: 'blur'
          }],
          description: [{
            required: true,
            message: 'Шалтгаан заавал бичнэ үү уу',
            trigger: 'change'
          }],
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          }
        }
      }
    },
    created(){
      if(this.student.id){
        this.ruleForm.id = this.student.id
        this.ruleForm.student_id = this.student.student.id
        if(this.student.status_code){
          this.ruleForm.status_code = this.student.status_code
          if(this.student.student_vacation_detail_get.length> 0){
            var vacation_date = []
            this.student.student_vacation_detail_get.forEach(element => {
              vacation_date.push(element.vacation_date)
            });
            this.ruleForm.student_vacation_detail= vacation_date
            this.ruleForm.description = this.student.description
          }
        }
      }
    },
    methods: {
      handleClose() {
        this.$root.studentVacation = false
      },
      StudentVacation(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$root.fullscreenLoading = true
            this.ruleForm.student_id = this.student.id
            services.createStudentVacation(this.ruleForm)
              .then(response => {
                if (response.status === 'success') {
                  location.reload()
                  this.$root.fullscreenLoading = false
                } else if (response.status === 'failed') {
                  this.$root.fullscreenLoading = true
                  Object.keys(response.message).forEach((element, index) => {
                    this.$notify.error({
                      title: 'Алдаа',
                      message: response.message[element],
                      offset: (index * 150)
                    })
                    this.$root.fullscreenLoading = false
                  })
                }
              })
          } else {
            console.log('error submit!!');
            return false;
          }

        });
      }
    }
  }

</script>
<style scoped>
  .el-input--prefix .el-input__inner {
    width: 586px;
  }

</style>
