<template>
    <div>
        <el-row class="p-20">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="220px" class="demo-ruleForm" labelPosition="top">
            <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
              <el-form-item label="Сургууль" prop="school">
                <el-input v-model="ruleForm.school" clearable placeholder="Сургууль оруулна уу"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="23" :sm="23" :md="23" :lg="11" :xl="11">
              <el-form-item label="Анги" prop="class">
                  <el-select v-model="ruleForm.class" placeholder="Анги" clearable>
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>            
              </el-form-item>
            </el-col>
          </el-form>
          </el-row>
      <el-row>
        <el-col :span="22">
            <el-button type="primary" class="button" @click="submitForm('ruleForm')">Үүсгэх</el-button>
        </el-col>
      </el-row>
      {{ruleForm}}
    </div>
  </template>
<script>
import services from '@/helpers/services.js'
import { notification } from '@/helpers/custom.js'
export default {
  props: {
    user: Object
  },
  created () {
    console.log('Params: ', this.$route.params)
  },
  data () {
    return {
      activeStep: 2,
      ruleForm: {
        student_id: this.$route.params.id,
        class: '',
        school: ''
      },
      options: [{
        value: '1',
        label: '1-р анги'
      }, {
        value: '2',
        label: '2-р анги'
      }, {
        value: '3',
        label: '3-р анги'
      }, {
        value: '4',
        label: '4-р анги'
      }, {
        value: '5',
        label: '5-р анги'
      }, {
        value: '6',
        label: '6-р анги'
      }, {
        value: '7',
        label: '7-р анги'
      }, {
        value: '8',
        label: '8-р анги'
      }, {
        value: '9',
        label: '9-р анги'
      }, {
        value: '10',
        label: '10-р анги'
      }, {
        value: '11',
        label: '11-р анги'
      }, {
        value: '12',
        label: '12-р анги'
      }, {
        value: 'other',
        label: 'Бусад'
      }],
      rules: {
        school: [{
          required: true,
          message: 'Та овог оруулна уу',
          trigger: 'change '
        }],
        class: [{
          required: true,
          message: 'Та нэрээ оруулна уу',
          trigger: 'change'
        }]
      },
      tableData: []
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          services.createStudentDetail(this.ruleForm)
            .then(response => {
              this.$root.fullscreenLoading = true
              if (response.status === 'success') {
                notification('add_user_nofitcation')
                this.$router.push({
                  params: { id: response.result.student_id },
                  name: 'studentStep3'
                })
                this.$root.fullscreenLoading = false
              } else if (response.status === 'failed') {
                this.$root.fullscreenLoading = true
                Object.keys(response.message).forEach((element, index) => {
                  this.$notify.error({
                    title: 'Алдаа',
                    message: response.message[element],
                    offset: (index * 150)
                  })
                  this.$root.fullscreenLoading = false
                })
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
