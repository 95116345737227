<template>
    <div class="home">
      <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
        <el-col :span="8">
          <h3 class="m-l-25">Өрөөний жагсаалт</h3>
        </el-col>
        <el-col :span="3" class="user_head_add_button m-r-25">
          <el-button size="mini" type="success" @click="addRoomDialog()">+ Өрөө үүсгэх</el-button>
        </el-col>
      </el-row>
      <el-row class="m-l-30">
        <el-col :span="23" class="home_body p-20">
          <el-table v-loading="loading" :data="roomList" stripe style="width: 100%">
            <el-table-column type="index" width="50">
            </el-table-column>
            <el-table-column prop="room_name" label="Өрөөний дугаар">
            </el-table-column>
            <el-table-column label="Үйлдэл">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="editRoom(scope.row)"></el-button>
                <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="deleteRoom(scope.row)" ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-dialog title="Өрөө үүсгэх" :visible.sync="this.$root.createRoomDialog" width="20%"
        :before-close="handleClose">
        <el-form :model="selectedRoom" :rules="rules" ref="ruleForm" label-width="120px" label-position="top"
          class="demo-ruleForm">
          <el-form-item label="Өрөөний дугаар үүсгэнэ үү" prop="room_name">
            <el-input v-model="selectedRoom.room_name" placeholder="Өрөөний дугаар"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="createRoom('ruleForm')">Хадгалах</el-button>
        </span>
      </el-dialog>
    </div>
  </template>

<script>
import services from '@/helpers/services.js'
import { notification } from '@/helpers/custom'
export default {
  data () {
    return {
      rules: {
        room_name: [
          { required: true, message: 'Өрөөний дугаарыг оруулна уу', trigger: 'blur' }
        ]
      },
      selectedRoom: {
        room_name: ''
      },
      roomList: [],
      loading: true
    }
  },
  created () {
    this.getRoomList()
  },
  methods: {
    deleteRoom (data) {
      this.$confirm(data.room_name + ' өрөөг усгах гэж байна итгэлтэй байна уу.', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        // this.$root.fullscreenLoading = true
        const payload = {
          id: data.id
        }
        services.deleteRoom(payload)
          .then((response) => {
            if (response.status === 'success') {
              this.$root.createRoomDialog = false
              notification('success_deleted')
              this.$root.fullscreenLoading = false
              location.reload()
            } else if (response.status === 404) {
              this.$root.fullscreenLoading = false
            }
            // console.log(response)
          })
      }).catch(() => {
      })
    },
    createRoom (formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          services.createRoom(this.selectedRoom)
            .then((response) => {
              if (response.status === 'success') {
                this.$root.createRoomDialog = false
                notification('success_save')
                this.$root.fullscreenLoading = false
                location.reload()
              } else if (response.status === 'unSuccess') {
                this.$root.fullscreenLoading = false
              }
              // console.log(response)
            })
        }
      })
    },
    handleClose () {
      this.$root.createRoomDialog = false
    },
    editRoom (data) {
      this.selectedRoom = data
      this.$root.createRoomDialog = true
    },
    addRoomDialog () {
      this.selectedRoom.room_name = ''
      this.$root.createRoomDialog = true
    },
    getRoomList () {
      services.getRoom()
        .then((response) => {
          this.roomList = response.data
          this.loading = false
        })
    }
  }
}

</script>

  <style>

  </style>
