<template>
  <div>
    <el-dialog title="Сурагчийн төлбөр" :visible.sync="this.$root.studentPayment" width="40%"
      :before-close="handleClose">
      <div class="payment_lesson">
        <div class="m-b-20 paymentColor">Сурагчийн төлбөрийн график: <br /></div>
        <el-table :data="paymentList" border-size="mini" style="width: 100%" class="m-b-20">
            <el-table-column label="Төлөх төлбөр" prop="payment_amount" width="180">
              <template slot-scope="scope">
                {{scope.row.pay_amount | formatCurrency}}
              </template>
            </el-table-column>
            <el-table-column label="Төлөх огноо" prop="to_pay_date"  width="180">
              <template slot-scope="scope">
                {{scope.row.to_pay_date}}
              </template>
            </el-table-column>
            <el-table-column label="Тайлбар" prop="description" width="300">
              <template slot-scope="scope">
                {{scope.row.description}}
              </template>
            </el-table-column>
            <el-table-column label="Төлөв"  @click="chagePaymentStatus(scope.row)">
              <el-radio-group class="m-t-10" size="mini" v-model="payment_status">
                <el-radio-button label="1">Төлсөн</el-radio-button>
                <el-radio-button label="0">Төлөөгүй</el-radio-button>
              </el-radio-group> 
            </el-table-column>
        </el-table>
        <div class="m-b-20">Сурагчийн төлөх ёстой төлбөр: {{ CASHIER_CURRENCY=calculatePayment() | formatCurrency}} <br /></div>
        <div class="m-t-20">
          Сурагчийн төлбөрийн хэлбэр: <br>
          <el-radio-group class="m-t-10" size="mini" v-model="paymentForm.payment_type">
            <el-radio-button label="Дансаар"></el-radio-button>
            <el-radio-button label="Бэлнээр"></el-radio-button>
            <el-radio-button label="Картаар"></el-radio-button>
            <el-radio-button label="Бартер"></el-radio-button>
            <el-radio-button label="Эрхийн бичиг"></el-radio-button>
            <el-radio-button label="Зээлээр"></el-radio-button>
          </el-radio-group> <br />
        </div>
        <div class="m-t-20">
          Төлөгдсөн дүнг оруулна уу: <br />
          <el-input-number :controls="false" class="m-t-10 w-100" :min="0" :max="calculatePayment()" v-model="paymentForm.pay_amount"></el-input-number>
        </div>
        <div class="m-t-20">
          Төлбөр төлсөн огноо оруулна уу: <br />
          <el-date-picker v-model="paymentForm.payed_date" type="date" format="yyyy/MM/dd" value-format="yyyy-MM-dd" placeholder="Төлбөр төлсөн өдөр">
          </el-date-picker>
        </div>
        <div class="m-t-20">
          Тайлбар: <br />
          <el-input class="m-t-10" type="textarea" v-model="paymentForm.description" placeholder="Төлбөртэй холбоотой тайлбар оруулна уу"></el-input>
        </div><br/>
        <el-button size="mini" class="m-t-20 w-100" type="success" @click="createPayment()">Төлөгдсөн</el-button>
        <div class="m-t-30">
          Төлбөрийн өмнөх түүх: <br />
          <el-table :data="student.student_contract.payment_history" border size="mini" style="width: 100%">
              <el-table-column prop="pay_amount" label="Төлсөн дүн" width="100">
                <template slot-scope="scope">
                          {{scope.row.pay_amount | formatCurrency}}
                </template>
              </el-table-column>
              <el-table-column prop="payment_type" label="Төлбөрийн хэлбэр" width="150">
              </el-table-column>
              <el-table-column prop="payed_date" label="Төлбөр төлсөн огноо" width="150">
              </el-table-column>
              <el-table-column prop="description" label="Тайлбар" width="180">
              </el-table-column>
              <el-table-column prop="created_at" label="Бүртгэсэн огноо" width="180">
                <template slot-scope="scope">
                  {{dateFormatFunctionPage(scope.row.created_at)}}
                </template>
              </el-table-column>
          </el-table>
        </div>
        <div class="m-b-20 m-t-30 paymentColor">Сурагчийн төлбөрийн график оруулах:</div>
        <div class="m-t-20">
          Төлөх дүнг оруулна уу: <br />
          <el-input-number :controls="false" class="m-t-10 w-100" :min="0" :max="calculatePayment()" v-model="paymentPlanForm.pay_amount"></el-input-number>
        </div>
        <div class="m-t-20">
          Төлбөр төлөх огноо оруулна уу: <br />
          <el-date-picker class="m-t-10" v-model="paymentPlanForm.to_pay_date" type="date" format="yyyy/MM/dd" value-format="yyyy-MM-dd" placeholder="Төлбөр төлөх өдөр">
          </el-date-picker>
        </div>
        <div class="m-t-20">
          Тайлбар: <br />
          <el-input class="m-t-10" type="textarea" v-model="paymentPlanForm.description" placeholder="Төлбөртэй холбоотой тайлбар оруулна уу"></el-input>
        </div><br/>
        <el-button size="mini" class="m-t-20 w-100" type="success" @click="createPaymentPlan()">Хадгалах</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { notification, dateFormatFunction } from '@/helpers/custom'
import services from '@/helpers/services.js'
export default {
  data () {
    return {
      paymentForm: {
        contract_id: this.student.student_contract.id,
        payment_type: 'Дансаар',
        pay_amount: 0,
        description: '',
        payed_date: '',
        user_status: this.student.user_status.status_code
      },
      paymentPlanForm: {
        contract_id: this.student.student_contract.id,
        pay_amount : 0,
        to_pay_date: '',
        description: ''
      },
      paymentList: [],
      // paymentStatus: 0,
      paymentStatus: {
        payment_status: 0
      }
    }
  },
  props: {
    student: Object
  },
  created(){
    if(this.student){
      this.getPaymentInfoStudent()
    }
  },
  methods: {
    getPaymentInfoStudent(){
      this.$root.fullscreenLoading = true
      var payload = {
        student_id : this.student.id,
        contract_id : this.student.student_contract.id
      }
          services.getStudentByPayment(payload)
          .then((response) => {
            this.paymentList = response.result
            console.log(response)
            if (response.status === 'success') {
              this.$root.fullscreenLoading = false
            }
          })
    },
    dateFormatFunctionPage (date) {
      return dateFormatFunction(date)
    },
    createPayment () {
      if (this.paymentForm.pay_amount === 0) {
        this.$notify.error({
          title: 'Анхаар',
          message: 'Төлбөр төлсөн дүн 0 байж болохгүй.'
        })
      } else {
        this.$root.fullscreenLoading = true
        services.createPayment(this.paymentForm)
          .then((response) => {
            console.log(response)
            if (response.status === 'success') {
              notification('add_user_nofitcation')
              this.$root.fullscreenLoading = false
              location.reload()
            }
          })
      }
    },
    createPaymentPlan(){
        if (this.paymentPlanForm.pay_amount === 0) {
        this.$notify.error({
          title: 'Анхаар',
          message: 'Төлбөр төлсөн дүн 0 байж болохгүй.'
        })
      } else {
        this.$root.fullscreenLoading = true
        services.createPaymentPlan(this.paymentPlanForm)
          .then((response) => {
            console.log(response)
            if (response.status === 'success') {
              notification('add_user_nofitcation')
              this.$root.fullscreenLoading = false
              location.reload()
            }
          })
      }
    },
    calculatePayment () {
      let totalPayment = 0
      this.student.student_contract.contract_lessons.forEach(element => {
        totalPayment = totalPayment + element.payment
      })
      return totalPayment
    },
    handleClose () {
      this.$root.studentPayment = false
    },
    chagePaymentStatus(data){
      this.paymentStatus.id = data.id,
      this.paymentStatus.payment_status = data.payment_status
    }
  }
}

</script>
