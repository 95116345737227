<template>
  <div class="home">
    <el-row type="flex" justify="space-between" class="m-t-20 m-b-20">
      <el-col :span="8">
        <h3 class="m-l-25">Сэдвийн жагсаалт</h3>
      </el-col>
      <el-col :span="3" class="user_head_add_button m-r-25">
        <el-button size="mini" type="success" @click="addTopicDialog()">+ Сэтгэгдэл оруулах</el-button>
      </el-col>
    </el-row>
    <el-row class="m-l-30">
      <el-col :span="23" class="home_body p-20">
      <el-input v-model="search" size="medium" placeholder="Хайх" prefix-icon="el-icon-search" />
        <el-table class="m-t-20" v-loading="loading" size="mini"
          :data="tabledata.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.description.toLowerCase().includes(search.toLowerCase()) )"
          style="width: 100%" height="550" border default-expand-all  >
            <el-table-column type="index" width="50">
            </el-table-column>
            <el-table-column prop="name" label="Нэр">
            </el-table-column>
            <el-table-column prop="description" label="Тайлбар">
            </el-table-column>
            <el-table-column label="Үйлдэл">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="editComment(scope.row)" circle>
                </el-button>
                <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteTopic(scope.row)" circle>
                </el-button>
              </template>
            </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-drawer title="Cэдэв үүсгэх" :visible.sync="this.$root.createTopicDrawer" custom-class="demo-drawer" ref="drawer"
      :before-close="handleClose">
      <div class="p-20">
        <el-form :model="createTopicDrawer" :rules="rules" ref="ruleForm" label-position="top" class="demo-ruleForm">
          {{createTopicDrawer}}
          <el-form-item label="Сэдвийн нэр" prop="name" :label-width="formLabelWidth">
            <el-input v-model="createTopicDrawer.name" placeholder="Сэдвийн нэр"></el-input>
          </el-form-item>
          <el-form-item label="Тайлбар" prop="description" :label-width="formLabelWidth">
            <el-input v-model="createTopicDrawer.description" type="textarea" placeholder="Тайлбар"></el-input>
          </el-form-item>
        </el-form>
        <div class="demo-drawer__footer m-r-20">
          <el-button type="success" class="button_right" @click="createTopic('ruleForm')">Үүсгэх</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import services from '@/helpers/services.js'
import {
  notification
} from '@/helpers/custom'
export default {
  data () {
    return {
      formLabelWidth: '80px',
      rules: {
        name: [{
          required: true,
          message: 'Сэдвийн нэр оруулна уу',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: 'Тайлбар оруулна уу',
          trigger: 'blur'
        }]
      },
      createTopicDrawer: {
        name: '',
        description: '',
        status: 'active'
      },
      tabledata: [],
      search: '',
      loading: true
    }
  },
  created () {
    this.getList()
  },
  methods: {
    deleteTopic (data) {
      this.$confirm(data.name + ' усгах гэж байна итгэлтэй байна уу.', 'Анхаар', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'warning'
      }).then(() => {
        this.$root.fullscreenLoading = true
        const payload = {
          id: data.id
        }
        services.deleteTopic(payload)
          .then((response) => {
            if (response.status === 'success') {
              notification('success_deleted')
              this.$root.fullscreenLoading = false
              this.handleClose()
              this.getList()
            }
            // console.log(response)
          })
      }).catch(() => {})
    },
    createTopic (formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.$root.fullscreenLoading = true
          services.createTopic(this.createTopicDrawer)
            .then((response) => {
              if (response.status === 'success') {
                this.$root.createTopicDrawer = false
                notification('success_save')
                this.$root.fullscreenLoading = false
                this.getList()
              } else if (response.status === 'unSuccess') {
                this.$root.fullscreenLoading = false
              }
              // console.log(response)
            })
        }
      })
    },
    handleClose () {
      this.$root.createTopicDrawer = false
      this.createTopicDrawer.name = ''
      this.createTopicDrawer.description = ''
      this.createTopicDrawer.status = 'active'
      if (this.createTopicDrawer.id) {
        delete this.createTopicDrawer.id
      }
    },
    editComment (data) {
      this.createTopicDrawer = {
        ...data
      }
      this.$root.createTopicDrawer = true
    },
    addTopicDialog () {
      this.createTopicDrawer.name = ''
      this.createTopicDrawer.description = ''
      this.$root.createTopicDrawer = true
    },
    getList () {
      services.getTopic()
        .then((response) => {
          this.tabledata = response.result
          this.loading = false
        })
    }
  }
}

</script>
