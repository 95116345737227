<template>
  <chart type="bar" height="250" :labels="labels" :datasets="dataset" :options="options" />
</template>
<script>
  import Chart from 'charts.vue2';
  export default {
    name: "ChartComponent",
    components: {
      Chart
    },
    data() {
      return {
        labels: ['1 сар', '2 сар', '3 сар', '5 сар', '6 сар', '7 сар', '8 сар', '9 сар', '10 сар', '12 сар'],
        dataset: [{
          label: 'Математик',
          backgroundColor: '#F3973F',
          data: this.dataReturn()
        }, {
          label: 'Англи хэл',
          backgroundColor: '#C2305B',
          data: [5, 6, 7, 8, 9, 10, 11, 12, 4, 3, 2, 5, 3]
        }],
        options: {
          responsive: true,
          maintainAspectRatio: false,
        }
      }
    },
    methods: {
      dataReturn() {
        return [1, 2, 3];
      }
    }
  }

</script>

<style>

</style>
